import { FormControlLabel, Radio, RadioGroup, useMediaQuery } from '@mui/material';
import { useDecision } from '@optimizely/react-sdk';
import { format, getWeek, isToday } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { KpiType, StorePerformanceInterval } from '../../constants';
import { useThemeContext } from '../../global-state/themeContext';
import { useStoreInformation, useTransactions, useUser } from '../../hooks';
import i18n from '../../i18n';
import { Container, Icons, Tooltip, Typography } from '../../stories/atoms';
import { DropdownMenu, IconWithText } from '../../stories/molecules';
import { DatePicker } from '../../stories/molecules/date-picker/DatePicker';
import { Switch } from '../../stories/molecules/switch/Switch';
import { Page } from '../../stories/organisms';
import { muiTheme } from '../../theme';
import { homeTranslations } from '../../translations';
import { UserPermissions, getOptimiselyPermission } from '../../utils';
import { localeMap } from '../../utils/language';
import { StyledCategoryContainer, StyledIcon, StyledSwitchWrapper } from './Home.styles';
import { WidgetType, WidgetsCategory } from './types';
import { getPermissonToView, kpiTypeToWidgetTypeMap, widgetsCategoryToWidgetTypesMap } from './util';
import { WidgetsContainer } from './widgetsContainer/WidgetsContainer';

const getPermissionToViewCategory = (
  widgetsCategory: WidgetsCategory,
  userPermissions: UserPermissions,
): boolean => {
  const widgetTypes = widgetsCategoryToWidgetTypesMap[widgetsCategory];

  return !!widgetTypes.find((type) => getPermissonToView(type, userPermissions));
};

const canViewHighlightedWidgets = (highlightedKpiTypes: KpiType[], userPermissions: UserPermissions) => {
  let canView = false;

  highlightedKpiTypes.forEach((kpiType) => {
    const widgetType = kpiTypeToWidgetTypeMap[kpiType];

    if (!widgetType) return;

    if (getPermissonToView(widgetType, userPermissions)) {
      canView = true;
    }
  });

  return canView;
};

export type SelectedCategory = {
  selectedId: string;
  selectedName: WidgetsCategory;
};

export type CategoryIcon = {
  iconId: string;
  iconName: WidgetsCategory;
  icon: Icons;
};

export const Home: FC = () => {
  const { t } = useTranslation();
  const { widgetCategory, datePicker, common } = homeTranslations;
  const {
    get: { stores, currentStoreId, highlightedKpiTargets, userPermissions, userId },
  } = useUser();
  const { countryCode, corporateBrandId } = useStoreInformation();
  const [regionSettings] = useDecision('region_settings', {}, { overrideUserId: userId || undefined });
  const { getIsWidgetDisabled } = getOptimiselyPermission(countryCode, corporateBrandId, regionSettings);

  const {
    get: { isFetchingTransaction, isFetchingCount },
    set: setTransactions,
  } = useTransactions();
  const { search, pathname } = useLocation();
  const { mode } = useThemeContext();

  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>(() => {
    const savedIconStr = localStorage.getItem('selectedIcon');

    // if no cached category but there are highlighted widgets, default to
    // highlighted widgets
    if (!savedIconStr && highlightedKpiTargets.length > 0) {
      return { selectedId: 'highlightedWidgets', selectedName: WidgetsCategory.HIGHLIGHTED };
    }

    // if no cached category, default to customer experience widgets
    if (!savedIconStr) {
      return { selectedId: 'salesEfficiency', selectedName: WidgetsCategory.SALESEFFICIENCY };
    }

    const savedIcon = JSON.parse(savedIconStr) as SelectedCategory;

    // if cached category is highlighted widgets but there are no highlighted widgets, return customer experience widgets instead
    if (savedIcon.selectedName === WidgetsCategory.HIGHLIGHTED && highlightedKpiTargets.length === 0) {
      return { selectedId: 'salesEfficiency', selectedName: WidgetsCategory.SALESEFFICIENCY };
    }

    return savedIcon;
  });

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    localStorage.setItem('selectedIcon', JSON.stringify(selectedCategory));
  }, [selectedCategory]);

  useEffect(() => {
    if (!isFetchingTransaction) setTransactions({ isFetchingTransaction: true });
    if (!isFetchingCount) setTransactions({ isFetchingCount: true });
  }, [isFetchingTransaction, isFetchingCount]);

  const categoryIcons: CategoryIcon[] = [
    { iconId: 'highlightedWidgets', iconName: WidgetsCategory.HIGHLIGHTED, icon: Icons.STAR },
    { iconId: 'customerExperience', iconName: WidgetsCategory.CUSTOMEREXPERIENCE, icon: Icons.PROFILE },
    { iconId: 'customerBaseValue', iconName: WidgetsCategory.CUSTOMERBASEVALUE, icon: Icons.CUSTOMERVALUE },
    { iconId: 'salesEfficiency', iconName: WidgetsCategory.SALESEFFICIENCY, icon: Icons.SALESEFFICIENCY },
    { iconId: 'transactions', iconName: WidgetsCategory.TRANSACTIONS, icon: Icons.TRANSACTIONS },
    { iconId: 'allWidgets', iconName: WidgetsCategory.ALL, icon: Icons.ALLWIDGETS },
  ];

  const queryParams = new URLSearchParams(search);
  const storeIdParam = queryParams.get('storeId');
  const mobileDeviceKey = queryParams.get('key');
  const showHomeForNonSignInUser =
    storeIdParam && mobileDeviceKey && pathname.includes('/home') ? true : undefined;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));

  const isCurrentDay = isToday(selectedDate);

  const handleCategorySelect = (iconId: string, iconName: WidgetsCategory) => {
    setSelectedCategory({ selectedId: iconId, selectedName: iconName });
  };

  const [pickerType, setPickerType] = useState<'date' | 'week'>('date');
  const [dateLabel, setDateLabel] = useState('');
  const [showAreaWidget, setShowAreaWidget] = useState<boolean>(
    localStorage.getItem('showAreaComparisonWidget') === 'true',
  );
  const [currentInterval, setCurrentInterval] = useState<StorePerformanceInterval>(StorePerformanceInterval.DAILY);

  const datePickerLabel =
    pickerType === 'week'
      ? `${t(datePicker.weekLabel.key, datePicker.weekLabel.defaultValue)} ${getWeek(selectedDate)}`
      : format(selectedDate, 'dd-MMM-yyyy', { locale: localeMap[i18n.language] });

  useEffect(() => {
    setDateLabel(datePickerLabel);
    if (pickerType === 'date') {
      setCurrentInterval(StorePerformanceInterval.DAILY);
    }
    if (pickerType === 'week') {
      setCurrentInterval(StorePerformanceInterval.WEEKLY);
    }
  }, [selectedDate, pickerType, i18n.language]);

  const canViewAreaComparison =
    !getIsWidgetDisabled(WidgetType.AreaComparisonWidget) && userPermissions.areaComparisonWidget.canRead;

  return (
    <Page
      header={
        <StyledCategoryContainer>
          <div>
            {showHomeForNonSignInUser ? (
              <></>
            ) : isMobile ? (
              <DropdownMenu
                data-testid="category-dropdown-menu"
                dropdownLabel={t(
                  widgetCategory[selectedCategory.selectedName].key,
                  widgetCategory[selectedCategory.selectedName].defaultValue,
                )}
                initialSelected={[selectedCategory.selectedName]}
                backgroundColor={{
                  hover: 'baseGray',
                  selected: mode === 'dark' ? 'primary' : 'backgroundSelected',
                  selectedHover: 'secondaryTextGray',
                }}
                menuItems={categoryIcons.map((categoryIcon) => ({
                  key: categoryIcon.iconId,
                  item: (
                    <IconWithText
                      label={t(
                        widgetCategory[categoryIcon.iconName].key,
                        widgetCategory[categoryIcon.iconName].defaultValue,
                      )}
                      iconOptions={{
                        icon: categoryIcon.icon,
                        size: 'small',
                      }}
                    />
                  ),
                }))}
                onSelect={(selectedItemKey) =>
                  setSelectedCategory({
                    selectedId: selectedItemKey[0],
                    selectedName: selectedItemKey[0] as WidgetsCategory,
                  })
                }
              />
            ) : (
              categoryIcons.map((categoryIcon) => {
                if (
                  categoryIcon.iconName === WidgetsCategory.HIGHLIGHTED &&
                  highlightedKpiTargets.length === 0 &&
                  canViewHighlightedWidgets(highlightedKpiTargets, userPermissions)
                ) {
                  return null;
                }

                if (
                  categoryIcon.iconName !== WidgetsCategory.ALL &&
                  categoryIcon.iconName !== WidgetsCategory.HIGHLIGHTED
                ) {
                  const hasPermissionToViewCategory = getPermissionToViewCategory(
                    categoryIcon.iconName,
                    userPermissions,
                  );

                  if (!hasPermissionToViewCategory) return null;
                }

                return (
                  <Tooltip
                    text={
                      <Typography padding={[1]} color="white">
                        {t(
                          widgetCategory[categoryIcon.iconName].key,
                          widgetCategory[categoryIcon.iconName].defaultValue,
                        )}
                      </Typography>
                    }
                    position="bottom"
                    key={`category-icon-${categoryIcon.iconId}`}
                  >
                    <StyledIcon
                      isSelected={selectedCategory.selectedId === categoryIcon.iconId}
                      data-testid="category-icon"
                      icon={categoryIcon.icon}
                      size="small"
                      margin={[0, 4, 0, 0]}
                      onClick={() => handleCategorySelect(categoryIcon.iconId, categoryIcon.iconName)}
                    />
                  </Tooltip>
                );
              })
            )}
          </div>
          <DatePicker
            inputSelectedDate={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
            }}
            label={dateLabel}
            pickerType={pickerType}
            showCalendarIcon
            actionBar={
              <RadioGroup
                aria-labelledby="date-picker-radio-group"
                defaultValue={pickerType}
                onChange={(event) => setPickerType(event.target.value as 'date')}
                sx={{ display: 'flex', py: 4, px: 4 }}
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label={t(datePicker.radioLabel1.key, datePicker.radioLabel1.defaultValue)}
                />
                <FormControlLabel
                  value="week"
                  control={<Radio />}
                  label={t(datePicker.radioLabel2.key, datePicker.radioLabel2.defaultValue)}
                />
              </RadioGroup>
            }
          />
        </StyledCategoryContainer>
      }
      headerBorderType="dashed"
      body={
        <div>
          <Container padding={isMobile ? [10, 5] : [10, 17]} wrap="nowrap" data-testid="home">
            <Container
              direction={isMobile ? 'vertical' : 'horizontal'}
              wrap="nowrap"
              space="between"
              position={undefined}
            >
              <Container wrap="nowrap">
                <Typography type="h3" data-testid="store-name" overflow="visible">
                  {stores.find((store) => store.storeId === currentStoreId)?.popularName +
                    ' ' +
                    `(${currentStoreId})`}
                </Typography>
                <Typography
                  type="body3"
                  data-testid={!isCurrentDay ? 'yesterday-date' : 'selected-date'}
                  overflow="visible"
                >
                  {t(
                    widgetCategory[selectedCategory.selectedName].key,
                    widgetCategory[selectedCategory.selectedName].defaultValue,
                  )}
                  {' - '}
                  {dateLabel}
                </Typography>
              </Container>
              {[WidgetsCategory.ALL, WidgetsCategory.SALESEFFICIENCY].includes(selectedCategory.selectedName) &&
                canViewAreaComparison && (
                  <StyledSwitchWrapper isMobile={isMobile}>
                    <Switch
                      data-testid="area-widget-switch"
                      isActive={showAreaWidget}
                      label={
                        showAreaWidget
                          ? t(common.hideAreaSwitch.key, common.hideAreaSwitch.defaultValue)
                          : t(common.showAreaSwitch.key, common.showAreaSwitch.defaultValue)
                      }
                      labelPosition="left"
                      labelType="body3"
                      style={{ justifyContent: 'flex-end' }}
                      onClick={() => {
                        setShowAreaWidget(!showAreaWidget);
                        localStorage.setItem('showAreaComparisonWidget', JSON.stringify(!showAreaWidget));
                      }}
                    />
                  </StyledSwitchWrapper>
                )}
            </Container>
            <WidgetsContainer
              showAreaWidget={showAreaWidget}
              isNonSigninUser={showHomeForNonSignInUser}
              isToday={isCurrentDay}
              selectedCategory={selectedCategory.selectedName}
              selectedDate={selectedDate}
              interval={currentInterval}
            />
          </Container>
        </div>
      }
    />
  );
};
