import { useDecision } from '@optimizely/react-sdk';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import {
  KpiDropdownActions,
  KpiEditTypes,
  KpiModalViews,
  KpiToApiMapping,
  KpiType,
  StorePerformanceInterval,
  UpdateKpiRequest,
} from '../../../constants';
import { useEventContext } from '../../../global-state/eventContext';
import { KpiTargetsProps, useAsyncAction, useStoreInformation, useStores, useUser } from '../../../hooks';
import { getOptimiselyPermission, getWeekBounds, isFailureResponse } from '../../../utils';
import KpiModal from '../../kpiModal/KpiModal';
import { KpiModalDetails } from '../../kpiTarget/KpiTarget';
import { WidgetType, WidgetsCategory } from '../types';
import { createGetIsWidgetVisible, createGetWidgetOrder } from '../util';
import AppCustomers from '../widgets/customerBaseValue/appCustomers/AppCustomers';
import { NewMembersWidget } from '../widgets/customerBaseValue/newMembers/NewMembersWidget';
import { ShareOfReceipts } from '../widgets/customerBaseValue/shareOfReceipts/ShareOfReceipts';
import { TopSellingGarmentWidget } from '../widgets/customerBaseValue/topSellingGarment/TopSellingGarmentWidget';
import { CustomerFeedbackWidget } from '../widgets/customerExperience/customerFeedback/CustomerFeedbackWidget';
import { QueueLengthWidget } from '../widgets/customerExperience/queueLength/QueueLengthWidget';
import { TopReturningGarmentWidget } from '../widgets/customerExperience/topReturningGarment/TopReturningGarment';
import { AssetManagementWidget } from '../widgets/other/assetManagement/AssetManagement';
import { RegisteredReceiptsWidget } from '../widgets/other/registeredReceipts/RegisteredReceiptsWidget';
import { TenderOverviewWidget } from '../widgets/other/tenderOverview/TenderOverviewWidget';
import AreaComparisonWidget from '../widgets/salesAndEfficiency/areaComparison/AreaComparison';
import { AveragePurchaseWidget } from '../widgets/salesAndEfficiency/averagePurchase/AveragePurchaseWidget';
import { ConversionRateWidget } from '../widgets/salesAndEfficiency/conversionRate/ConversionRateWidget';
import { EffectiveHoursWidget } from '../widgets/salesAndEfficiency/effectiveHours/EffectiveHoursWidget';
import { HourlyTurnoverWidget } from '../widgets/salesAndEfficiency/hourlyTurnover/HourlyTurnoverWidget';
import { PiecesPerReceiptWidget } from '../widgets/salesAndEfficiency/piecesPerReceipt/PiecesPerReceiptWidget';
import { ReceiptSalesWidget } from '../widgets/salesAndEfficiency/receiptSales/ReceiptSalesWidget';
import { WidgetCard } from './WidgetCard';
import { Container } from './WidgetsContainer.styles';

export type WidgetEnable = {
  [key: string]: boolean;
};

export type WidgetsContainerProps = {
  showAreaWidget: boolean;
  selectedCategory: WidgetsCategory;
  selectedDate: Date;
  isToday: boolean;
  isNonSigninUser?: boolean;
  interval?: StorePerformanceInterval;
};

export const WidgetsContainer: FC<WidgetsContainerProps> = observer(
  ({
    showAreaWidget,
    selectedCategory,
    selectedDate,
    isToday,
    isNonSigninUser,
    interval = StorePerformanceInterval.DAILY,
  }) => {
    const {
      set,
      get: { highlightedKpiTargets, userPermissions, userId },
    } = useUser();
    const { countryCode, corporateBrandId } = useStoreInformation();

    const { transactionEventsCount } = useEventContext();
    const { fetchKpiTargets, updateKpiData } = useStores();
    const [newAppCustomers] = useDecision('new_app_customers_widget', {}, { overrideUserId: userId || undefined });
    const [csatWidget] = useDecision('csat-widget', {}, { overrideUserId: userId || undefined });
    const [regionSettings] = useDecision('region_settings', {}, { overrideUserId: userId || undefined });
    const showAppCustomersWidget = newAppCustomers.enabled || process.env.NODE_ENV === 'development';

    const { getIsWidgetDisabled } = getOptimiselyPermission(countryCode, corporateBrandId, regionSettings);

    const [modalView, setModalView] = useState<KpiModalViews>(KpiModalViews.INITIAL);
    const [kpiModalDetails, setKpiModalDetails] = useState<Omit<KpiModalDetails, 'isAreaCap'>>({
      isModalOpen: false,
      kpiType: 'dailyConversionRate',
      operation: KpiEditTypes.ADD,
    });

    const {
      result: updatedKpiTargetProps,
      isLoading: updateTargetLoading,
      triggerAction: triggerUpdateTarget,
    } = useAsyncAction<KpiTargetsProps>();

    // Update Target
    const updateTarget = async (updateRequest: UpdateKpiRequest[]) => {
      const response = await updateKpiData(updateRequest);

      if (isFailureResponse(response)) {
        setModalView(KpiModalViews.ERROR);
        throw new Error();
      }
      const { data } = response;
      setModalView(KpiModalViews.SUCCESS);
      return data;
    };

    const handleDropdownDetails = (kpiType: KpiType, action: KpiDropdownActions) => {
      const operationType = action.includes('add') ? KpiEditTypes.ADD : KpiEditTypes.UPDATE;
      setKpiModalDetails({
        isModalOpen: true,
        kpiType,
        operation: operationType,
      });
    };

    const triggerFetchKPITargets = async () => {
      const date = format(selectedDate as Date, 'yyyy-MM-dd');
      const response = await fetchKpiTargets(date);

      if (isFailureResponse(response)) {
        return null;
      }

      set({ kpiTargetsProps: response.data });
    };

    // Update the kpiTargetsProps state when updatedKpiTargetProps changes
    useEffect(() => {
      if (updatedKpiTargetProps) {
        set({ kpiTargetsProps: updatedKpiTargetProps });
      }
    }, [updatedKpiTargetProps]);

    useEffect(() => {
      if (interval === StorePerformanceInterval.WEEKLY) return; // TODO: Remove this once backend implements this
      triggerFetchKPITargets();
    }, [selectedDate, interval]);

    const hasPermissionToEditKpi = userPermissions.kpiTargets.canUpdate;
    const hasTransactionSearchPageReadPermission = userPermissions.transactionSearchPage.canRead;

    const { getWidgetOrder } = createGetWidgetOrder(selectedCategory, highlightedKpiTargets);
    const { getIsWidgetVisible } = createGetIsWidgetVisible({
      userPermissions,
      selectedCategory,
      highlightedKpiTargets,
      getIsWidgetDisabled: (widgetType: WidgetType) => getIsWidgetDisabled(widgetType),
    });

    const { weekStart, weekEnd } = getWeekBounds(selectedDate);

    return (
      <Container data-testid="widgets-container">
        <KpiModal
          isOpen={kpiModalDetails.isModalOpen}
          onKpiModalClose={() => {
            setKpiModalDetails({
              ...kpiModalDetails,
              isModalOpen: false,
            });
            setModalView(KpiModalViews.INITIAL);
          }}
          kpiType={kpiModalDetails.kpiType}
          widgetName={KpiToApiMapping[kpiModalDetails.kpiType].widgetName}
          updateType={kpiModalDetails.operation}
          modalView={modalView}
          onKpiModalRetry={() => setModalView(KpiModalViews.INITIAL)}
          isModalLoading={updateTargetLoading}
          handleUpdateKpi={(updateRequest) => {
            triggerUpdateTarget(() => updateTarget(updateRequest));
          }}
        />

        {/* New Members Widget */}
        {getIsWidgetVisible(WidgetType.NewMembers) && (
          <WidgetCard col={1} order={getWidgetOrder(WidgetType.NewMembers)} data-testid="widget-container">
            <NewMembersWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* ConversionRate Widget */}
        {getIsWidgetVisible(WidgetType.ConversionRate) && (
          <WidgetCard col={2} order={getWidgetOrder(WidgetType.ConversionRate)} data-testid="widget-container">
            <ConversionRateWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isNonSigninUser={isNonSigninUser}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* HourlyTurnover Widget */}
        {getIsWidgetVisible(WidgetType.HourlyTurnover) && (
          <WidgetCard col={2} order={getWidgetOrder(WidgetType.HourlyTurnover)} data-testid="widget-container">
            <HourlyTurnoverWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Top Selling Garment Widget */}
        {getIsWidgetVisible(WidgetType.TopSellingGarment) && (
          <WidgetCard col={1} order={getWidgetOrder(WidgetType.TopSellingGarment)} data-testid="widget-container">
            <TopSellingGarmentWidget
              currentDate={selectedDate}
              isNonSigninUser={isNonSigninUser}
              interval={interval}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Top Returning Garment Widget */}
        {getIsWidgetVisible(WidgetType.TopReturningGarment) && (
          <WidgetCard
            col={1.5}
            order={getWidgetOrder(WidgetType.TopReturningGarment)}
            data-testid="widget-container"
          >
            <TopReturningGarmentWidget
              currentDate={selectedDate}
              interval={interval}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* ShareOfReceipts Widget */}
        {getIsWidgetVisible(WidgetType.ShareOfReceipts) && (
          <WidgetCard col={1.5} order={getWidgetOrder(WidgetType.ShareOfReceipts)} data-testid="widget-container">
            <ShareOfReceipts
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              transactionEventsCount={transactionEventsCount}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* App Customers Widget */}
        {/* TODO: Remove this flag while integrating the new app customers widget */}
        {showAppCustomersWidget && getIsWidgetVisible(WidgetType.AppCustomersWidget) && (
          <WidgetCard
            col={1.5}
            order={getWidgetOrder(WidgetType.AppCustomersWidget)}
            data-testid="widget-container"
          >
            <AppCustomers
              isToday={isToday}
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Effective Hours Widget */}
        {getIsWidgetVisible(WidgetType.EffectiveHoursWidget) && (
          <WidgetCard
            col={1}
            order={getWidgetOrder(WidgetType.EffectiveHoursWidget)}
            data-testid="widget-container"
          >
            <EffectiveHoursWidget
              isToday={isToday}
              currentDate={selectedDate}
              interval={interval}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Receipt Sales Widget */}
        {getIsWidgetVisible(WidgetType.ReceiptSales) && (
          <WidgetCard col={1.5} order={getWidgetOrder(WidgetType.ReceiptSales)} data-testid="widget-container">
            <ReceiptSalesWidget
              currentDate={selectedDate}
              interval={interval}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Pieces Per Receipt Widget */}
        {getIsWidgetVisible(WidgetType.PiecesPerReceipt) && (
          <WidgetCard col={1.5} order={getWidgetOrder(WidgetType.PiecesPerReceipt)} data-testid="widget-container">
            <PiecesPerReceiptWidget
              interval={interval}
              currentDate={selectedDate}
              isToday={isToday}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Average Purchase Widget */}
        {getIsWidgetVisible(WidgetType.AveragePurchaseWidget) && (
          <WidgetCard
            col={1.5}
            order={getWidgetOrder(WidgetType.AveragePurchaseWidget)}
            data-testid="widget-container"
          >
            <AveragePurchaseWidget
              interval={interval}
              currentDate={selectedDate}
              isToday={isToday}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Area Comparison Widget */}
        {getIsWidgetVisible(WidgetType.AreaComparisonWidget) && showAreaWidget && (
          <WidgetCard
            col={2}
            order={getWidgetOrder(WidgetType.AreaComparisonWidget)}
            data-testid="widget-container"
          >
            <AreaComparisonWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Tender Widget */}
        {getIsWidgetVisible(WidgetType.TenderOverview) && (
          <WidgetCard col={1.5} order={getWidgetOrder(WidgetType.TenderOverview)} data-testid="widget-container">
            <TenderOverviewWidget currentDate={selectedDate} interval={interval} />
          </WidgetCard>
        )}

        {/* Registered receipts Widget */}
        {getIsWidgetVisible(WidgetType.RegisteredReceipts) && (
          <WidgetCard col={1} order={getWidgetOrder(WidgetType.RegisteredReceipts)} data-testid="widget-container">
            <RegisteredReceiptsWidget
              isNonSigninUser={isNonSigninUser}
              hasTransactionSearchPageReadPermission={hasTransactionSearchPageReadPermission}
              currentDate={selectedDate}
              transactionEventsCount={transactionEventsCount}
              interval={interval}
            />
          </WidgetCard>
        )}

        {/* Asset Management Widget */}
        {getIsWidgetVisible(WidgetType.AssetManagement) && (
          <WidgetCard col={2} order={getWidgetOrder(WidgetType.AssetManagement)} data-testid="widget-container">
            <AssetManagementWidget currentDate={selectedDate} interval={interval} />
          </WidgetCard>
        )}

        {/* Queue Length Widget */}
        {getIsWidgetVisible(WidgetType.QueueLengthWidget) && (
          <WidgetCard
            col={1.5}
            order={getWidgetOrder(WidgetType.QueueLengthWidget)}
            data-testid="widget-container"
          >
            <QueueLengthWidget
              interval={interval}
              currentDate={selectedDate}
              isToday={isToday}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              fromDate={weekStart}
              toDate={weekEnd}
              handleDropdownDetails={handleDropdownDetails}
            />
          </WidgetCard>
        )}

        {/* Customer Feedback Widget */}
        {csatWidget.enabled && getIsWidgetVisible(WidgetType.CustomerFeedBackWidget) && (
          <WidgetCard
            col={1}
            order={getWidgetOrder(WidgetType.CustomerFeedBackWidget)}
            data-testid="widget-container"
          >
            <CustomerFeedbackWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
      </Container>
    );
  },
);
