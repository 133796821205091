import { ToggleButton as MuiToggleButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../../../assets/articlePlaceholder.png';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { TopSellingGarmentItem, useAsyncAction, useSoldProducts, useStoreInformation } from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { Card, DynamicTab, Row, TabContentProps, TabLabelProps, Table } from '../../../../../stories/molecules';
import { ToggleButton } from '../../../../../stories/molecules/toggleButton/ToggleButton';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { getArticleAssetUrl } from '../../../../../utils/soldProducts';
import { WidgetCommonState } from '../../../common/widgetCommonState/WidgetCommonState';
import { StyledArticles, StyledItem, StyledView } from './TopSellingGarmentWidget.styles';

export type TopSellingGarmentWidgetProps = {
  currentDate: Date;
  isNonSigninUser?: boolean;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export const TopSellingGarmentWidget: FC<TopSellingGarmentWidgetProps> = observer(
  ({ currentDate, interval, fromDate, toDate }) => {
    const { fetchTopSellingGarmentData } = useSoldProducts();
    const { transactionEventsCount } = useEventContext();
    const { currencyCode: storeCurrency } = useStoreInformation();

    const [tabIndex, setTabIndex] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
    const [topSellingGarmentTabs, setTopSellingGarmentTabs] = useState<TabLabelProps[]>([]);
    const [topSellingGarmentContent, setTopSellingGarmentContent] = useState<TabContentProps[]>([]);
    const {
      result: topSellingData,
      isLoading: topSellingLoading,
      isError: topSellingError,
      triggerAction: triggerFetchTopSelling,
    } = useAsyncAction<TopSellingGarmentItem[]>();

    const [view, setView] = useState('view1');

    const { mode } = useThemeContext();

    const { t } = useTranslation();
    const { topSelling, common } = homeTranslations;

    const fetchTopSellingData = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchTopSellingGarmentData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }
      setLastUpdated(response.data.lastUpdated);

      return response.data.topSellingGarments;
    };

    useEffect(() => {
      triggerFetchTopSelling(fetchTopSellingData);
    }, [currentDate, interval, transactionEventsCount]);

    const getUpdatedRows = (categoryGroup: TopSellingGarmentItem[]) => {
      const sortedCategoryGroup = categoryGroup.sort((a, b) => b.soldPieces - a.soldPieces);

      return sortedCategoryGroup.map(
        ({ title, soldPieces, productId, whitePrice, articleIdentifier, moneyGenerated }, index) => {
          const imageUrl = getArticleAssetUrl({
            articleId: `${productId}${articleIdentifier}`,
            assetType: 'descriptiveStillLife',
            rendition: 'medium',
          });

          return {
            id: index,
            item: (
              <StyledArticles>
                <StyledItem
                  data-testid="article-image"
                  src={imageUrl}
                  alt=""
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = articlePlaceholder;
                  }}
                  width="100"
                  height="100"
                />
                <div>
                  <Typography>{title}</Typography>
                  <Typography>{whitePrice}</Typography>
                </div>
              </StyledArticles>
            ),
            count: (
              <Typography color="textGray">
                {view === 'view1'
                  ? soldPieces + ' pc'
                  : parseFloat(moneyGenerated).toFixed(1) + ' ' + storeCurrency}
              </Typography>
            ),
          };
        },
      );
    };

    useEffect(() => {
      if (!topSellingData) return;

      // Filtering category data from main response into categoryGroups
      const categoryGroups = topSellingData.reduce((map, item) => {
        const { category } = item;
        const group = map.get(category) || [];
        group.push(item);
        map.set(category, group);
        return map;
      }, new Map());

      // Setting two useStates for tabs and content dynamically taking top 10 items
      const tabLabelArray = [
        { tabName: t(common.allTabName.key, common.allTabName.defaultValue), isTabVisible: true },
      ];
      const tabContentArray = [
        { tabContent: getTabContent(getUpdatedRows(topSellingData.slice(0, 10))), visible: true },
      ];

      categoryGroups.forEach((value, key) => {
        const newTab = { tabName: key, isTabVisible: true };
        const newTabcontent = { tabContent: getTabContent(getUpdatedRows(value.slice(0, 10))), visible: true };
        tabLabelArray.push(newTab);
        tabContentArray.push(newTabcontent);
      });

      setTopSellingGarmentTabs(tabLabelArray);
      setTopSellingGarmentContent(tabContentArray);
    }, [view, topSellingData]);

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const getTabContent = (tabRows: Row[]) => {
      return (
        <Table
          stickyHeader
          noBorder
          fixedHeight={'270px'}
          rows={tabRows}
          noHeader
          cellSize="small"
          columns={[
            {
              id: 'item',
              name: t(common.type.key, common.type.defaultValue),
              width: '70%',
            },
            {
              id: 'count',
              name: t(common.count.key, common.count.defaultValue),
              width: '30%',
            },
          ]}
          isLoading={topSellingLoading}
          skeletonLoaderOptions={{ numberOfRows: 4 }}
        />
      );
    };

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
      if (view2 !== null) {
        setView(view2);
      }
    };

    const isNodata = topSellingData && topSellingData.length === 0;

    return (
      <Card
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(topSelling.title.key, topSelling.title.defaultValue)}
        subHeaderText={t(topSelling.subTitle.key, topSelling.subTitle.defaultValue)}
        toolTipText={t(topSelling.toolTip.key, topSelling.toolTip.defaultValue)}
        lastUpdatedDate={lastUpdated}
        headerRightElement={
          <ToggleButton
            value={view}
            onChange={handleChangeView}
            leftButton={
              <MuiToggleButton value="view1" aria-label="left button">
                <Icon data-testid="left-icon" icon={Icons.DELIVERY} size="small" />
              </MuiToggleButton>
            }
            rightButton={Icons.DOLLAR}
          />
        }
        content={
          <>
            {topSellingLoading && <WidgetCommonState isLoading />}

            {topSellingError && (
              <WidgetCommonState isError errorText={t(topSelling.error.key, topSelling.error.defaultValue)} />
            )}

            {!topSellingLoading && !topSellingError && isNodata && <WidgetCommonState isNoData />}

            {!topSellingLoading && !topSellingError && topSellingData && topSellingData.length > 0 && (
              <Container wrap="nowrap" data-testid="top-selling-garment-overview" height="100%">
                <StyledView wrap="nowrap" data-testid="top-selling-garment-table" hasBorder>
                  <DynamicTab
                    tabLabelArray={topSellingGarmentTabs}
                    handleTabChange={handleTabChange}
                    tabValue={tabIndex}
                    variant="scrollable"
                    tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
                    content={topSellingGarmentContent}
                  />
                </StyledView>
              </Container>
            )}
          </>
        }
      />
    );
  },
);
