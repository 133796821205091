import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { CustomerFeedbackResponse, useTransaction } from '../../../../../hooks';
import { useAsyncAction } from '../../../../../hooks/useAsyncAction';
import { Container, Icons, Typography } from '../../../../../stories/atoms';
import { Card, IconWithText } from '../../../../../stories/molecules';
import { Charts } from '../../../../../stories/molecules/charts/Charts';
import { color } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { ratingFilters } from '../../../../../utils/customerFeedback';
import { WidgetCommonState } from '../../../common/widgetCommonState/WidgetCommonState';
import { ViewResponsesModal } from './ViewResponsesModal';

export type CustomerFeedbackWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  fromDate?: Date;
  toDate?: Date;
};

export const CustomerFeedbackWidget: FC<CustomerFeedbackWidgetProps> = observer(
  ({ interval, currentDate, fromDate, toDate }) => {
    const [isResponsesModalOpen, setIsResponsesModalOpen] = useState(false);

    const { t } = useTranslation();
    const { fetchCustomerFeedbackData } = useTransaction();
    const { mode } = useThemeContext();
    const { customerFeedback: translations } = homeTranslations;

    const {
      result: customerFeedbackData,
      isLoading,
      isError,
      triggerAction,
    } = useAsyncAction<CustomerFeedbackResponse>();

    const fetchCustomerFeedback = useCallback(async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchCustomerFeedbackData(request);
      if (isFailureResponse(response)) throw new Error();

      return response.data;
    }, [interval, currentDate]);

    useEffect(() => {
      triggerAction(fetchCustomerFeedback);
    }, [interval, currentDate]);

    // Calculate feedback statistics
    const totalResponses = customerFeedbackData?.feedback.length || 0;

    // Group feedback by rating
    const ratingsBreakdown = customerFeedbackData?.feedback.reduce(
      (acc: number[], { rating }) => {
        if (rating >= 1 && rating <= 5) {
          acc[rating - 1] += 1; // Increment based on the rating
        }
        return acc;
      },
      [0, 0, 0, 0, 0],
    ) || [0, 0, 0, 0, 0];

    // Calculate satisfaction percentage (5-star and 4-star ratings)
    const satisfactionPercentage = totalResponses
      ? ((ratingsBreakdown[4] + ratingsBreakdown[3]) / totalResponses) * 100
      : 0;

    return (
      <Card
        headerIcon={Icons.PROFILE}
        headerText={t(translations.title.key, translations.title.defaultValue)}
        subHeaderText={t(translations.subTitle.key, translations.subTitle.defaultValue)}
        toolTipText={t(translations.toolTip.key, translations.toolTip.defaultValue)}
        lastUpdatedDate={customerFeedbackData?.lastUpdated}
        content={
          <>
            {isLoading && <WidgetCommonState isLoading />}
            {isError && (
              <WidgetCommonState isError errorText={t(translations.error.key, translations.error.defaultValue)} />
            )}
            {!isLoading && !isError && !totalResponses && (
              <WidgetCommonState
                isNoData
                customNoDataText={t(translations.noData.key, translations.noData.defaultValue)}
              />
            )}
            {!isLoading && !isError && totalResponses && customerFeedbackData && (
              <Container
                style={{ borderTop: `1px solid ${color.borderGray}` }}
                wrap="nowrap"
                data-testid="customer-feedback-widget-wrapper"
              >
                <Container direction="horizontal" space="between" style={{ alignItems: 'center' }}>
                  <div>
                    <Typography padding={[3, 5, 0]}>
                      {t(translations.customerResponses.key, translations.customerResponses.defaultValue)}
                    </Typography>
                    <Container direction="horizontal" style={{ alignItems: 'baseline' }}>
                      <Typography type="h3" padding={[0, 0, 0, 5]}>
                        {totalResponses}
                      </Typography>
                      <Typography type="body2" padding={[0, 0, 0, 2]}>
                        {satisfactionPercentage.toFixed(2)}%
                      </Typography>
                    </Container>
                  </div>
                  <IconWithText
                    label={t(translations.viewAllResponses.key, translations.viewAllResponses.defaultValue)}
                    onClick={() => setIsResponsesModalOpen(true)}
                    data-testid="view-all-responses-button"
                    backgroundColor={mode === 'dark' ? 'borderGray' : 'backgroundSelectedDark'}
                    textOptions={{
                      type: 'body3',
                      color: 'primary',
                    }}
                    padding={[2, 3]}
                    containerStyles={{ borderRadius: 6, marginRight: 25 }}
                  />
                </Container>
                <Charts
                  areaChartGradient={{
                    type: ['solid'],
                  }}
                  chartType="pie"
                  xAxisCategories={ratingFilters.map((filter) => filter.label)}
                  chartSeries={ratingsBreakdown}
                  legend={{
                    customLegendItems: ratingFilters.map((filter) => filter.label),
                    position: 'bottom',
                    horizontalAlign: 'center',
                    labels: { colors: mode === 'dark' ? 'white' : 'primary' },
                  }}
                  isAnimationEnabled={false}
                  chartColors={['error', 'warning', 'info', 'success', 'success']}
                  dataLabel={{
                    enabled: true,
                    formatter: function (val: number) {
                      return val.toFixed(1) + '%'; // Show percentages on slices
                    },
                    style: {
                      fontWeight: 'bold',
                      fontSize: '10px',
                      colors: ['primary'],
                    },
                    dropShadow: {
                      enabled: false,
                    },
                  }}
                  chartPlotOptions={{
                    expandOnClick: false,
                    customScale: 1.2,
                    offsetY: 10,
                  }}
                  strokeWidth={1}
                />

                <ViewResponsesModal
                  isOpen={isResponsesModalOpen}
                  feedbacks={customerFeedbackData?.feedback || []}
                  onClose={() => setIsResponsesModalOpen(false)}
                />
              </Container>
            )}
          </>
        }
      />
    );
  },
);
