import { KpiType } from '../../constants';
import { UserPermissions } from '../../utils';
import { CategoryWidgetOrderMap, WidgetType, WidgetsCategory } from './types';

export const kpiTypeToWidgetTypeMap: { [key in KpiType]: WidgetType | null } = {
  dailyConversionRate: WidgetType.ConversionRate,
  dailyNewMembers: WidgetType.NewMembers,
  dailyShareOfReceipts: WidgetType.ShareOfReceipts,
  dailyTurnOver: WidgetType.HourlyTurnover,
  dailyQueueLength: WidgetType.QueueLengthWidget,
};

export const widgetsCategoryToWidgetTypesMap: { [key in WidgetsCategory]: WidgetType[] } = {
  allWidgets: Object.values(WidgetType),
  customerExperience: [
    WidgetType.TopReturningGarment,
    WidgetType.QueueLengthWidget,
    WidgetType.CustomerFeedBackWidget,
  ],
  customerBaseValue: [
    WidgetType.NewMembers,
    WidgetType.ShareOfReceipts,
    WidgetType.TopSellingGarment,
    WidgetType.AppCustomersWidget,
  ],
  salesEfficiency: [
    WidgetType.AreaComparisonWidget,
    WidgetType.ConversionRate,
    WidgetType.HourlyTurnover,
    WidgetType.ReceiptSales,
    WidgetType.PiecesPerReceipt,
    WidgetType.AveragePurchaseWidget,
    WidgetType.EffectiveHoursWidget,
  ],
  transactions: [WidgetType.TenderOverview, WidgetType.RegisteredReceipts, WidgetType.AssetManagement],
  highlightedWidgets: [],
};

const getHighlightedWidgetsOrder = (highlightedWidgets: KpiType[]): CategoryWidgetOrderMap => {
  const categoryWidgetOrderMap: CategoryWidgetOrderMap = {};

  let currentOrder = 1;
  highlightedWidgets.forEach((kPITargetWidget) => {
    const widgetType = kpiTypeToWidgetTypeMap[kPITargetWidget];

    if (widgetType === null) return;

    categoryWidgetOrderMap[widgetType] = currentOrder;
    currentOrder += 1;
  });

  return categoryWidgetOrderMap;
};

export const createGetWidgetOrder = (
  selectedCategory: WidgetsCategory,
  highlightedWidgets: KpiType[],
): { getWidgetOrder: (widgetType: WidgetType) => number } => {
  const highlightedWidgetsOrder = getHighlightedWidgetsOrder(highlightedWidgets);

  const allWidgetsOrder: CategoryWidgetOrderMap = {
    AreaComparisonWidget: 1,
    HourlyTurnover: 2, // NetSales
    ConversionRate: 3,
    PiecesPerReceipt: 4,
    AveragePurchaseWidget: 5,
    ReceiptSales: 6, // Efficiency
    EffectiveHoursWidget: 7,
    QueueLengthWidget: 8,
    TopReturningGarment: 9,
    TopSellingGarment: 10,
    NewMembers: 11,
    ShareOfReceipts: 12,
    AppCustomersWidget: 13,
    CustomerFeedBackWidget: 14,
    RegisteredReceipts: 15,
    AssetManagement: 16,
    TenderOverview: 17,
  };

  const salesEfficiencyWidgetsOrder: CategoryWidgetOrderMap = {
    AreaComparisonWidget: 1,
    ConversionRate: 2,
    HourlyTurnover: 3,
    PiecesPerReceipt: 4,
    AveragePurchaseWidget: 5,
    ReceiptSales: 6,
    EffectiveHoursWidget: 7,
  };

  const customerWidgetsOrder: CategoryWidgetOrderMap = {
    NewMembers: 1,
    ShareOfReceipts: 2,
    TopSellingGarment: 3,
    AppCustomersWidget: 4,
  };

  const customerExperienceWidgetsOrder: CategoryWidgetOrderMap = {
    TopReturningGarment: 1,
    QueueLengthWidget: 2,
    CustomerFeedBackWidget: 3,
  };

  const transactionsWidgetsOrder: CategoryWidgetOrderMap = {
    RegisteredReceipts: 1,
    TenderOverview: 2,
    AssetManagement: 3,
  };

  const getWidgetOrder = (widgetType: WidgetType) => {
    const widgetOrderMap: { [key in WidgetsCategory]: number } = {
      [WidgetsCategory.ALL]: allWidgetsOrder[widgetType] || 0,
      [WidgetsCategory.HIGHLIGHTED]: highlightedWidgetsOrder[widgetType] || 0,
      [WidgetsCategory.CUSTOMERBASEVALUE]: customerWidgetsOrder[widgetType] || 0,
      [WidgetsCategory.SALESEFFICIENCY]: salesEfficiencyWidgetsOrder[widgetType] || 0,
      [WidgetsCategory.CUSTOMEREXPERIENCE]: customerExperienceWidgetsOrder[widgetType] || 0,
      [WidgetsCategory.TRANSACTIONS]: transactionsWidgetsOrder[widgetType] || 0,
    };

    return widgetOrderMap[selectedCategory];
  };

  return { getWidgetOrder };
};

export const getPermissonToView = (widgetType: WidgetType, userPermissions: UserPermissions): boolean => {
  const widgetTypePermissionMap: { [key in WidgetType]: boolean } = {
    [WidgetType.AssetManagement]: userPermissions.assetManagementWidget.canRead,
    [WidgetType.AveragePurchaseWidget]: userPermissions.avgPurchasePerReceiptWidget.canRead,
    [WidgetType.ConversionRate]: userPermissions.conversionRateWidget.canRead,
    [WidgetType.HourlyTurnover]: userPermissions.salesPerformanceWidget.canRead,
    [WidgetType.NewMembers]: userPermissions.newMembersWidget.canRead,
    [WidgetType.AppCustomersWidget]: true, // TODO: Add permission while BE integration
    [WidgetType.PiecesPerReceipt]: userPermissions.avgPiecesPerReceiptWidget.canRead,
    [WidgetType.ReceiptSales]: userPermissions.takt1Widget.canRead || userPermissions.sptWidget.canRead,
    [WidgetType.RegisteredReceipts]: userPermissions.registeredReceiptsWidget.canRead,
    [WidgetType.ShareOfReceipts]: userPermissions.shareOfReceiptWidget.canRead,
    [WidgetType.TenderOverview]: userPermissions.tenderOverviewWidget.canRead,
    [WidgetType.TopReturningGarment]: userPermissions.topSellingGarmentsWidget.canRead,
    [WidgetType.TopSellingGarment]: userPermissions.topSellingGarmentsWidget.canRead,
    [WidgetType.EffectiveHoursWidget]: userPermissions.effectiveHoursWidget.canRead,
    [WidgetType.QueueLengthWidget]: userPermissions.queueLengthWidget.canRead,
    [WidgetType.AreaComparisonWidget]: userPermissions.areaComparisonWidget.canRead,
    [WidgetType.CustomerFeedBackWidget]: userPermissions.customerFeedBackWidget.canRead,
  };

  return widgetTypePermissionMap[widgetType] ?? false;
};

export const createGetIsWidgetVisible = (params: {
  selectedCategory: WidgetsCategory;
  highlightedKpiTargets: KpiType[];
  userPermissions: UserPermissions;
  getIsWidgetDisabled: (widgetType: WidgetType) => boolean;
}): { getIsWidgetVisible: (widgetType: WidgetType) => boolean } => {
  const getIsWidgetVisible = (widgetType: WidgetType) => {
    const { userPermissions, selectedCategory, highlightedKpiTargets, getIsWidgetDisabled } = params;

    if (getIsWidgetDisabled(widgetType)) return false;

    const hasPermissonToView = getPermissonToView(widgetType, userPermissions);

    if (!hasPermissonToView) return false;

    const isWidgetInCategory = !!widgetsCategoryToWidgetTypesMap[selectedCategory].find((type) => {
      return type === widgetType;
    });

    if (isWidgetInCategory) return true;

    const isWidgetHighlighted = !!highlightedKpiTargets.find((kPITarget) => {
      return kpiTypeToWidgetTypeMap[kPITarget] === widgetType;
    });

    if (isWidgetHighlighted && selectedCategory === WidgetsCategory.HIGHLIGHTED) return true;

    return false;
  };

  return { getIsWidgetVisible };
};
