export const homeTranslations = {
  onboarding: {
    title: {
      key: 'home.onboarding.title',
      defaultValue: 'Back Office Onboarding',
    },
    subTitle: {
      key: 'home.onboarding.subTitle',
      defaultValue: 'Follow the steps and get to know Back office',
    },
    complete: {
      key: 'home.onboarding.complete',
      defaultValue: 'Onboarding completed!',
    },
  },
  tenderOverview: {
    title: {
      key: 'home.tenderOverview.title',
      defaultValue: 'Payment Methods',
    },
    subTitle: {
      key: 'home.tenderOverview.subTitle',
      defaultValue: 'All transactions divided into tenders',
    },
    tender: {
      key: 'home.tenderOverview.tender',
      defaultValue: 'Tender',
    },
    error: {
      key: 'home.tenderOverview.error',
      defaultValue: 'We can´t show the payment methods widget right now.',
    },
    toolTip: {
      key: 'home.tenderOverview.toolTip',
      defaultValue: 'Transactions based on payment type',
    },
  },
  assetManagement: {
    title: {
      key: 'home.assetManagement.title',
      defaultValue: 'Asset Management',
    },
    subTitle: {
      key: 'home.assetManagement.subTitle',
      defaultValue: 'All workstations in store',
    },
    name: {
      key: 'home.assetManagement.name',
      defaultValue: 'Name',
    },
    latestTransaction: {
      key: 'home.assetManagement.latestTransaction',
      defaultValue: 'Latest Transaction',
    },
    totalAmount: {
      key: 'home.assetManagement.totalAmount',
      defaultValue: 'Total Amount',
    },
    userLog: {
      key: 'home.assetManagement.userLog',
      defaultValue: 'User Log',
    },
    status: {
      key: 'home.assetManagement.status',
      defaultValue: 'Status',
    },
    workstations: {
      key: 'home.assetManagement.workstations',
      defaultValue: 'Workstations',
    },
    active: {
      key: 'home.assetManagement.active',
      defaultValue: 'Active',
    },
    closed: {
      key: 'home.assetManagement.closed',
      defaultValue: 'Closed',
    },
    offline: {
      key: 'home.assetManagement.offline',
      defaultValue: 'Error',
    },
    warningTooltip: {
      key: 'home.assetManagement.warningTooltip',
      defaultValue: 'Till is open but warning encountered',
    },
    error: {
      key: 'home.assetManagement.error',
      defaultValue: 'We can´t show the asset management widget right now.',
    },
    toolTip: {
      key: 'home.assetManagement.toolTip',
      defaultValue: 'An overview of tills in the store',
    },
  },
  registeredReceipts: {
    title: {
      key: 'home.registeredReceipts.title',
      defaultValue: 'Registered Receipts Overview',
    },
    subTitle: {
      key: 'home.registeredReceipts.subTitle',
      defaultValue: 'All transactions divided into registered receipts',
    },
    error: {
      key: 'home.registeredReceipts.error',
      defaultValue: 'We can´t show the registered receipts widget right now.',
    },
    toolTip: {
      key: 'home.registeredReceipts.toolTip',
      defaultValue: 'Transactions based on transaction type',
    },
    header: {
      key: 'home.registeredReceipts.header',
      defaultValue: 'Total Transactions:',
    },
    saleHeader: {
      key: 'home.registeredReceipts.saleHeader',
      defaultValue: 'Sales',
    },
    returnHeader: {
      key: 'home.registeredReceipts.returnHeader',
      defaultValue: 'Voids and Returns',
    },
  },
  storeOverview: {
    key: 'home.storeOverview',
    defaultValue: 'Store Overview',
  },
  common: {
    error: {
      key: 'home.common.error',
      defaultValue: 'There was an error loading this widget',
    },
    noData: {
      key: 'home.common.nodata',
      defaultValue: 'No transaction has been made yet.',
    },
    count: {
      key: 'home.common.count',
      defaultValue: 'Count',
    },
    type: {
      key: 'home.common.type',
      defaultValue: 'Type',
    },
    dateBasedData: {
      key: 'home.common.dateBasedData',
      defaultValue: 'Data based on:',
    },
    noReceipts: {
      key: 'home.common.noReceipts',
      defaultValue: 'No receipts have been created yet.',
    },
    noWeeklyData: {
      key: 'home.common.noWeeklyData',
      defaultValue: 'No weekly data available for this widget',
    },
    loading: {
      key: 'home.common.loading',
      defaultValue: 'Loading',
    },
    targetKPINewMember: {
      key: 'home.common.targetKPINewMember',
      defaultValue: 'Target KPI New Member: ',
    },
    targetKPIHourly: {
      key: 'home.common.targetKPIHourly',
      defaultValue: 'Target KPI Net Sales: ',
    },
    targetKPICR: {
      key: 'home.common.targetKPICR',
      defaultValue: 'Target KPI for CR: ',
    },
    targetKPINMShare: {
      key: 'home.common.targetKPINMShare',
      defaultValue: 'Target KPI NM Share: ',
    },
    targetKPISOR: {
      key: 'home.common.targetKPISOR',
      defaultValue: 'Target KPI SOR: ',
    },
    targetKPIQueueLength: {
      key: 'home.common.targetKPIQueueLength',
      defaultValue: 'Target KPI Queue Length: ',
    },
    allTabName: {
      key: 'home.common.allTabName',
      defaultValue: 'All',
    },
    today: {
      key: 'home.common.today',
      defaultValue: 'Today',
    },
    yesterday: {
      key: 'home.common.yesterday',
      defaultValue: 'Yesterday',
    },
    lastWeek: {
      key: 'home.common.lastWeek',
      defaultValue: 'Last Week',
    },
    lastYear: {
      key: 'home.common.lastYear',
      defaultValue: 'Last Year',
    },
    thanActual: {
      key: 'home.common.thanActual',
      defaultValue: 'than Actual',
    },
    nearby: {
      key: 'home.common.nearby',
      defaultValue: 'Nearby',
    },
    expandAll: {
      key: 'home.common.expandAll',
      defaultValue: 'Expand All',
    },
    collapseAll: {
      key: 'home.common.collapseAll',
      defaultValue: 'Collapse All',
    },
    forecast: {
      key: 'home.common.forecast',
      defaultValue: 'Forecast',
    },
    hourlyTarget: {
      key: 'home.common.hourlyTarget',
      defaultValue: 'Hourly Target',
    },
    showAreaSwitch: {
      key: 'home.common.showAreaSwitch',
      defaultValue: 'Show Area Widget',
    },
    hideAreaSwitch: {
      key: 'home.common.hideAreaSwitch',
      defaultValue: 'Hide Area Widget',
    },
    lastUpdated: {
      key: 'home.common.lastUpdated',
      defaultValue: 'Last Updated',
    },
  },
  datePicker: {
    weekLabel: {
      key: 'home.common.datePicker.weekLabel',
      defaultValue: 'Week',
    },
    radioLabel1: {
      key: 'home.common.datePicker.radioLabel1',
      defaultValue: 'Select a Date',
    },
    radioLabel2: {
      key: 'home.common.datePicker.radioLabel2',
      defaultValue: 'Select a Week',
    },
  },
  banners: {
    translationError: {
      key: 'home.banners.translationError',
      defaultValue: 'We are facing some troubles translating to your desired language.',
    },
    onBoardingSuccess: {
      key: 'home.banners.onBoardingSuccess',
      defaultValue:
        'Nice work, you have just completed an onboarding item! Visit the onboarding list on the home screen to view your progress.',
    },
    serviceAlerts: {
      key: 'home.banners.serviceAlerts',
      defaultValue: 'We are sorry, but due to some technical issues, some of the data is not updated. ',
    },
  },
  slider: {
    generalInformation: {
      key: 'home.slider.generalInformation',
      defaultValue: 'General Information',
    },
    storeOpeningHours: {
      key: 'home.slider.storeOpeningHours',
      defaultValue: 'Opening hours',
    },
    chatBotPlaceholder: {
      key: 'home.slider.chatBotPlaceholder',
      defaultValue: 'Ask a question',
    },
  },
  budgetPlanned: {
    title: {
      key: 'home.budgetPlanned.title',
      defaultValue: 'Budget vs Planned',
    },
    budget: {
      key: 'home.budgetPlanned.budget',
      defaultValue: 'Budget',
    },
    planned: {
      key: 'home.budgetPlanned.planned',
      defaultValue: 'Planned',
    },
    budgetDropdown: {
      key: 'home.budgetPlanned.budgetDropdown',
      defaultValue: 'Show Budget/Planned',
    },
    error: {
      key: 'home.budgetPlanned.error',
      defaultValue: 'Something went wrong!',
    },
    noPermissionBudgetTooltip: {
      key: 'home.budgetPlanned.noPermissionBudgetTooltip',
      defaultValue: 'You do not have permission to view Budget/Planned',
    },
  },
  hourlyTurnover: {
    title: {
      key: 'home.hourlyTurnover.title',
      defaultValue: 'Sales Net',
    },
    subTitle: {
      key: 'home.hourlyTurnover.subTitle',
      defaultValue: 'Hourly net sales',
    },
    error: {
      key: 'home.hourlyTurnover.error',
      defaultValue: 'We can´t show the sales net widget right now.',
    },
    totalSales: {
      key: 'home.hourlyTurnover.totalSales',
      defaultValue: 'Total Sales',
    },
    toolTip: {
      key: 'home.hourlyTurnover.toolTip',
      defaultValue: 'Hourly net sales in store',
    },
  },
  conversionRate: {
    title: {
      key: 'home.conversionRate.title',
      defaultValue: 'Conversion rate',
    },
    subTitle: {
      key: 'home.conversionRate.subTitle',
      defaultValue: 'The amount of non paying & paying customers.',
    },
    error: {
      key: 'home.conversionRate.error',
      defaultValue: 'We can´t show the conversion widget right now.',
    },
    average: {
      key: 'home.conversionRate.average',
      defaultValue: 'Average',
    },
    totalVisitors: {
      key: 'home.conversionRate.totalVisitors',
      defaultValue: 'Total Visitors',
    },
    numberOfTills: {
      key: 'home.conversionRate.numberOfTills',
      defaultValue: 'Utilized Tills',
    },
    payingCustomers: {
      key: 'home.conversionRate.payingCustomers',
      defaultValue: 'Paying',
    },
    nonPayingCustomers: {
      key: 'home.conversionRate.nonPayingCustomers',
      defaultValue: 'Non Paying',
    },
    toolTip: {
      key: 'home.conversionRate.toolTip',
      defaultValue:
        'Quick overview of number of paying vs non-paying customers. The Forecast is shown in percentage',
    },
    visitors: {
      key: 'home.conversionRate.visitors',
      defaultValue: 'Visitors',
    },
  },
  salesNet: {
    title: {
      key: 'home.salesNet.title',
      defaultValue: 'Sales',
    },
    subTitle: {
      key: 'home.salesNet.subTitle',
      defaultValue: 'Weekly Net sales in store',
    },
    error: {
      key: 'home.salesNet.error',
      defaultValue: 'We can´t show the sales widget right now.',
    },
    weekly: {
      key: 'home.salesNet.weekly',
      defaultValue: 'Weekly',
    },
    actual: {
      key: 'home.salesNet.actual',
      defaultValue: 'Actual',
    },
    toolTip: {
      key: 'home.salesNet.toolTip',
      defaultValue: 'Net Sales in store for the past week. The Forecast is shown in currency.',
    },
  },
  topSelling: {
    title: {
      key: 'home.topSelling.title',
      defaultValue: 'Top selling garment',
    },
    subTitle: {
      key: 'home.topSelling.subTitle',
      defaultValue: 'In the store right now',
    },
    error: {
      key: 'home.topSelling.error',
      defaultValue: 'We can´t show the top selling garment widget right now.',
    },
    toolTip: {
      key: 'home.topSelling.toolTip',
      defaultValue: 'Top Selling Garments sold in the store',
    },
  },
  topReturns: {
    title: {
      key: 'home.topReturns.title',
      defaultValue: 'Returns',
    },
    subTitle: {
      key: 'home.topReturns.subTitle',
      defaultValue: 'Return Product Insights',
    },
    error: {
      key: 'home.topReturns.error',
      defaultValue: 'We can´t show the top returns garment widget right now.',
    },
    toolTip: {
      key: 'home.topReturns.toolTip',
      defaultValue: 'Store or ORIS Returned Garments in the store',
    },
    storeReturns: {
      key: 'home.topReturns.storeReturns',
      defaultValue: 'Store Returns',
    },
    orisReturns: {
      key: 'home.topReturns.orisReturns',
      defaultValue: 'ORIS Returns',
    },
    oris: {
      title: {
        key: 'home.topReturns.oris.title',
        defaultValue: 'ORIS Conversion Rate',
      },
      orisTotalCustomers: {
        key: 'home.topReturns.oris.orisTotalCustomer',
        defaultValue: 'ORIS Total Customers',
      },
      orisCovertedCustomers: {
        key: 'home.topReturns.oris.orisCovertedCustomers',
        defaultValue: 'ORIS Converted Customers',
      },
    },
    storeText: {
      key: 'home.topReturns.storeText',
      defaultValue: 'Store',
    },
    onlineText: {
      key: 'home.topReturns.onlineText',
      defaultValue: 'Online',
    },
  },
  newMembers: {
    title: {
      key: 'home.newMembers.title',
      defaultValue: 'New Members',
    },
    subTitle: {
      key: 'home.newMembers.subTitle',
      defaultValue: 'New H&M Member Recruited',
    },
    error: {
      key: 'home.newMembers.error',
      defaultValue: 'We can´t show the new members widget right now.',
    },
    toolTip: {
      key: 'home.newMembers.toolTip',
      defaultValue: 'Hourly view of New Members in store. The Forecast is shown in numbers',
    },
    totalNewMembers: {
      key: 'home.newMembers.totalNewMembers',
      defaultValue: 'Total New Members',
    },
    totalNMShare: {
      key: 'home.newMembers.totalNMShare',
      defaultValue: 'Total New Member',
    },
    unrecruited: {
      key: 'home.newMembers.unrecruited',
      defaultValue: 'Total Unrecruited',
    },
  },
  receiptSales: {
    title: {
      key: 'home.receiptSales.title',
      defaultValue: 'Efficiency',
    },
    subTitle: {
      key: 'home.receiptSales.subTitle',
      defaultValue: 'Net Revenue and Units Sold',
    },
    error: {
      key: 'home.receiptSales.error',
      defaultValue: 'We can´t show the efficiency widget right now.',
    },
    toolTip: {
      key: 'home.receiptSales.toolTip',
      defaultValue: 'Takt1: Sales Net/Receipts, SPT: Sold Pieces/Receipts',
    },
    takt1: {
      key: 'home.receiptSales.takt1',
      defaultValue: 'Takt1',
    },
    spt: {
      key: 'home.receiptSales.spt',
      defaultValue: 'SPT',
    },
    budgetTakt: {
      key: 'home.receiptSales.budgetTakt',
      defaultValue: 'Show Budget/Planned - Takt1',
    },
    budgetSpt: {
      key: 'home.receiptSales.budgetSpt',
      defaultValue: 'Show Budget/Planned - SPT',
    },
  },
  piecesPerReceipt: {
    title: {
      key: 'home.piecesPerReceipt.title',
      defaultValue: 'Pieces per receipt',
    },
    subTitle: {
      key: 'home.piecesPerReceipt.subTitle',
      defaultValue: 'Pieces Sold per Receipt',
    },
    error: {
      key: 'home.piecesPerReceipt.error',
      defaultValue: 'We can´t show the pieces per receipt widget right now.',
    },
    toolTip: {
      key: 'home.piecesPerReceipt.toolTip',
      defaultValue: 'Sold Pieces per receipt in store',
    },
    headerText: {
      key: 'home.piecesPerReceipt.headerText',
      defaultValue: 'Pieces/Receipt',
    },
  },
  effectiveHours: {
    title: {
      key: 'home.effectiveHours.title',
      defaultValue: 'Effective Hours',
    },
    subTitle: {
      key: 'home.effectiveHours.subTitle',
      defaultValue: 'Productive Time Tracker',
    },
    error: {
      key: 'home.effectiveHours.error',
      defaultValue: 'We can´t show the effective hours right now.',
    },
  },
  averagePurchase: {
    title: {
      key: 'home.averagePurchase.title',
      defaultValue: 'Average Purchase',
    },
    subTitle: {
      key: 'home.averagePurchase.subTitle',
      defaultValue: 'Sales Net per Receipt ',
    },
    error: {
      key: 'home.averagePurchase.error',
      defaultValue: 'We can´t show the average purchase widget right now.',
    },
    toolTip: {
      key: 'home.averagePurchase.toolTip',
      defaultValue: 'Sales Net per Receipt',
    },
  },
  shareOfReceipts: {
    title: {
      key: 'home.shareOfReceipts.title',
      defaultValue: 'Member Share Of Receipts',
    },
    subTitle: {
      key: 'home.shareOfReceipts.subTitle',
      defaultValue: 'SoRs for ',
    },
    error: {
      key: 'home.shareOfReceipts.error',
      defaultValue: 'We can´t show the member share of receipts widget right now.',
    },
    sor: {
      key: 'home.shareOfReceipts.sor',
      defaultValue: 'Share of receipts',
    },
    SofR: {
      key: 'home.shareOfReceipts.SofR',
      defaultValue: 'SoR',
    },
    nmShare: {
      key: 'home.shareOfReceipts.nmShare',
      defaultValue: 'NM Share',
    },
    newMembers: {
      key: 'home.shareOfReceipts.newMembers',
      defaultValue: 'New Members',
    },
    newMembersShare: {
      key: 'home.shareOfReceipts.newMembersShare',
      defaultValue: 'New Member Share',
    },
    day: {
      key: 'home.shareOfReceipts.day',
      defaultValue: 'Day',
    },
    toolTip: {
      key: 'home.shareOfReceipts.toolTip',
      defaultValue: 'Share of Receipts for the Member transactions. The Forecast is shown in percentage.',
    },
    totalMemberTransactions: {
      key: 'home.shareOfReceipts.totalMemberTransactions',
      defaultValue: 'Total Member Transactions',
    },
    totalShareOfReceipts: {
      key: 'home.shareOfReceipts.totalShareOfReceipts',
      defaultValue: 'Total Share Of Receipts',
    },
  },
  queueLength: {
    title: {
      key: 'home.queueLength.title',
      defaultValue: 'Queue Length',
    },
    subTitle: {
      key: 'home.queueLength.subTitle',
      defaultValue: 'Till queue count',
    },
    error: {
      key: 'home.queueLength.error',
      defaultValue: 'We can´t show the queue lenght widget right now.',
    },
    noData: {
      key: 'home.queueLength.noData',
      defaultValue: 'No Queues - Keep up the good work.',
    },
    toolTip: {
      key: 'home.queueLength.toolTip',
      defaultValue: 'This is the number of customers waiting in a queue.',
    },
    chartToolTip: {
      key: 'home.queueLength.chartToolTip',
      defaultValue: 'Overall Queue Length and Experience',
    },
    tillToolTip: {
      key: 'home.queueLength.tillToolTip',
      defaultValue: 'Till',
    },
    tabDefault: {
      key: 'home.queueLength.tabDefault',
      defaultValue: 'Regular Tills',
    },
    tabSelfCheckout: {
      key: 'home.queueLength.tabSelfCheckout',
      defaultValue: 'Self-service Checkout',
    },
    overallQueueLength: {
      key: 'home.queueLength.overallQueueLength',
      defaultValue: 'Overall Queue Length',
    },
    overallExperience: {
      key: 'home.queueLength.overallExperience',
      defaultValue: 'Overall Experience',
    },
  },
  appCustomers: {
    title: {
      key: 'home.appCustomers.title',
      defaultValue: 'App Customers',
    },
    subTitle: {
      key: 'home.appCustomers.subTitle',
      defaultValue: 'App-based Customers',
    },
    toolTip: {
      key: 'home.appCustomers.toolTip',
      defaultValue: 'Number of new customer who have made purchases directly through our mobile app',
    },
    error: {
      key: 'home.appCustomers.error',
      defaultValue: 'We can´t show the app customers widget right now.',
    },
    newCustomersText: {
      key: 'home.appCustomers.newCustomersText',
      defaultValue: 'New App Customers',
    },
  },
  customerFeedback: {
    title: {
      key: 'home.customerFeedback.title',
      defaultValue: 'Customer Satisfaction',
    },
    subTitle: {
      key: 'home.customerFeedback.subTitle',
      defaultValue: 'CSAT Summary',
    },
    toolTip: {
      key: 'home.customerFeedback.toolTip',
      defaultValue: 'Customer Satisfaction Score along with the responses',
    },
    error: {
      key: 'home.customerFeedback.error',
      defaultValue: 'We can´t show the customer feedback widget right now.',
    },
    customerResponses: {
      key: 'home.customerFeedback.customerResponses',
      defaultValue: 'Customer Responses',
    },
    viewAllResponses: {
      key: 'home.customerFeedback.viewAllResponses',
      defaultValue: 'View All Responses',
    },
    modalHeaders: {
      key: 'home.customerFeedback.modalHeaders',
      defaultValue: 'Customer responses',
    },
    modalSubHeaders: {
      key: 'home.customerFeedback.modalSubHeaders',
      defaultValue: 'View All Customer Response',
    },
    totalResponse: {
      key: 'home.customerFeedback.totalResponse',
      defaultValue: 'Total Response',
    },
    filterBy: {
      key: 'home.customerFeedback.filterBy',
      defaultValue: 'Filter By: ',
    },
    noData: {
      key: 'home.customerFeedback.noData',
      defaultValue: 'No customer feedback available yet.',
    },
  },
  areaComparison: {
    title: {
      key: 'home.areaComparison.title',
      defaultValue: 'Area',
    },
    subTitle: {
      key: 'home.areaComparison.subTitle',
      defaultValue: 'Weekly and Annual Data with Area',
    },
    toolTip: {
      key: 'home.areaComparison.toolTip',
      defaultValue: 'Target comparisons between stores in the area.',
    },
    error: {
      key: 'home.areaComparison.error',
      defaultValue: 'We can´t show the area comparison widget right now.',
    },
    equationGroup: {
      sales: {
        key: 'home.areaComparison.equationGroup.sales',
        defaultValue: 'Sales',
      },
      salesEquation: {
        key: 'home.areaComparison.equationGroup.salesEquation',
        defaultValue: 'Sales Equation',
      },
      efficiency: {
        key: 'home.areaComparison.equationGroup.efficiency',
        defaultValue: 'Efficiency',
      },
    },
  },

  widgetCategory: {
    allWidgets: {
      key: 'home.widgetCategory.allWidgets',
      defaultValue: 'All Widgets',
    },
    highlightedWidgets: {
      key: 'home.widgetCategory.highlightedWidgets',
      defaultValue: 'Highlighted Widgets',
    },
    salesEfficiency: {
      key: 'home.widgetCategory.salesEfficiency',
      defaultValue: 'Sales & Efficiency',
    },
    customerBaseValue: {
      key: 'home.widgetCategory.customerBaseValue',
      defaultValue: 'Customer Base Value',
    },
    customerExperience: {
      key: 'home.widgetCategory.customerExperience',
      defaultValue: 'Customer Experience',
    },
    transactions: {
      key: 'home.widgetCategory.transactions',
      defaultValue: 'Transactions',
    },
  },
  kpiTargets: {
    addKpi: {
      key: 'home.kpiTargets.addKpi',
      defaultValue: 'Add KPI',
    },
    editKpi: {
      key: 'home.kpiTargets.editKpi',
      defaultValue: 'Edit KPI',
    },
    addkpiForNMShare: {
      key: 'home.kpiTargets.addkpiForNMShare',
      defaultValue: 'Add KPI for NM Share',
    },
    editkpiForNMShare: {
      key: 'home.kpiTargets.editkpiForNMShare',
      defaultValue: 'Edit KPI for NM Share',
    },
    addkpiForSOR: {
      key: 'home.kpiTargets.addkpiForSOR',
      defaultValue: 'Add KPI for SOR',
    },
    editkpiForSOR: {
      key: 'home.kpiTargets.editkpiForSOR',
      defaultValue: 'Edit KPI for SOR',
    },
    editAreaCap: {
      key: 'home.kpiTargets.editAreaCap',
      defaultValue: 'Edit Area Cap',
    },
    labelText: {
      key: 'home.kpiTargets.labelText',
      defaultValue: 'Enter {{modalType}} value for {{widgetName}}',
    },
    removeLabelText: {
      key: 'home.kpiTargets.removeLabelText',
      defaultValue: 'Are you sure you want to remove {{modalType}} for {{widgetName}}?',
    },
    errorHelperText1: {
      key: 'home.kpiTargets.errorHelperText1',
      defaultValue: 'No special characters or alphabets are allowed!',
    },
    errorHelperText2: {
      key: 'home.kpiTargets.errorHelperText2',
      defaultValue: '{{modalType}} value cannot be more than 10 digits',
    },
    errorHelperText3: {
      key: 'home.kpiTargets.errorHelperText3',
      defaultValue: '{{modalType}} value cannot be more than 100',
    },
    errorHelperText4: {
      key: 'home.kpiTargets.errorHelperText4',
      defaultValue: 'This field is required!',
    },
    errorHelperText5: {
      key: 'home.kpiTargets.errorHelperText5',
      defaultValue: 'Value cannot be 0. You can choose to remove target instead',
    },
    successText: {
      key: 'home.kpiTargets.successText',
      defaultValue: 'The {{modalType}} for {{widgetName}} widget has been {{operation}} successfully.',
    },
    errorText: {
      key: 'home.kpiTargets.errorText',
      defaultValue: 'The {{modalType}} could not be updated. Please try again!',
    },
    disabledTooltip: {
      key: 'home.kpiTargets.disabledTooltip',
      defaultValue: 'No receipts created to add/edit KPI',
    },
    noPermissionTooltip: {
      key: 'home.kpiTargets.noPermissionTooltip',
      defaultValue: 'You do not have permission to edit/add KPI',
    },
    lockedTargetTooltip: {
      key: 'home.kpiTargets.lockedTargetTooltip',
      defaultValue: 'Target is locked!',
    },
    note: {
      key: 'home.kpiTargets.note',
      defaultValue: ' Note: Editing Area Cap will affect all stores in the area',
    },
  },
};
