import { soldProductsEndpoint, topReturnedGarmentEndpoint, topSellingGarmentEndpoint } from '../constants';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';
import { useUser } from './useController';
import { LastUpdatedTime, StorePerformanceRequest } from './useTransaction';

export type SoldProductItem = {
  productName: string;
  productId: string;
  price: number;
  soldPieces: number;
  departmentId: string;
  departmentName: string;
  season: string;
  salesNet: number;
  mostSoldArticle: string;
  // TODO: add after BE change
  // stockAvailable: boolean;
  // speed: number;
  // remainingPieces: number;
  // moneyGenerated: string;
  // category: string;
};

export type ProductIndexItem = {
  id: string;
  divisionName: string; //Business Unit(BU)
  indexDescription: string; //Index
  subindex: string; //Sub Index
  departmentId: string;
  departmentName: string;
  garmentGroup: string;
  salesNet: number;
  salesNetLastWeek: number;
  salesNetLastYear: number;

  lastUpdated: string;
  productName: string;
  season: number;
  whitePrice: number;
  currency: string;
  sectionName: string;
  indexGroupName: string;
};

export type GarmentResponse = {
  category: string;
  title: string;
  whitePrice: number;
  articleIdentifier: string;
  productId: string;
  currency: string;
};

export type TopSellingGarmentItem = GarmentResponse & {
  soldPieces: number;
  moneyGenerated: string;
};

export type TopSellingResponse = LastUpdatedTime & {
  topSellingGarments: TopSellingGarmentItem[];
};

export type TopReturningGarmentItem = GarmentResponse & {
  storeReturnPieces: number;
  onlineReturnPieces: number;
};

export type TopReturningResponse = LastUpdatedTime & {
  topReturningGarments: TopReturningGarmentItem[];
};

export const useSoldProducts = () => {
  const {
    get: { currentStoreId, keyToken },
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest(keyToken);

  const fetchSoldProducts = async (locale: string, date: string) => {
    const soldProductsResponse = await authAxiosRequest<SoldProductItem[]>(
      `/sold-products/${currentStoreId}/soldProducts`,
      {
        params: {
          locale,
          date,
        },
      },
    );

    return soldProductsResponse;
  };

  const fetchProductIndex = async (fromDate: string, toDate: string) => {
    const soldProductsResponse = await authAxiosRequest<ProductIndexItem[]>(
      `${soldProductsEndpoint}/${currentStoreId}/productIndex`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      },
    );

    return soldProductsResponse;
  };

  const fetchTopSellingGarmentData = async (request: StorePerformanceRequest) => {
    const topSellingGarmentResponse = await authAxiosRequest<TopSellingResponse>(
      `${soldProductsEndpoint}/${currentStoreId}${topSellingGarmentEndpoint}`,
      {
        params: request,
      },
    );

    return topSellingGarmentResponse;
  };

  const fetchTopReturningGarmentData = async (request: StorePerformanceRequest) => {
    const topReturnedGarmentResponse = await authAxiosRequest<TopReturningResponse>(
      `${soldProductsEndpoint}/${currentStoreId}${topReturnedGarmentEndpoint}`,
      {
        params: request,
      },
    );

    return topReturnedGarmentResponse;
  };

  return {
    fetchSoldProducts,
    fetchProductIndex,
    fetchTopSellingGarmentData,
    fetchTopReturningGarmentData,
  };
};
