import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { SalesNetPerReceiptPerformanceItem, useStorePerformance, useTransaction } from '../../../../../hooks';
import { Container, Icons, Typography } from '../../../../../stories/atoms';
import { Card } from '../../../../../stories/molecules';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { formatAmountWithSeparator, isFailureResponse } from '../../../../../utils';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetCommonState } from '../../../common/widgetCommonState/WidgetCommonState';
import { StyledAveragePurchaseWrapper, StyledDivider } from './AveragePurchaseWidget.styles';

export type AveragePurchaseWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  isToday: boolean;
  fromDate?: Date;
  toDate?: Date;
};

export const AveragePurchaseWidget: FC<AveragePurchaseWidgetProps> = observer(
  ({ interval, currentDate, isToday, fromDate, toDate }) => {
    const { t, i18n } = useTranslation();

    const { fetchSalesNetPerReceiptData } = useTransaction();
    const { transactionEventsCount } = useEventContext();

    const { averagePurchase: averagePurchaseTranslation } = homeTranslations;
    const { mode } = useThemeContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [today, setToday] = useState(0);
    const [lastWeek, setLastWeek] = useState(0);
    const [lastYear, setLastYear] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<SalesNetPerReceiptPerformanceItem>();

    const itemValueResolver = (item: SalesNetPerReceiptPerformanceItem) => {
      return item.amount;
    };

    const formatAmount = (value: number) => {
      return `${Intl.NumberFormat(i18n.language, { notation: 'compact' }).format(value)}`;
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchSalesNetPerReceiptData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setToday(response.data.today);
      setLastWeek(response.data.lastWeek);
      setLastYear(response.data.lastYear);
      setLastUpdated(response.data.lastUpdated);

      return response.data.netSalePerReceipt;
    }, [interval, currentDate]);

    useEffect(() => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        valueResolver: itemValueResolver,
      });
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    return (
      <Card
        headerIcon={Icons.SALESEFFICIENCY}
        headerText={t(averagePurchaseTranslation.title.key, averagePurchaseTranslation.title.defaultValue)}
        subHeaderText={t(
          averagePurchaseTranslation.subTitle.key,
          averagePurchaseTranslation.subTitle.defaultValue,
        )}
        lastUpdatedDate={lastUpdated}
        toolTipText={t(averagePurchaseTranslation.toolTip.key, averagePurchaseTranslation.toolTip.defaultValue)}
        content={
          <>
            {performanceItemsLoading && <WidgetCommonState isLoading />}

            {performanceItemsError && (
              <WidgetCommonState
                isError
                errorText={t(averagePurchaseTranslation.error.key, averagePurchaseTranslation.error.defaultValue)}
              />
            )}

            {isNoData && <WidgetCommonState isNoData />}

            {!performanceItemsLoading && !performanceItemsError && !isNoData && (
              <StyledAveragePurchaseWrapper wrap="nowrap" data-testid="salesnet-per-receipt-wrapper" height="100%">
                <Typography type="h4" padding={[0, 5]}>
                  {formatAmountWithSeparator(today)}
                </Typography>

                <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                  <WidgetChart
                    type="bar"
                    performanceItemsChartName={t(
                      averagePurchaseTranslation.toolTip.key,
                      averagePurchaseTranslation.toolTip.defaultValue,
                    )}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                    forecastPerformanceItems={forecastPerformanceItems}
                    itemValueResolver={itemValueResolver}
                    showXAxis={true}
                    showYAxis={false}
                    tooltipFormatter={(value) => formatAmountWithSeparator(value)}
                    dataLabel={{
                      enabled: true,
                      enabledOnSeries: [0, 2],
                      offsetY: -28,
                      style: {
                        fontSize: isMobile ? '8px' : '12px',
                        fontWeight: '100',
                        colors: [mode === 'dark' ? muiTheme.color.white : muiTheme.color.primary],
                      },
                      formatter: function (value) {
                        if (!value) return '';

                        return formatAmount(parseFloat(value.toString()));
                      },
                    }}
                  />
                </Container>

                <Container padding={[0, 5, 3, 5]}>
                  <Container direction="horizontal" space="between" position="center" padding={[2, 0, 2]}>
                    <Typography>Last Week</Typography>
                    <Container direction="horizontal" position="center">
                      <StyledTrendIconComponent
                        direction="horizontal"
                        originalAmount={today}
                        previousAmount={lastWeek}
                        textOptions={{ margin: [0, 2, 0] }}
                        valueFormatter={(value) => formatAmountWithSeparator(value)}
                      />
                    </Container>
                  </Container>
                  <StyledDivider data-testid="divider" />
                  <Container direction="horizontal" space="between" position="center" padding={[2, 0, 2]}>
                    <Typography>Last Year</Typography>
                    <Container direction="horizontal" position="center">
                      <StyledTrendIconComponent
                        direction="horizontal"
                        originalAmount={today}
                        previousAmount={lastYear}
                        textOptions={{ margin: [0, 2, 0] }}
                        valueFormatter={(value) => formatAmountWithSeparator(value)}
                      />
                    </Container>
                  </Container>
                </Container>
              </StyledAveragePurchaseWrapper>
            )}
          </>
        }
      />
    );
  },
);
