import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../../../assets/articlePlaceholder.png';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import {
  TopReturningGarmentItem,
  useAsyncAction,
  useSoldProducts,
  useStoreInformation,
} from '../../../../../hooks';
import { Container, Icons, Typography } from '../../../../../stories/atoms';
import { Card, DynamicTab, Row, TabContentProps, TabLabelProps, Table } from '../../../../../stories/molecules';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { getArticleAssetUrl } from '../../../../../utils/soldProducts';
import { WidgetCommonState } from '../../../common/widgetCommonState/WidgetCommonState';
import {
  StyledArticles,
  StyledItem,
  StyledTypography,
  StyledView,
} from '../../customerBaseValue/topSellingGarment/TopSellingGarmentWidget.styles';

type TopReturningGarmentWidgetProps = {
  currentDate: Date;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export const TopReturningGarmentWidget: FC<TopReturningGarmentWidgetProps> = observer(
  ({ currentDate, interval, fromDate, toDate }) => {
    const { fetchTopReturningGarmentData } = useSoldProducts();
    const { currencyCode: storeCurrency } = useStoreInformation();
    const { transactionEventsCount } = useEventContext();

    // TODO: Uncomment while integrating ORIS data
    // const [view, setView] = useState('view1');
    const [tabIndex, setTabIndex] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
    const [topReturnedGarmentTabs, setTopReturnedGarmentTabs] = useState<TabLabelProps[]>([]);
    const [topReturnedGarmentContent, setTopReturnedGarmentContent] = useState<TabContentProps[]>([]);
    const {
      result: topReturnedGarments,
      isLoading: topReturnedGarmentsLoading,
      isError: topReturnedGarmentsError,
      triggerAction: triggerFetchTopReturnedGarments,
    } = useAsyncAction<TopReturningGarmentItem[]>();

    const { mode } = useThemeContext();
    const { t } = useTranslation();
    const { topReturns: topReturnsTranslations, common } = homeTranslations;

    const fetchTopReturnedGarments = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchTopReturningGarmentData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.topReturningGarments;
    };

    const calculatePieces = (item: TopReturningGarmentItem, returnType: 'store' | 'oris' | 'both') => {
      if (returnType === 'store') {
        return item.storeReturnPieces;
      } else if (returnType === 'oris') {
        return item.onlineReturnPieces;
      } else {
        return item.storeReturnPieces + item.onlineReturnPieces;
      }
    };

    const sortCategoryGroup = (
      categoryGroup: TopReturningGarmentItem[],
      returnType: 'store' | 'oris' | 'both',
    ) => {
      return categoryGroup.sort((a, b) => calculatePieces(b, returnType) - calculatePieces(a, returnType));
    };

    const getUpdatedRows = (categoryGroup: TopReturningGarmentItem[], returnType: 'store' | 'oris' | 'both') => {
      const sortedCategoryGroup = sortCategoryGroup(categoryGroup, returnType);

      return sortedCategoryGroup.map((item, index) => {
        const { title, productId, whitePrice, articleIdentifier, storeReturnPieces, onlineReturnPieces } = item;

        const pieces = calculatePieces(item, returnType);

        const imageUrl = getArticleAssetUrl({
          articleId: `${productId}${articleIdentifier}`,
          assetType: 'descriptiveStillLife',
          rendition: 'medium',
        });

        return {
          id: index,
          item: (
            <StyledArticles>
              <StyledItem
                data-testid="article-image"
                src={imageUrl}
                alt=""
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = articlePlaceholder;
                }}
                width="100"
                height="100"
              />
              <Container>
                <Typography>{title}</Typography>
                <Container direction="horizontal">
                  <Typography>{`${whitePrice} ${storeCurrency}`}</Typography>
                  {returnType === 'both' && storeReturnPieces !== 0 && (
                    <StyledTypography padding={[1, 2]} margin={[0, 2]} type="caption">
                      {t(topReturnsTranslations.storeText.key, topReturnsTranslations.storeText.defaultValue)}
                    </StyledTypography>
                  )}
                  {returnType === 'both' && onlineReturnPieces !== 0 && (
                    <StyledTypography padding={[1, 2]} margin={[0, 2]} type="caption">
                      {t(topReturnsTranslations.onlineText.key, topReturnsTranslations.onlineText.defaultValue)}
                    </StyledTypography>
                  )}
                </Container>
              </Container>
            </StyledArticles>
          ),
          count: <Typography color="textGray">{pieces} pc</Typography>,
        };
      });
    };

    useEffect(() => {
      triggerFetchTopReturnedGarments(fetchTopReturnedGarments);
    }, [currentDate, interval, transactionEventsCount]);

    useEffect(() => {
      if (!topReturnedGarments || !topReturnedGarments.length) return;

      const groupedGarments = topReturnedGarments.reduce(
        (acc: { [key: string]: TopReturningGarmentItem[] }, garment) => {
          const { storeReturnPieces, onlineReturnPieces } = garment;

          if (storeReturnPieces !== 0 || onlineReturnPieces !== 0) {
            if (storeReturnPieces !== 0 && onlineReturnPieces === 0) {
              acc.storeReturns.push(garment);
            }
            if (onlineReturnPieces !== 0 && storeReturnPieces === 0) {
              acc.onlineReturns.push(garment);
            }
            acc.all.push(garment);
          }
          return acc;
        },
        { all: [], storeReturns: [], onlineReturns: [] },
      );

      const tabLabelArray = [
        {
          tabName: `${t(common.allTabName.key, common.allTabName.defaultValue)} (${groupedGarments.all.length})`,
          isTabVisible: groupedGarments.all.length > 0,
        },
        {
          tabName: `${t(
            topReturnsTranslations.storeReturns.key,
            topReturnsTranslations.storeReturns.defaultValue,
          )} (${groupedGarments.storeReturns.length})`,
          isTabVisible: groupedGarments.storeReturns.length > 0,
        },
        {
          tabName: `${t(
            topReturnsTranslations.orisReturns.key,
            topReturnsTranslations.orisReturns.defaultValue,
          )} (${groupedGarments.onlineReturns.length})`,
          isTabVisible: groupedGarments.onlineReturns.length > 0,
        },
      ];

      const allTabContent = {
        tabContent: getTabContent(getUpdatedRows(groupedGarments.all, 'both')),
        visible: groupedGarments.all.length > 0,
      };
      const storeReturnsTabContent = {
        tabContent: getTabContent(getUpdatedRows(groupedGarments.storeReturns, 'store')),
        visible: groupedGarments.storeReturns.length > 0,
      };
      const onlineReturnsTabContent = {
        tabContent: getTabContent(getUpdatedRows(groupedGarments.onlineReturns, 'oris')),
        visible: groupedGarments.onlineReturns.length > 0,
      };

      const tabContentArray = [allTabContent, storeReturnsTabContent, onlineReturnsTabContent];
      setTabIndex(tabLabelArray.findIndex((tab) => tab.isTabVisible));
      setTopReturnedGarmentTabs(tabLabelArray);
      setTopReturnedGarmentContent(tabContentArray);
    }, [topReturnedGarments]);

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const getTabContent = (tabRows: Row[]) => {
      return (
        <Table
          stickyHeader
          cellSize="small"
          noBorder
          fixedHeight={'270px'}
          rows={tabRows}
          noHeader
          columns={[
            {
              id: 'item',
              name: t(common.type.key, common.type.defaultValue),
              width: '75%',
            },
            {
              id: 'count',
              name: t(common.count.key, common.count.defaultValue),
              width: '25%',
            },
          ]}
          isLoading={topReturnedGarmentsLoading}
          skeletonLoaderOptions={{ numberOfRows: 4 }}
        ></Table>
      );
    };

    // TODO: Uncomment while integrating ORIS data
    // const rightContainerElements = () => {
    //   return (
    //     <ToggleButton
    //       value={view}
    //       onChange={handleChangeView}
    //       leftButton={Icons.RETURNS}
    //       rightButton={Icons.CONVERSION}
    //     />
    //   );
    // };

    // const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
    //   if (view2 !== null) {
    //     setView(view2);
    //   }
    // };
    const isNodata = topReturnedGarments && topReturnedGarments.length === 0;
    const areTabsVisible = topReturnedGarmentTabs.some((tab) => tab.isTabVisible);

    return (
      <Card
        headerIcon={Icons.PROFILE}
        headerText={t(topReturnsTranslations.title.key, topReturnsTranslations.title.defaultValue)}
        subHeaderText={t(topReturnsTranslations.subTitle.key, topReturnsTranslations.subTitle.defaultValue)}
        toolTipText={t(topReturnsTranslations.toolTip.key, topReturnsTranslations.toolTip.defaultValue)}
        lastUpdatedDate={lastUpdated}
        content={
          <>
            {topReturnedGarmentsLoading && <WidgetCommonState isLoading />}

            {topReturnedGarmentsError && (
              <WidgetCommonState
                isError
                errorText={t(topReturnsTranslations.error.key, topReturnsTranslations.error.defaultValue)}
              />
            )}

            {!topReturnedGarmentsLoading && !topReturnedGarmentsError && (isNodata || !areTabsVisible) && (
              <WidgetCommonState isNoData />
            )}

            {!topReturnedGarmentsLoading &&
              !topReturnedGarmentsError &&
              topReturnedGarments &&
              topReturnedGarments.length > 0 && (
                <StyledView
                  wrap="nowrap"
                  data-testid="top-returning-garment-overview"
                  height="100%"
                  hasBorder={true}
                >
                  <StyledView data-testid="top-returning-garment-table" hasBorder={false}>
                    <DynamicTab
                      tabLabelArray={topReturnedGarmentTabs}
                      handleTabChange={handleTabChange}
                      tabValue={tabIndex}
                      variant="scrollable"
                      tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
                      content={topReturnedGarmentContent}
                    />
                  </StyledView>
                  {/* TODO: Uncomment while integrating ORIS data */}
                  {/* {view === 'view2' && <OrisConversionRate ORISdata={ORISdata} />} */}
                </StyledView>
              )}
          </>
        }
      />
    );
  },
);
