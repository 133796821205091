import { useMsal } from '@azure/msal-react';
import { useMediaQuery } from '@mui/material';
import { useDecision } from '@optimizely/react-sdk';
import jwt_decode from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ActionAreaWrapper, AnimationWrapper, Container, SpeedDialIconsWrapper } from './App.styles';
import { RoutePaths } from './constants';
import { LocalStorageKey } from './constants/localStorageKey';
import { useEventContext } from './global-state/eventContext';
import { Client, RoleMatrix } from './global-state/types';
import {
  useLocalStorage,
  useProfile,
  useRoleMatrix,
  useStoreInformation,
  useStores,
  useTokenInfo,
  useUser,
} from './hooks';
import { useUserPermissions } from './hooks/useUserPermissions';
import {
  AvatarSettings,
  Development,
  Employees,
  Home,
  NotFound,
  ProductCategoryPage,
  TransactionSearch,
} from './pages';
import { KpiTarget } from './pages/kpiTarget/KpiTarget';
import { ProfitAndLoss } from './pages/profitAndLoss/ProfitAndLoss';
import { SelectStore } from './pages/select-store/SelectStore';
import { SoldProductsPage } from './pages/soldProducts/SoldProductsPage';
import { WidgetAccessControlPage } from './pages/widgetAccessControl/WidgetAccessControlPage';
import { Chatbot } from './speedDial/chatbot/Chatbot';
import { FeedbackForm } from './speedDial/feedbackForm/FeedbackForm';
import { HomePageSlider } from './speedDial/homePageSlider/HomePageSlider';
import { Icon, Icons } from './stories/atoms';
import { Animation, Animations, Banner, SpeedDialActionDetails, SpeedDialButton } from './stories/molecules';
import { ActionButton, BannerItemsType } from './stories/molecules/banner';
import { ErrorFallback, Header, SideMenu } from './stories/organisms';
import { muiTheme } from './theme';
import { commonTranslations, errors, homeTranslations } from './translations';
import { headerTranslations, sideMenuTranslations } from './translations/navigation';
import {
  Translation,
  UserPermissions,
  getOptimiselyPermission,
  getUniqueId,
  getUserPermissions,
  isFailureResponse,
  parseExtensionAttributeAssignements,
  parseJWTToken,
} from './utils';

type SpeedDialActionType = 'STORE_DETAILS' | 'CHAT_BOT' | 'FEEDBACK';

type Link = {
  path: RoutePaths;
  element: ReactNode;
  translation: Translation;
  icon: Icons;
  badgeIcon?: JSX.Element;
};

const speedDialActions: SpeedDialActionDetails<SpeedDialActionType>[] = [
  { text: 'STORE_DETAILS', icon: <Icon icon={Icons.INFO} />, tooltipText: 'General Information' },
  { text: 'CHAT_BOT', icon: <Icon icon={Icons.CHATBOT} />, tooltipText: 'Ask a question' },
  { text: 'FEEDBACK', icon: <Icon icon={Icons.FEEDBACK} />, tooltipText: 'Provide Feedback' },
];

export type RouteList = {
  section: Translation;
  links: Link[];
};

const {
  avatarManagement,
  test,
  transaction,
  employees,
  home,
  general,
  dashboards,
  kpiTarget,
  soldProducts,
  customerCentricInsights,
  productCategory,
  profitAndLoss,
  widgetAccessControl,
} = sideMenuTranslations;

const RouteConfigMap = {
  [RoutePaths.HOME]: {
    path: RoutePaths.HOME,
    translation: home,
    element: <Home />,
    icon: Icons.HOME,
  },
  [RoutePaths.WIDGETACCESSCONTROL]: {
    path: RoutePaths.WIDGETACCESSCONTROL,
    translation: widgetAccessControl,
    element: <WidgetAccessControlPage />,
    icon: Icons.SETTINGS,
  },
  [RoutePaths.AVATARMANAGEMENT]: {
    path: RoutePaths.AVATARMANAGEMENT,
    translation: avatarManagement,
    element: <AvatarSettings />,
    icon: Icons.PROFILE,
  },
  [RoutePaths.EMPLOYEES]: {
    path: RoutePaths.EMPLOYEES,
    translation: employees,
    element: <Employees />,
    icon: Icons.HEART,
  },
  [RoutePaths.TRANSACTION]: {
    path: RoutePaths.TRANSACTION,
    translation: transaction,
    element: <TransactionSearch />,
    icon: Icons.TRANSACTION_SEARCH,
  },
  [RoutePaths.KPITARGET]: {
    path: RoutePaths.KPITARGET,
    translation: kpiTarget,
    element: <KpiTarget />,
    icon: Icons.TARGET,
  },
  [RoutePaths.SOLDPRODUCTS]: {
    path: RoutePaths.SOLDPRODUCTS,
    translation: soldProducts,
    element: <SoldProductsPage />,
    icon: Icons.BAG,
  },
  [RoutePaths.PRODUCTCATEGORY]: {
    path: RoutePaths.PRODUCTCATEGORY,
    translation: productCategory,
    element: <ProductCategoryPage />,
    icon: Icons.PRODUCTCATEGORY,
  },
  [RoutePaths.PROFITANDLOSS]: {
    path: RoutePaths.PROFITANDLOSS,
    translation: profitAndLoss,
    element: <ProfitAndLoss />,
    icon: Icons.PROFITANDLOSS,
  },

  [RoutePaths.DEVELOPMENT]: {
    path: RoutePaths.DEVELOPMENT,
    translation: { key: 'development' },
    element: <Development />,
    icon: Icons.WARNING,
  },
};

export const App: FC<PropsWithChildren<unknown>> = observer(() => {
  const [isError, setIsError] = useState(false);
  const [isAssignmentError, setIsAssignmentError] = useState(false);

  const [isFetchingProfile, setIsFetchingProfile] = useState<boolean>(true);
  const [isFetchingStoresDetails, setIsFethcingStoresDetails] = useState(false);
  const [isFetchingStoresData, setIsFetchingStoresData] = useState(false);
  const [isFetchingRoleMatrix, setIsFetchingRoleMatrix] = useState<boolean>(false);
  const [isFetchingPermissions, setIsFetchingPermissions] = useState<boolean>(false);
  const [isFetchingHighlightedWidgets, setIsFetchingHighlightedWidgets] = useState<boolean>(false);

  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [systemBanners, setSystemBanners] = useState<BannerItemsType[]>([]);
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);

  const [isHomePageSliderOpen, setIsHomePageSliderOpen] = useState(false);
  const openHomePageSlider = () => setIsHomePageSliderOpen(true);
  const closeHomePageSlider = () => setIsHomePageSliderOpen(false);

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const openFeedback = () => setIsFeedbackOpen(true);
  const closeFeedback = () => setIsFeedbackOpen(false);

  const handleOnAction = (actionType: SpeedDialActionType) => {
    switch (actionType) {
      case 'CHAT_BOT':
        openChatbot();
        break;
      case 'STORE_DETAILS':
        openHomePageSlider();
        break;
      case 'FEEDBACK':
        openFeedback();
        break;
    }
  };

  const { accessToken: token, idToken: dataToken, fetchTokenInfo } = useTokenInfo();
  const location = useLocation();

  const path = location.pathname.split('/')[1] as RoutePaths;
  const currentRouteConfig = Object.values(RouteConfigMap).find((route) => route.path.includes(path));

  const {
    set,
    get: { currentStoreId, storesDetails, profile, userPermissions, userId },
  } = useUser();
  const { instance } = useMsal();
  const { fetchProfile } = useProfile();
  const { fetchStoresDetails, fetchStoresData, fetchHighlightedKpi, fetchKpiTargets } = useStores();
  const { countryCode, corporateBrandId } = useStoreInformation();
  const { fetchRoleMatrix } = useRoleMatrix();
  const { fetchUserPermissions } = useUserPermissions();
  const { getItem, setItem } = useLocalStorage();
  const { t, i18n } = useTranslation();
  const { registerEvent } = useEventContext();

  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('desktopLarge'));

  const [soldProductsPage] = useDecision('top_selling_garments_page', {}, { overrideUserId: userId || undefined });
  const [productCategoryPage] = useDecision('product_category_page', {}, { overrideUserId: userId || undefined });
  const [porfitAndLossPage] = useDecision('profit_and_loss_page', {}, { overrideUserId: userId || undefined });
  const [widgetAccessControlPage] = useDecision(
    'widget_access_control_page',
    {},
    { overrideUserId: userId || undefined },
  );
  const [regionSettings] = useDecision('region_settings', {}, { overrideUserId: userId || undefined });
  const [alerts] = useDecision('alert_banners', {}, { overrideUserId: userId || undefined });

  const { getIsPageDisabled } = getOptimiselyPermission(countryCode, corporateBrandId, regionSettings);

  const getIsPageDisabledInRegion = (routePath: RoutePaths) => getIsPageDisabled(routePath);

  const getCanAccessPath = (routePath: RoutePaths, userPathPermissions: UserPermissions): boolean => {
    if (getIsPageDisabledInRegion(routePath)) return false;

    const permissionMap: { [key in RoutePaths]: boolean } = {
      [RoutePaths.HOME]: true,
      [RoutePaths.AVATARMANAGEMENT]: userPathPermissions.avatarManagementPage.canRead,
      [RoutePaths.EMPLOYEES]: userPathPermissions.employeesAndRolesPage.canRead,
      [RoutePaths.TRANSACTION]: userPathPermissions.transactionSearchPage.canRead,
      [RoutePaths.KPITARGET]: userPathPermissions.kpiTargetsPage.canRead,
      [RoutePaths.DEVELOPMENT]: process.env.NODE_ENV === 'development',
      [RoutePaths.SOLDPRODUCTS]: userPathPermissions.soldProductsPage.canRead && soldProductsPage.enabled,
      [RoutePaths.PRODUCTCATEGORY]:
        userPathPermissions.productIndexSubindex.canRead && productCategoryPage.enabled,
      [RoutePaths.PROFITANDLOSS]: process.env.NODE_ENV === 'development' || porfitAndLossPage.enabled,
      [RoutePaths.WIDGETACCESSCONTROL]:
        userPathPermissions.blacklistPermissionsForArea.canRead && widgetAccessControlPage.enabled,
    };

    return permissionMap[routePath] ?? false;
  };

  const isUserPermissionsLoaded = userPermissions && Object.keys(userPermissions).length > 0;

  useEffect(() => {
    if (!i18n.resolvedLanguage) {
      setSystemBanners(() => [
        {
          id: getUniqueId(),
          label: t(
            homeTranslations.banners.translationError.key,
            homeTranslations.banners.translationError.defaultValue,
          ),
          type: 'warning',
          actionButton: ActionButton.Close,
          hasNoMargin: true,
        },
      ]);
      return;
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (alerts.enabled) {
      setSystemBanners(() => [
        {
          id: getUniqueId(),
          label: t(
            homeTranslations.banners.serviceAlerts.key,
            homeTranslations.banners.serviceAlerts.defaultValue,
          ),
          type: 'warning',
          actionButton: ActionButton.Close,
          hasNoMargin: true,
        },
      ]);
    }
  }, [alerts.enabled]);

  const triggerFetchProfile = async () => {
    if (!token) return;

    const { upn } = parseJWTToken<{ upn: string }>(token);
    const response = await fetchProfile(upn);

    if (isFailureResponse(response)) {
      setIsError(true);
      return null;
    }

    set({ profile: response.data });
    setIsFetchingProfile(false);
  };

  useEffect(() => {
    triggerFetchProfile();
  }, [token]);

  useEffect(() => {
    if (!dataToken) return;

    const {
      given_name: firstName,
      family_name: lastName,
      upn,
      ExtensionAttribute7,
      roles,
    } = jwt_decode(dataToken) as {
      given_name: string;
      family_name: string;
      upn: string;
      ExtensionAttribute7: string;
      roles?: string[];
    };

    const assignments = parseExtensionAttributeAssignements(ExtensionAttribute7);

    const isAppRole = roles?.includes('Developer'); // For now checking developer role as they dont have extension attribute
    if (roles) {
      const defaultIndex = roles.indexOf('Default');
      if (defaultIndex > -1) {
        roles?.splice(defaultIndex, 1);
      }
    }

    set({
      profile: {
        ...profile,
        firstName,
        lastName,
        upn,
        assignments: isAppRole ? profile?.assignments : assignments,
        appRoles: roles,
      },
    });
  }, [dataToken]);

  useEffect(() => {
    triggerFetchStoresDetails();
  }, [profile?.assignments, profile?.appRoles]);

  // Trigger register event when storeId or upn changes
  useEffect(() => {
    if (!profile?.upn || !currentStoreId) return;

    registerEvent(profile?.upn, currentStoreId);
  }, [profile?.upn, currentStoreId]);

  useEffect(() => {
    if (!token || !currentStoreId) return;

    const getRoleMatrix = async () => {
      const clients: Client[] = ['box'];
      setIsFetchingRoleMatrix(true);
      const roleMatrixes = await Promise.all(
        clients.map(async (client) => {
          const marketCode = currentStoreId.slice(0, 2);
          const response = await fetchRoleMatrix(client, marketCode);

          if (isFailureResponse(response)) {
            setIsError(true);
            return null;
          }

          const { data } = response;
          const key = data.modelKey?.split('-')[0];

          return { [key]: data };
        }),
      );
      setIsFetchingRoleMatrix(false);
      if (roleMatrixes.some((matrix) => !matrix)) return;

      const updatedRoleMatrix = roleMatrixes.reduce((previousMatrix, currentMatrix) => ({
        ...previousMatrix,
        ...currentMatrix,
      })) as RoleMatrix;

      set({ roleMatrix: updatedRoleMatrix });
    };

    getRoleMatrix();

    const triggerFetchUserPermissions = async () => {
      setIsFetchingPermissions(true);
      const upn = profile.upn;
      const response = await fetchUserPermissions(upn, currentStoreId);

      setIsFetchingPermissions(false);
      if (isFailureResponse(response)) {
        setIsError(true);
        return null;
      }

      const apiUserPermissions = getUserPermissions(response.data);

      set({ userPermissions: apiUserPermissions });
    };

    triggerFetchUserPermissions();
  }, [token, currentStoreId, profile?.assignments, profile?.appRoles]);

  useEffect(() => {
    fetchTokenInfo();
  }, []);

  useEffect(() => {
    if (!currentStoreId) return;

    triggerFetchStoresData();
  }, [currentStoreId]);

  useEffect(() => {
    if (!currentStoreId) return;

    triggerFetchHighlightedKpi();
    triggerFetchKpiTargets();
  }, [currentStoreId]);

  const triggerFetchStoresDetails = async () => {
    if ((!profile.assignments || !profile.assignments.length) && !isFetchingProfile) {
      if (!profile?.appRoles) {
        setIsAssignmentError(true);
      } else return;
    }

    if (storesDetails && storesDetails.length > 0) return;

    setIsFethcingStoresDetails(true);

    const response = await fetchStoresDetails();

    setIsFethcingStoresDetails(false);

    if (isFailureResponse(response)) {
      setIsError(true);
      return null;
    }

    const { data: newStoresDetails } = response;

    if (newStoresDetails.length === 0) return;

    const storeIdParam = location.pathname.replace('/home/', '');

    const hasAccess =
      storeIdParam && profile.assignments?.some((assignment) => assignment.locationCode === storeIdParam);

    if (hasAccess) {
      setItem(LocalStorageKey.SelectedStore, storeIdParam);
    }

    let storeId = newStoresDetails.find(
      ({ storeId: id }) => id === getItem(LocalStorageKey.SelectedStore),
    )?.storeId;

    if (!storeId) {
      storeId = newStoresDetails.length > 1 ? '' : (newStoresDetails[0]?.storeId as string);
    }

    set({
      storesDetails: newStoresDetails,
      currentStoreId: storeId,
    });

    setIsFethcingStoresDetails(false);
  };

  const triggerFetchStoresData = async () => {
    setIsFetchingStoresData(true);
    const response = await fetchStoresData(currentStoreId);
    setIsFetchingStoresData(false);

    if (isFailureResponse(response)) {
      setIsError(true);
      return null;
    }

    set({
      stores: [response.data],
    });
  };

  const triggerFetchHighlightedKpi = async () => {
    setIsFetchingHighlightedWidgets(true);
    const response = await fetchHighlightedKpi();
    setIsFetchingHighlightedWidgets(false);

    if (isFailureResponse(response)) {
      return null;
    }

    set({
      highlightedKpiTargets: response.data,
    });
  };

  const triggerFetchKpiTargets = async () => {
    const response = await fetchKpiTargets();

    if (isFailureResponse(response)) {
      return null;
    }

    set({ kpiTargetsProps: response.data });
  };

  const filteredMenuList = () => {
    const routes: RouteList[] = [
      {
        section: dashboards,
        links: [RouteConfigMap[RoutePaths.HOME], RouteConfigMap[RoutePaths.WIDGETACCESSCONTROL]],
      },
      {
        section: general,
        links: [
          RouteConfigMap[RoutePaths.AVATARMANAGEMENT],
          RouteConfigMap[RoutePaths.EMPLOYEES],
          RouteConfigMap[RoutePaths.TRANSACTION],
        ],
      },
      {
        section: customerCentricInsights,
        links: [
          RouteConfigMap[RoutePaths.KPITARGET],
          RouteConfigMap[RoutePaths.SOLDPRODUCTS],
          RouteConfigMap[RoutePaths.PRODUCTCATEGORY],
          RouteConfigMap[RoutePaths.PROFITANDLOSS],
        ],
      },
      {
        section: test,
        links: [...(process.env.NODE_ENV === 'development' ? [RouteConfigMap[RoutePaths.DEVELOPMENT]] : [])],
      },
    ];
    const filteredList = routes.map((section) => {
      const formatLinks = section.links.filter((link) => getCanAccessPath(link.path, userPermissions));

      return {
        ...section,
        links: formatLinks,
      };
    });
    return filteredList;
  };

  if (!currentStoreId && storesDetails.length > 1) {
    return <SelectStore />;
  }

  const ErrorAnimation = () => {
    return (
      <AnimationWrapper>
        <ErrorFallback />
      </AnimationWrapper>
    );
  };

  const isFetching =
    isFetchingProfile ||
    isFetchingStoresDetails ||
    isFetchingStoresData ||
    isFetchingRoleMatrix ||
    isFetchingPermissions ||
    isFetchingHighlightedWidgets;

  return (
    <>
      {isError && <ErrorAnimation />}
      {isAssignmentError && (
        <Animation
          title={t(errors.storeNotAssigned.title.key, errors.storeNotAssigned.title.defaultValue)}
          subtitle={t(errors.storeNotAssigned.subtitle.key, errors.storeNotAssigned.subtitle.defaultValue)}
          animation={Animations.SAD}
          button={{
            label: t(headerTranslations.signout.key, headerTranslations.signout.defaultValue),
            onClick: () => instance.logoutRedirect(),
          }}
        />
      )}
      {isFetching && (
        <AnimationWrapper>
          <Animation
            title={t(commonTranslations.loading.key, commonTranslations.loading.defaultValue)}
            animation={Animations.LOADING}
          />
        </AnimationWrapper>
      )}
      {storesDetails.length === 0 && (
        <AnimationWrapper>
          <Animation
            title={t(errors.noStoresFound.title.key, errors.noStoresFound.title.defaultValue)}
            subtitle={t(errors.noStoresFound.subtitle.key, errors.noStoresFound.subtitle.defaultValue)}
            animation={Animations.CONFUSED}
            button={{
              label: t(headerTranslations.signout.key, headerTranslations.signout.defaultValue),
              onClick: () => instance.logoutRedirect(),
            }}
          />
        </AnimationWrapper>
      )}
      {!isFetching && !isError && !isAssignmentError && isUserPermissionsLoaded && (
        <ErrorBoundary FallbackComponent={ErrorAnimation}>
          <Banner
            isSticky
            items={systemBanners}
            onChange={(items) => {
              setSystemBanners(items);
            }}
          />
          <Container isFullScreen={isDesktop}>
            <Banner
              items={bannerItems}
              onChange={(items) => {
                setBannerItems(items);
                set({ hasCompletedNewOnboardingItem: false });
              }}
            />
            <Header
              currentPathNameTranslation={currentRouteConfig ? currentRouteConfig?.translation : home}
              onOpenNav={() => setIsSliderOpen(true)}
              onRoleChangeSuccess={triggerFetchProfile}
            />
            <SideMenu
              header={{
                profile,
                storeId: currentStoreId,
              }}
              list={filteredMenuList()}
              isOpen={isSliderOpen}
              onClose={() => setIsSliderOpen(false)}
            />

            <Routes>
              {filteredMenuList().map(({ links }) =>
                links.map((link) => <Route key={link.path} path={link.path} element={link.element} />),
              )}
              <Route index element={<Navigate to="/home" />} />
              <Route path={`/home/${getItem(LocalStorageKey.SelectedStore)}`} element={<Navigate to="/home" />} />

              <Route path="*" element={<NotFound />} />
            </Routes>

            <ActionAreaWrapper>
              <SpeedDialButton actions={speedDialActions} onAction={handleOnAction} />
            </ActionAreaWrapper>

            <SpeedDialIconsWrapper>
              <Chatbot isOpen={isChatbotOpen} closeChatbot={closeChatbot} />
            </SpeedDialIconsWrapper>

            <HomePageSlider isOpen={isHomePageSliderOpen} onClose={closeHomePageSlider} />

            <SpeedDialIconsWrapper>
              <FeedbackForm isOpen={isFeedbackOpen} onClose={closeFeedback} />
            </SpeedDialIconsWrapper>
          </Container>
        </ErrorBoundary>
      )}
    </>
  );
});
