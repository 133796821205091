import { Property } from 'csstype';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { homeTranslations } from '../../../translations';
import { Container, Icon, Icons, Tooltip } from '../../atoms';
import { Typography } from '../../atoms/typography/Typography';
import {
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledHeaderSide,
  StyledHeaderText,
  StyledIcon,
} from './Card.styles';

export type GridCardItemProps = {
  headerIcon?: Icons;
  headerText?: string;
  subHeaderText?: string;
  headerRightElement?: JSX.Element;
  content: JSX.Element;
  lastUpdatedDate?: Date | null;
  footer?: JSX.Element;
  overflow?: Property.Overflow;
  toolTipText?: string;
};

export const Card: FC<GridCardItemProps> = ({
  headerText,
  subHeaderText,
  headerIcon,
  headerRightElement,
  content,
  lastUpdatedDate,
  footer,
  overflow,
  toolTipText,
}) => {
  const { t } = useTranslation();
  const formattedDate = lastUpdatedDate ? format(new Date(lastUpdatedDate), "yyyy-MM-dd 'at' HH:mm") : null;

  return (
    <Container data-testid="card-wrapper" height="100%" wrap="nowrap" width="100%">
      <StyledHeader>
        <StyledHeaderSide>
          {headerIcon && (
            <Tooltip position="bottom-start" text={<Typography color="white">{toolTipText}</Typography>}>
              <StyledIcon data-testid="header-icon" icon={headerIcon} size="small" margin={[0, 4, 0, 0]} />
            </Tooltip>
          )}
          <StyledHeaderText>
            <Container direction="horizontal" wrap="nowrap" margin={[0, 0, 1, 0]}>
              <Typography data-testid="title" type="body3">
                {headerText}
              </Typography>
              {formattedDate && (
                <Tooltip
                  position="bottom-start"
                  text={
                    <Typography color="white">
                      {t(
                        homeTranslations.common.lastUpdated.key,
                        homeTranslations.common.lastUpdated.defaultValue,
                      )}
                      :
                      <br /> {formattedDate}
                    </Typography>
                  }
                >
                  <Icon data-testid="header-icon" icon={Icons.CLOCKFILLED} size="small" margin={[0, 0, 0, 1]} />
                </Tooltip>
              )}
            </Container>

            <Typography data-testid="sub-title" type="legal" color="secondaryTextGray">
              {subHeaderText}
            </Typography>
          </StyledHeaderText>
        </StyledHeaderSide>
        <div data-testid="header-right-element">{headerRightElement}</div>
      </StyledHeader>
      <StyledContent data-testid="content" overflow={overflow}>
        {content}
      </StyledContent>
      {footer && (
        <StyledFooter padding={[6]} direction="horizontal" position="right" data-testid="footer">
          {footer}
        </StyledFooter>
      )}
    </Container>
  );
};
