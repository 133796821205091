import { add } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { TransactionFilterType, VendorMapping, VendorType } from '../../constants';
import { Transaction, TransactionFilter } from '../../global-state/types';
import {
  AppCustomersResponse,
  AveragePiecesPerReceiptDataResponse,
  AveragePiecesPerReceiptPerformanceItem,
  BudgetPlannedData,
  ConversionRateResponse,
  CustomerFeedbackResponse,
  EffectiveHoursResponse,
  HourlyTurnoverResponse,
  NewMembersResponse,
  QueueLengthItem,
  QueueLengthPerformanceItem,
  QueueLengthResponse,
  ReceiptSalesTimeResponse,
  RegisteredReceiptsResponse,
  SalesNetPerReceiptPerformanceItem,
  SalesNetPerReceiptResponse,
  ShareOfReceiptResponse,
  ShareOfReceiptsItem,
  TenderResponse,
  TillType,
  TransactionCount,
  TransactionResponse,
} from '../../hooks';
import { mockStores, mockStoresDetails } from './store.mock';

export const getMockReceiptData = () => {
  return [
    '                      H&M                       ',
    '           H&M Hennes & Mauritz GmbH            ',
    '                 www.hm.com/AT                  ',
    '                     AT0168                     ',
    '      Riverside Breitenfurterstr. 372-380       ',
    '                  1230 Vienna                   ',
    '          Kundenservice 0800 66 55 900          ',
    '                  ATU19401608                   ',
    'Kassier: 375985      Store: AT0168     Bon: 3535',
    'Datum:23.02.21     Kassa:03        Zeit:13:55:07',
    '------------------------------------------------',
    'Einkaufstasche                  1St.            ',
    '999006                                      0,20',
    'Gürtel                          1St.            ',
    '0878223     S           Orange              1,99',
    '  Urspr. Preis                      9,99',
    '  Rabatt                           -8,00',
    'Fancy Jersey                    1St.            ',
    '0916222     152         Schwarz            22,99',
    'Other Sales                     1St.            ',
    '0452818     43/45       Schwarz             7,99',
    'Denim Kinder                    1St.            ',
    '0860172     152         Blau               24,99',
    'Fancy Jersey                    1St.            ',
    '0869158     152         Schwarz            17,99',
    'Denim Kinder                    1St.            ',
    '0860172     152         Blau               24,99',
    'Bluse                           1St.            ',
    '0925204     42          Blue               24,99',
    'Denim Damen                     1St.            ',
    '0399061     40          Blau               34,99',
    '------------------------',
    'PAKET                                           ',
    'Basic Jersey                    1St.            ',
    '0628327     152         Schwarz             7,14',
    '  Urspr. Preis                      9,99',
    'Basic Jersey                    1St.            ',
    '0628327     152         Schwarz             7,14',
    '  Urspr. Preis                      9,99',
    'Basic Jersey                    1St.            ',
    '0627554     146         Schwarz             5,70',
    '  Urspr. Preis                      7,99',
    '  Manueller Rabatt                 -7,99',
    '  Paketpreis                               19,98',
    '------------------------',
    '------------------------------------------------',
    'Total          181,10 €',
    'ANZAHL DER ARTIKEL 12                           ',
    'MWST%       MWST        Netto       Gesamt      ',
    '20,00       30,18       150,92      181,10      ',
    'Gesamt      30,18       150,92      181,10      ',
    '',
    'Rabatt Gesamt:                             15,99',
    'Erhalten    Maestro                       181,10',
    '',
    'KOPIE KARTENINHABER                             ',
    '',
    'Datum                         23/02/2021        ',
    'Uhrzeit                         13:56:21        ',
    '',
    'Karte                ***************8888        ',
    'PAN seq.                              01        ',
    'Bev. Name                        MAESTRO        ',
    'Kartentyp                        maestro        ',
    'Zahlungsmethode                  maestro        ',
    'Payment variant                  maestro        ',
    'Eingabemodus           Kontaktloser Chip        ',
    'CVM res.                     PIN GEPRÜFT        ',
    '',
    'AID                       A0000000043060        ',
    'MID                      526567002119688        ',
    'TID                      VX820-902561592        ',
    'PTID                            72334425        ',
    '',
    'Auth. code                        688206        ',
    'Tender               tVUH001298552181341        ',
    'Referenz         AT016800320210223135635        ',
    '',
    'Typ               WAREN_DIENSTLEISTUNGEN        ',
    'Summe                           € 181.10        ',
    '',
    'Genehmigt                                       ',
    '',
    'Für Ihre Unterlagen                             ',
    '',
    '',
    'Letzter Umtauschtag 25.03.21                    ',
    'Kassenidentfikationsnummer: AT016803            ',
    'Belegnummer: 90611                              ',
    '',
    '          Verlängerte Umtauschfristen           ',
    '     Alle Einkäufe, die zwischen 7.12.2020      ',
    '      und 24.12.2020 in unseren Geschäften      ',
    '    in Österreich getätigt wurden, kannst du    ',
    '    bis einschließlich 9.3.2021 zurückgeben.    ',
    '  Gilt nur für Einkäufe in den den H&M Stores   ',
    '   in Österreich im oben genannten Zeitraum.    ',
    '       Für alle Einkäufe ab dem 8.2.2021        ',
    ' gilt die reguläre Umtauschfrist von 30 Tagen.  ',
    '',
    '        Vielen Dank für deinen Einkauf!         ',
    '         Umtausch nur gegen Vorlage des         ',
    '         originalen Kassabons möglich.          ',
    '',
    '     Bitte beachte, dass der Umtausch sowie     ',
    '      die Rückgabe von Gesichtsmasken, aus      ',
    '    hygienischen Gründen, nicht möglich ist.    ',
    '',
    '',
    '[barcode 20210223016800303535 INTERLEAVED_2_5 barcode]',
    '',
  ];
};

const mockPaymentType = ['Card', 'Cash', 'CreditDebit', 'HouseAccount'];
const mockOperatorIds = ['A110001', 'A110003', 'A110004'];
const mockReceiptType = ['OnlineReturn', 'Sale', 'Return', 'ReturnFaultyOnline', 'ReturnFaulty'];
const mockPaymentCardType = ['VISA', 'MasterCard', 'Alipay'];

const getMockPaymentType = () => {
  return mockPaymentType[Math.floor(Math.random() * mockPaymentType.length)];
};

const getMockPaymentCardType = () => {
  return mockPaymentCardType[Math.floor(Math.random() * mockPaymentCardType.length)];
};

const getMockReceiptType = () => {
  return mockReceiptType[Math.floor(Math.random() * mockReceiptType.length)];
};

const getMockOperatorId = () => {
  return mockOperatorIds[Math.floor(Math.random() * mockOperatorIds.length)];
};

export const getMockTransactionData = (pageSize?: number): TransactionResponse => {
  const data: Transaction[] = [];
  const updatedPageSize = pageSize || 100;

  for (let i = 0; i < updatedPageSize; i++) {
    data.push({
      tillId: Math.floor(Math.random() * 10).toString(),
      receiptNumber: Math.floor(Math.random() * 1000).toString(),
      countryCode: 'SE',
      storeId: mockStores[0].storeId,
      tillType: 'Manual',
      sequenceNumber: Math.floor(Math.random() * 1000).toString(),
      paymentType: [getMockPaymentType()],
      paymentCardtype: [getMockPaymentCardType()],
      receiptType: getMockReceiptType(),
      businessDayDate: add(new Date(), { hours: Math.floor(Math.random() * 20) }).toISOString(),
      receiptSubType: getMockReceiptType(),
      amount: Math.floor(Math.random() * 1000).toString(),
      Transaction: {
        beginDateTime: new Date().toISOString(),
        endDateTime: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
        OperatorID: getMockOperatorId(),
        currencyCode: 'SEK',
      },
    });
  }
  return {
    data,
    paging: {
      countFromOldPartition: 0,
      nextBusinessDayDate: new Date().toISOString(),
      nextTransactionSubType: 'Sales',
    },
  };
};

export const getMockTransactionCountData = (
  type = TransactionFilterType.OperatorId,
  totalCount = 150,
): TransactionCount => {
  const mapping = {
    [TransactionFilterType.OperatorId]: mockOperatorIds,
    [TransactionFilterType.PaymentType]: mockPaymentType,
    [TransactionFilterType.ReceiptSubType]: mockReceiptType,
  };
  const count = {
    operatorId: totalCount,
    paymentType: totalCount,
    receiptSubType: totalCount,
  };
  const getCount = (isLastItem: boolean) => {
    if (isLastItem) return count[type];

    const itemCount = Math.floor(Math.random() * count[type]) || 0;
    count[type] = count[type] - itemCount;
    return itemCount;
  };

  return {
    totalCount: totalCount.toString(),
    filterData: {
      [type]: mapping[type].map((item, index) => ({
        item,
        count: getCount(index === mapping[type].length - 1).toString(),
      })),
    } as TransactionFilter,
  };
};

export const getReceiptWidgetData = (): RegisteredReceiptsResponse => {
  const count = {
    receiptSubType: 150,
  };
  const getCount = (isLastItem: boolean) => {
    if (isLastItem) return count[TransactionFilterType.ReceiptSubType];

    const itemCount = Math.floor(Math.random() * count[TransactionFilterType.ReceiptSubType]) || 0;
    count[TransactionFilterType.ReceiptSubType] = count[TransactionFilterType.ReceiptSubType] - itemCount;
    return itemCount;
  };

  const registeredReceipts = mockReceiptType.map((item, index) => ({
    item,
    count: getCount(index === mockReceiptType.length - 1),
  }));

  return {
    registeredReceipts,
    lastUpdated: new Date(),
  };
};

export const getTenderWidgetData = (numberOfRecords = 10): TenderResponse => {
  const tenders = (Object.keys(VendorMapping) as VendorType[])
    .sort(() => Math.random() - 0.5)
    .filter((_, index) => index < numberOfRecords)
    .map((type, index) => ({
      id: index,
      type,
      count: Math.floor(Math.random() * 1000),
      amount: Math.floor(Math.random() * 10000),
      currency: 'SEK',
    }));

  return {
    tenders,
    lastUpdated: new Date(),
  };
};

export const getHourlyTournoverData = (useTimezone: boolean = true): HourlyTurnoverResponse => {
  const data = [];
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  for (let i = 0; i < 24; i++) {
    data.push({
      timeUnit: i.toString(),
      amount: useTimezone && i > hour ? 0 : Math.floor(Math.random() * 1000000),
    });
  }

  return {
    netSales: data,
    lastWeek: 200,
    lastYear: 100,
    today: 10,
    lastUpdated: new Date(),
  };
};

export const getBudgetPlannedData = (): BudgetPlannedData => {
  return {
    budget: 25000,
    planned: 8000,
  };
};

export const getConversionRateData = (useTimezone: boolean = true): ConversionRateResponse => {
  const data = [];
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  for (let i = 0; i < 24; i++) {
    data.push({
      timeUnit: i.toString(),
      payingCustomers: useTimezone && i > hour ? 0 : Math.floor(Math.random() * 100),
      nonPayingCustomers: useTimezone && i > hour ? 0 : Math.floor(Math.random() * 50),
      numberOfTills: Math.floor(Math.random() * 5),
    });
  }

  return {
    conversionRate: data,
    lastWeek: 200,
    lastYear: 100,
    today: 10,
    lastUpdated: new Date(),
  };
};

export const getNewMembersData = (useTimezone: boolean = true): NewMembersResponse => {
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  const newMembers = Array.from({ length: 24 }, (_, i) => ({
    timeUnit: i.toString(),
    newMembersCount: useTimezone && i > hour ? 0 : Math.floor(Math.random() * 100),
    totalTransactions: Math.floor(Math.random() * 100),
    unrecruited: Math.floor(Math.random() * 100),
  }));

  return {
    newMembers,
    lastUpdated: new Date(),
  };
};

export const getAppCustomersData = (useTimezone: boolean = true): AppCustomersResponse => {
  const data = [];
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  for (let i = 0; i < 24; i++) {
    data.push({
      timeUnit: i.toString(),
      newCustomerCount: useTimezone && i > hour ? 0 : Math.floor(Math.random() * 100),
    });
  }

  return {
    newAppCustomers: data,
    appCustomers: '80',
  };
};

export const getEffectiveHours = (): EffectiveHoursResponse => {
  return {
    today: 45564,
    lastWeek: 11443,
    lastYear: 9800,
    lastUpdated: new Date(),
  };
};

export const getMockShareOfReceiptsData = (useTimezone: boolean = true): ShareOfReceiptResponse => {
  const mockData = [];
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  for (let i = 0; i < 24; i++) {
    const totalTransactions = useTimezone && i > hour ? 0 : Math.floor(Math.random() * 100) + 1;
    const memberTransactions = totalTransactions * Math.random();

    const entry: ShareOfReceiptsItem = {
      timeUnit: i.toString(),
      totalTransactions,
      memberTransactions,
    };

    mockData.push(entry);
  }

  return {
    shareOfReceipts: mockData,
    lastUpdated: new Date(),
  };
};

export const getSalesNetPerReceiptData = (useTimezone: boolean = true): SalesNetPerReceiptResponse => {
  const mockEntries: SalesNetPerReceiptPerformanceItem[] = [];
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  for (let i = 0; i < 24; i++) {
    mockEntries.push({
      timeUnit: i.toString(),
      amount: useTimezone && i > hour ? 0 : Math.floor(Math.random() * (1000 - 100) + 100) / 100,
    });
  }

  return {
    today: Math.floor(Math.random() * 1000000),
    lastWeek: Math.floor(Math.random() * 100),
    lastYear: Math.floor(Math.random() * 100),
    netSalePerReceipt: mockEntries,
    lastUpdated: new Date(),
  };
};

export const getAveragePiecesPerReceiptData = (
  useTimezone: boolean = true,
): AveragePiecesPerReceiptDataResponse => {
  const mockEntries: AveragePiecesPerReceiptPerformanceItem[] = [];
  const date = new Date();
  const zonedDate = utcToZonedTime(date, mockStoresDetails[0].timezone);
  const hour = zonedDate.getHours();

  for (let i = 0; i < 24; i++) {
    mockEntries.push({
      timeUnit: i.toString(),
      amount: useTimezone && i > hour ? 0 : Math.floor(Math.random() * (1000 - 100) + 100) / 100,
    });
  }

  return {
    today: Math.floor(Math.random() * 100),
    lastWeek: Math.floor(Math.random() * 100),
    lastYear: Math.floor(Math.random() * 100),
    piecesPerReceipt: mockEntries,
    lastUpdated: new Date(),
  };
};

export const getQueueLengthData = (): QueueLengthResponse => {
  const mockEntries: QueueLengthItem[] = [];
  const tillTypes: TillType[] = ['DEFAULT', 'SELFCHECKOUT'];

  for (let i = 0; i < 50; i++) {
    const tillTypeIndex = Math.floor(Math.random() * (tillTypes.length - 1 + 1) + 0);

    mockEntries.push({
      queueCount: Math.floor(Math.random() * 100),
      tillId: `ZZ${i + 1}`,
      tillType: tillTypes[tillTypeIndex],
    });
  }

  return {
    queueLength: mockEntries,
    checkoutExperience: {
      happyCheckoutExperienceCount: 0,
      totalCheckoutExperienceCount: 0,
    },
    totalTransactions: 100,
    lastUpdated: new Date(),
  };
};

export const getQueueLengthForecastData = (): QueueLengthPerformanceItem[] => {
  const mockEntries: QueueLengthPerformanceItem[] = [];

  for (let i = 0; i < 24; i++) {
    mockEntries.push({
      timeUnit: i.toString(),
      queueCount: Math.floor(Math.random() * 100),
      totalTransactions: Math.floor(Math.random() * 10),
    });
  }

  return mockEntries;
};

export const getCustomerFeedbackData = (): CustomerFeedbackResponse => {
  const phrases = [
    'Great experience',
    'Good experience',
    'Good experience. Nice navigation',
    'Average experience',
    'Average experience. Could be better',
    'Bad experience',
    'Worst experience',
    'Excellent service',
    'Not satisfied',
    'Could be improved',
    'Fantastic!',
    'Very bad',
    'Loved it',
    'Hated it',
    'Just okay',
    'The service was exceptional and exceeded all my expectations',
    'I had a wonderful time and the staff was very friendly',
    'The product quality was top-notch and delivery was prompt',
    'Customer support was very helpful and resolved my issue quickly',
    'The website is user-friendly and easy to navigate',
    'I am extremely satisfied with my purchase and the overall experience',
    'The experience was mediocre and could use some improvements',
    'I faced several issues and the support team was unresponsive',
    'The product did not meet my expectations and was of poor quality',
    'I had a terrible experience and would not recommend this service',
  ];

  const getRandomRating = () => Math.floor(Math.random() * 5) + 1;

  const generateRandomReviews = (numReviews: number) => {
    const reviews = [];
    for (let i = 0; i < numReviews; i++) {
      const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];
      const randomRating = getRandomRating();
      reviews.push({
        text: randomPhrase,
        rating: randomRating,
      });
    }
    return reviews;
  };

  const feedback = generateRandomReviews(10);

  return {
    feedback,
    lastUpdated: new Date(),
  };
};

export const getReceiptSalesAnalysisData = (): ReceiptSalesTimeResponse => {
  return {
    takt1: {
      today: Math.floor(Math.random() * 100),
      lastWeek: Math.floor(Math.random() * 100),
      lastYear: Math.floor(Math.random() * 100),
    },
    spt: {
      today: Math.floor(Math.random() * 100),
      lastWeek: Math.floor(Math.random() * 100),
      lastYear: Math.floor(Math.random() * 100),
    },
    lastUpdated: new Date(),
  };
};
