import { useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../assets/articlePlaceholder.png';
import { SortDirection } from '../../../global-state/types';
import { SoldProductItem } from '../../../hooks';
import { Typography } from '../../../stories/atoms';
import { Animation, Animations, Row, Table } from '../../../stories/molecules';
import { muiTheme } from '../../../theme';
import { soldProductsTranslations, transactionSearchTranslations } from '../../../translations';
import { getArticleAssetUrl } from '../../../utils/soldProducts';
import { BadgeWrapper, StyledAnimationWrapper, StyledItem } from './ProductTable.styles';

const { rowsPerpageLabel } = transactionSearchTranslations;

type ProductTableProps = {
  soldProductItems: SoldProductItem[];
  isFetchingGarments: boolean;
  height: string;
  selectedRowIndex: number;
  onRowClick: (selectedRowId: number) => void;
};

export const ProductTable: FC<ProductTableProps> = ({
  soldProductItems,
  isFetchingGarments,
  height,
  selectedRowIndex,
  onRowClick,
}) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('desktop'));

  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const rowsPerPageOptions = [100, 250, 500];
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPageOptions[0]);

  const tableHeaders = [
    {
      id: 'photo',
      name: t(soldProductsTranslations.image.key, soldProductsTranslations.image.defaultValue),
      width: '10%',
    },
    {
      id: 'season',
      name: t(soldProductsTranslations.season.key, soldProductsTranslations.season.defaultValue),
      width: '10%',
    },
    {
      id: 'departmentId',
      name: `${t(
        soldProductsTranslations.departmentId.key,
        soldProductsTranslations.departmentId.defaultValue,
      )}/${t(soldProductsTranslations.name.key, soldProductsTranslations.name.defaultValue)}`,
      width: '12.5%',
    },
    {
      id: 'productId',
      name: `${t(soldProductsTranslations.productId.key, soldProductsTranslations.productId.defaultValue)}/${t(
        soldProductsTranslations.name.key,
        soldProductsTranslations.name.defaultValue,
      )}`,
      width: '15%',
    },
    // TODO: add when BE spec is updated
    // {
    //   id: 'piecesRemaining',
    //   name: t(soldProductsTranslations.piecesRemaining.key, soldProductsTranslations.piecesRemaining.defaultValue),
    //   width: '12.5%',
    // },
    {
      id: 'price',
      name: t(soldProductsTranslations.price.key, soldProductsTranslations.price.defaultValue),
      width: '10%',
    },
    {
      id: 'salesNet',
      name: t(soldProductsTranslations.salesNet.key, soldProductsTranslations.salesNet.defaultValue),
      width: '10%',
      // isSortable: true,
    },
    {
      id: 'soldPieces',
      name: t(soldProductsTranslations.piecesSold.key, soldProductsTranslations.piecesSold.defaultValue),
      width: '10%',
      // isSortable: true,
    },
    // TODO: add when BE spec is updated
    // { id: 'speed', name: 'Speed', width: '10%', isSortable: true },
  ];

  const onRowsPerPageChange = (rowsPerPage: number) => {
    setCurrentRowsPerPage(rowsPerPage);
    setCurrentPageNumber(0);
  };

  // TODO: Update the data structure to match the actual data
  const getRows = (items: SoldProductItem[]): Row[] => {
    return items.map((row, index) => {
      const {
        productName,
        productId,
        soldPieces,
        price,
        departmentId,
        departmentName,
        salesNet,
        season,
        mostSoldArticle,
      } = row;

      const imageUrl = getArticleAssetUrl({
        articleId: `${productId}${mostSoldArticle}`,
        assetType: 'descriptiveStillLife',
        rendition: 'extraSmall',
      });

      return {
        id: index,
        photo: (
          <StyledItem
            data-testid="article-image"
            src={imageUrl}
            alt=""
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = articlePlaceholder;
            }}
            width="100"
            height="100"
          />
        ),
        departmentId: (
          <>
            <Typography type="body3">{departmentId}</Typography>
            <Typography type="legal" color="textGray" padding={[1, 0, 0, 0]}>
              {departmentName}
            </Typography>
          </>
        ),
        productId: (
          <>
            <BadgeWrapper isMobileView={isMobileView}>
              <Typography type="body3" margin={[0, 2, 0, 0]}>
                {productId}
              </Typography>
              {/* TODO: add when BE spec is updated */}
              {/* <Badge backgroundColor={stockAvailable ? 'successLight' : 'errorLight'}>
              <Typography type="body3" color={stockAvailable ? 'validationGreen' : 'red'}>
                {stockAvailable
                  ? t(soldProductsTranslations.inStock.key, soldProductsTranslations.inStock.defaultValue)
                  : t(soldProductsTranslations.outOfStock.key, soldProductsTranslations.outOfStock.defaultValue)}
              </Typography>
            </Badge> */}
            </BadgeWrapper>
            <Typography type="legal" color="textGray" padding={[1, 0, 0, 0]}>
              {productName}
            </Typography>
          </>
        ),
        season: season,
        // TODO: add when BE spec is updated
        // piecesRemaining: `${remainingPieces} pcs`,
        price: `${price}`,
        salesNet: `${salesNet.toFixed(2)}`,
        soldPieces: `${soldPieces} ${t(
          soldProductsTranslations.pieces.key,
          soldProductsTranslations.pieces.defaultValue,
        )}`,
        // TODO: add when BE spec is updated
        // speed: `${speed}%`,
      };
    });
  };

  const sortObjects = (property: keyof SoldProductItem, order: SortDirection) => {
    return (a: SoldProductItem, b: SoldProductItem) => {
      if (a[property] === null) return 1; // move nulls to the end
      if (b[property] === null) return -1; // move nulls to the start
      if (a !== null && b !== null && a[property] !== null && b[property] !== null) {
        if (property === 'salesNet' || property === 'soldPieces') {
          const aValue = a[property];
          const bValue = b[property];

          return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
      }
      return 0;
    };
  };

  const handleSorting = (columnId: string, direction: SortDirection): Row[] => {
    if (!soldProductItems) return [];
    if (!columnId) return getRows(soldProductItems);
    const sortedData = [...soldProductItems].sort(sortObjects(columnId as keyof SoldProductItem, direction));
    return getRows(sortedData);
  };

  useEffect(() => {
    setCurrentPageNumber(0);
  }, [soldProductItems]);

  if (!soldProductItems.length && !isFetchingGarments) {
    return (
      <StyledAnimationWrapper>
        <Animation
          animation={Animations.CONFUSED}
          title={t(soldProductsTranslations.noData.title.key, soldProductsTranslations.noData.title.defaultValue)}
          subtitle={t(
            soldProductsTranslations.noData.subtitle.key,
            soldProductsTranslations.noData.subtitle.defaultValue,
          )}
        ></Animation>
      </StyledAnimationWrapper>
    );
  }

  const sortedRows = soldProductItems ? getRows(soldProductItems) : [];

  return (
    <Table
      columns={tableHeaders}
      rows={sortedRows}
      onRowClick={onRowClick}
      paginationOptions={{
        enabled: sortedRows.length > 0,
        rowsPerPage: currentRowsPerPage,
        rowsPerPageOptions: rowsPerPageOptions,
        totalRows: sortedRows.length,
        defaultPageIndex: 0, // reset when user select new dropdown filter or date selection
        nextIconButtonProps: {
          disabled: isFetchingGarments || !(sortedRows.length - (currentPageNumber + 1) * currentRowsPerPage > 0),
        },
      }}
      selectedRowIndex={selectedRowIndex}
      onPageChange={(page) => setCurrentPageNumber(page)}
      onRowsPerPageChange={onRowsPerPageChange}
      handleSorting={handleSorting}
      labelRowsPerPage={t(rowsPerpageLabel.key, rowsPerpageLabel.defaultValue)}
      isLoading={isFetchingGarments}
      fixedHeight={height}
      whiteSpace="nowrap"
      noBorder
      stickyHeader
      useZebraStyles
    />
  );
};
