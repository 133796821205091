import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Forecast,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import {
  NewMembersPerformanceItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { Card, DropdownMenu } from '../../../../../stories/molecules';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { StyledCustomLegends } from '../../../Home.styles';
import { ProgressIndicator } from '../../../common/progressIndicator/ProgressIndicator';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetCommonState } from '../../../common/widgetCommonState/WidgetCommonState';
import { StyledIcon, StyledNewMemberWrapper, StyledSection, StyledWrapper } from './NewMembersWidget.styles';

export type NewMembersProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const NewMembersWidget: FC<NewMembersProps> = observer(
  ({ interval, currentDate, hasPermissionToEditKpi, isToday, handleDropdownDetails, fromDate, toDate }) => {
    const { t } = useTranslation();
    const {
      get: { kpiTargetsProps },
    } = useUser();

    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();

    const { fetchNewMembersData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<NewMembersPerformanceItem>();

    const { common, kpiTargets, newMembers } = homeTranslations;

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const target = getKpiTargetValue(kpiTargetsProps, 'dailyNewMembers');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyNewMembers')?.isLocked ?? false;

    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchNewMembersData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.newMembers;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<NewMembersPerformanceItem>('newMembers');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    }, [interval, currentDate]);

    const itemValueResolver = (item: NewMembersPerformanceItem) => {
      return item.newMembersCount;
    };

    const getTotalNewMembersCount = (items: (NewMembersPerformanceItem | null)[]) => {
      return items.reduce((acc, val) => acc + (val ? val.newMembersCount : 0), 0);
    };

    useEffect(() => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        valueResolver: itemValueResolver,
      });
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyNewMembers',
    });

    const totalNewMemberCount = getTotalNewMembersCount(performanceItems);
    const forecastTotalNewMemberCount = getTotalNewMembersCount([
      ...performanceItems,
      ...forecastPerformanceItems,
    ]);

    const totalNMTransactions = performanceItems.reduce(
      (acc, { totalTransactions }) => acc + totalTransactions,
      0,
    );

    const totalUnrecruitedValue = performanceItems.reduce((acc, { unrecruited }) => acc + unrecruited, 0);

    const totalNewMemberShare = totalNMTransactions === 0 ? 0 : (totalNewMemberCount / totalNMTransactions) * 100;
    const totalUnrecruitedPercentage =
      totalNMTransactions === 0 ? 0 : (totalUnrecruitedValue / totalNMTransactions) * 100;

    const targetProgressPercent = target && target != 0 ? (forecastTotalNewMemberCount / target) * 100 : 0;

    const isEditKPIDisabled = performanceItemsError || !hasPermissionToEditKpi;

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    let maxNewMemberCount = 0;

    performanceItems.forEach((item) => {
      const { newMembersCount } = item;
      if (newMembersCount > maxNewMemberCount) maxNewMemberCount = newMembersCount;
    });

    targetItems.forEach((item) => {
      if (!item) return;
      if (item > maxNewMemberCount) maxNewMemberCount = item;
    });

    let yAxisTickAmount = Math.ceil(maxNewMemberCount);

    while (yAxisTickAmount / 4 > 1) {
      yAxisTickAmount = yAxisTickAmount / 2;
    }

    yAxisTickAmount = Math.ceil(yAxisTickAmount);

    const menuItems = [
      {
        key: target ? 'editkpi' : 'addkpi',
        disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
        hasTooltip: isEditKPIDisabled || isTargetLocked,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <Card
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(newMembers.title.key, newMembers.title.defaultValue)}
        subHeaderText={t(newMembers.subTitle.key, newMembers.subTitle.defaultValue)}
        lastUpdatedDate={lastUpdated}
        headerRightElement={
          <StyledWrapper>
            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={menuItems}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItem) => {
                handleDropdownDetails('dailyNewMembers', selectedItem[0] as KpiDropdownActions);
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledWrapper>
        }
        content={
          <>
            {performanceItemsLoading && <WidgetCommonState isLoading />}

            {performanceItemsError && (
              <WidgetCommonState isError errorText={t(newMembers.error.key, newMembers.error.defaultValue)} />
            )}

            {!performanceItemsLoading && !performanceItemsError && isNoData && <WidgetCommonState isNoData />}

            {!performanceItemsLoading && !performanceItemsError && !isNoData && (
              <StyledNewMemberWrapper
                wrap="nowrap"
                padding={[3, 4]}
                data-testid="new-members-wrapper"
                height="100%"
              >
                {showForecast && (
                  <ProgressIndicator
                    valueType={Forecast.NUMBER}
                    value={Math.trunc(Math.round(forecastTotalNewMemberCount))}
                    progressPercentage={targetProgressPercent}
                    valueFormatter={(value) => Math.round(value).toFixed(0)}
                    padding={[1, 2]}
                    margin={[0, 0, 1, 0]}
                  />
                )}

                <Container width="100%" wrap="nowrap" direction="horizontal" space="between">
                  <StyledWrapper>
                    <StyledIcon icon={Icons.NEWMEMBER} size="small" margin={[0, 1, 0, 0]} />
                    <Typography type="body2">
                      {t(newMembers.totalNewMembers.key, newMembers.totalNewMembers.defaultValue)}:{' '}
                      {Math.round(totalNewMemberCount)}
                    </Typography>
                  </StyledWrapper>
                  <Container direction={isMobile ? 'vertical' : 'horizontal'} position="right">
                    {target && (
                      <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[2, 0, 0]}>
                        {t(common.targetKPINewMember.key, common.targetKPINewMember.defaultValue)} {target}
                      </StyledCustomLegends>
                    )}
                  </Container>
                </Container>
                <Container wrap="nowrap" data-testid="chart" height="100%">
                  <WidgetChart
                    performanceItemsChartName={t(newMembers.title.key, newMembers.title.defaultValue)}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                    forecastPerformanceItems={forecastPerformanceItems}
                    targetItems={targetItems}
                    itemValueResolver={itemValueResolver}
                    showXAxis={true}
                    yAxisTickAmount={yAxisTickAmount || 1}
                    yAxisMax={Math.ceil(maxNewMemberCount) || 1}
                    tooltipFormatter={(value) => `${Math.ceil(value)}`}
                    yAxisFormatter={(value) => `${value.toFixed(0)}`}
                  />
                </Container>
                <StyledSection padding={[2]} wrap="nowrap" direction="horizontal" data-testid="sale-wrapper">
                  <StyledIcon icon={Icons.CONVERSION} size="small" margin={[0, 2, 0, 0]} />
                  <Container wrap="nowrap" width="100%">
                    <Container direction="horizontal" position="center" space="between" margin={[0, 0, 1, 0]}>
                      <Typography>
                        {t(newMembers.totalNMShare.key, newMembers.totalNMShare.defaultValue)}
                      </Typography>
                      <Typography type="button">{totalNewMemberShare.toFixed(2)}%</Typography>
                    </Container>
                    <Container direction="horizontal" position="center" space="between">
                      <Typography>{t(newMembers.unrecruited.key, newMembers.unrecruited.defaultValue)}</Typography>
                      <Typography type="button">{totalUnrecruitedPercentage.toFixed(2)}%</Typography>
                    </Container>
                  </Container>
                </StyledSection>
              </StyledNewMemberWrapper>
            )}
          </>
        }
        toolTipText={t(newMembers.toolTip.key, newMembers.toolTip.defaultValue)}
      />
    );
  },
);
