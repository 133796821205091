import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '../../../../stories/atoms';
import { Animation, Animations } from '../../../../stories/molecules';
import { homeTranslations } from '../../../../translations';
import { StyledContainer } from './WidgetCommonState.styles';

export type WidgetStateProps = {
  isError?: boolean;
  isLoading?: boolean;
  isNoData?: boolean;
  errorText?: string;
  isNoWeeklyData?: boolean;
  customNoDataText?: string;
};

const errorComponent = (text: string) => {
  return (
    <StyledContainer data-testid="error-view">
      <Animation title="" animation={Animations.SAD} size="small" errorText={text} />
    </StyledContainer>
  );
};

const loadingComponent = (text: string) => {
  return (
    <Container data-testid="loading-view" position="center" height="100%">
      <CircularProgress size={24} thickness={3} />
      <Typography type="button" textAlign="center" padding={[4, 0, 0]}>
        {text}
      </Typography>
    </Container>
  );
};

const noDataComponent = (text: string) => {
  return (
    <Container data-testid="no-data" position="center" height="100%">
      <Typography type="button" textAlign="center">
        {text}
      </Typography>
    </Container>
  );
};

export const WidgetCommonState: FC<WidgetStateProps> = ({
  isError,
  isLoading,
  isNoData,
  errorText,
  isNoWeeklyData,
  customNoDataText,
}) => {
  const { t } = useTranslation();

  const noDataText = customNoDataText
    ? customNoDataText
    : isNoWeeklyData
    ? t(homeTranslations.common.noWeeklyData.key, homeTranslations.common.noWeeklyData.defaultValue)
    : t(homeTranslations.common.noReceipts.key, homeTranslations.common.noReceipts.defaultValue);

  return (
    <>
      {isError && errorText && errorComponent(errorText)}
      {isLoading &&
        loadingComponent(t(homeTranslations.common.loading.key, homeTranslations.common.loading.defaultValue))}
      {isNoData && noDataComponent(noDataText)}
    </>
  );
};
