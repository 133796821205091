/* eslint-disable @typescript-eslint/no-explicit-any */
import { translationMappingEndpoint } from '../constants';
import { useTranslationContext } from '../global-state/translationContext';
import { isFailureResponse, ObjectWithStringKeys } from '../utils';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';
import { useUser } from './useController';

// TODO: Remove this mock data and replace it with actual data
const translatedDetails = {
  'Cleaning, Pest and Waste': 'Limpieza, Plagas y Residuos',
  Electricity: 'Electricidad',
  Hangers: 'Perchas',
  'Other personnel costs/Employee': 'Otros costos de personal/Empleado',
  Others: 'Otros',
  'Reduction Cosmetics': 'Reducción de Cosméticos',
  'Reduction Textile': 'Reducción de Textiles',
  Reductions: 'Reducciones',
  Salaries: 'Salarios',
  'Sales Excl VAT': 'Ventas Excluyendo IVA',
  'Sales Incl VAT': 'Ventas Incluyendo IVA',
  'Shopfitting and Bulbs': 'Equipamiento de Tienda y Bombillas',
  'Statistical Cost of Reduction': 'Costo Estadístico de Reducción',
  'Temporary Staff Agency': 'Agencia de Personal Temporal',
  'Travel Expense': 'Gastos de Viaje',
};

export const useDynamicTranslation = () => {
  const {
    get: { keyToken },
  } = useUser();
  const { authAxiosRequest } = useAuthAxiosRequest(keyToken);
  const { translations, setTranslations } = useTranslationContext();

  // API to fetch the translations
  const fetchTranslations = async (propertyArray: string[], language: string) => {
    await authAxiosRequest<string[]>(`${translationMappingEndpoint}`, {
      method: 'POST',
      data: {
        plData: propertyArray,
        language: language,
      },
    });
    return { status: 200, data: translatedDetails };
  };

  // Call the API to fetch the translations
  const callTranslationAPI = async (transalationKeys: string[], language: string) => {
    if (!transalationKeys) return null;

    const response = await fetchTranslations(transalationKeys, language);

    if (isFailureResponse(response)) {
      return null;
    }

    const { data } = response;

    const translationData = {
      ...translations,
      [language]: {
        ...translations[language],
        ...data,
      },
    };

    setTranslations(translationData);
  };

  // Extract the string values from the object
  const extractValues = (pobj: ObjectWithStringKeys | ObjectWithStringKeys[], paths: string[]): string[] => {
    const results = [];

    const getValue = (obj: ObjectWithStringKeys | ObjectWithStringKeys[], path: string): any => {
      const parts = path.split('.');
      let current: any = obj;

      for (const part of parts) {
        if (part.includes('[') && part.includes(']')) {
          const [key, index] = part.split(/[\[\]]/).filter(Boolean);

          if (key && key !== '_root') {
            current = current[key];
          }
          if (index === '*') {
            return current.flatMap((item: any) => getValue(item, parts.slice(parts.indexOf(part) + 1).join('.')));
          } else {
            const idx = parseInt(index);
            if (idx >= 0 && idx < current.length) {
              current = current[idx];
            } else {
              return undefined; // Out of bounds, return undefined
            }
          }
        } else {
          current = current ? current[part] : undefined;
        }
      }
      return current;
    };

    for (const path of paths) {
      const value = getValue(pobj, path);
      if (Array.isArray(value)) {
        results.push(...value.flat());
      } else if (value !== undefined) {
        results.push(value);
      }
    }

    return results;
  };

  // Fetch the translations
  const fetchDataTranslations = async (
    data: ObjectWithStringKeys,
    translationProperties: string[],
    language: string,
  ) => {
    const extractedStrings = extractValues(data, translationProperties);

    if (translations[language]) {
      const filteredStrings = extractedStrings.filter((key) => !translations[language][key]);
      if (!!filteredStrings.length) {
        await callTranslationAPI(filteredStrings, language);
      }
    }
  };

  // Get the translations
  const getTranslations = (key: string, language: string): string => {
    return translations[language]?.[key] || key;
  };

  return { fetchDataTranslations, getTranslations };
};
