import { useMediaQuery } from '@mui/material';
import { getWeek, intlFormat } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { AveragePiecesPerReceiptPerformanceItem, useStorePerformance, useTransaction } from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { Card } from '../../../../../stories/molecules';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { formatAmountWithSeparator, isFailureResponse } from '../../../../../utils';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { WidgetCommonState } from '../../../common/widgetCommonState/WidgetCommonState';
import { StyledContainer, StyledPiecesWidgetWrapper, StyledSection } from './PiecesPerReceiptWidget.styles';

export type PiecesPerReceiptWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  isToday: boolean;
  fromDate?: Date;
  toDate?: Date;
};

export const PiecesPerReceiptWidget: FC<PiecesPerReceiptWidgetProps> = observer(
  ({ interval, currentDate, isToday, fromDate, toDate }) => {
    const { t, i18n } = useTranslation();

    const { fetchAveragePiecesPerReceiptData } = useTransaction();
    const { transactionEventsCount } = useEventContext();

    const { common, piecesPerReceipt: piecesPerReceipTranslations, datePicker } = homeTranslations;
    const { mode } = useThemeContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [today, setToday] = useState(0);
    const [lastWeek, setLastWeek] = useState(0);
    const [lastYear, setLastYear] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<AveragePiecesPerReceiptPerformanceItem>();

    const itemValueResolver = (item: AveragePiecesPerReceiptPerformanceItem) => {
      return item.amount;
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchAveragePiecesPerReceiptData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setToday(response.data.today);
      setLastWeek(response.data.lastWeek);
      setLastYear(response.data.lastYear);
      setLastUpdated(response.data.lastUpdated);

      return response.data.piecesPerReceipt;
    }, [interval, currentDate]);

    const formatAmount = (value: number) => {
      return `${Intl.NumberFormat(i18n.language, { notation: 'compact' }).format(value)}`;
    };

    useEffect(() => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        valueResolver: itemValueResolver,
      });
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    return (
      <Card
        headerIcon={Icons.SALESEFFICIENCY}
        headerText={t(piecesPerReceipTranslations.title.key, piecesPerReceipTranslations.title.defaultValue)}
        lastUpdatedDate={lastUpdated}
        subHeaderText={t(
          piecesPerReceipTranslations.subTitle.key,
          piecesPerReceipTranslations.subTitle.defaultValue,
        )}
        content={
          <>
            {performanceItemsLoading && <WidgetCommonState isLoading />}

            {performanceItemsError && (
              <WidgetCommonState
                isError
                errorText={t(
                  piecesPerReceipTranslations.error.key,
                  piecesPerReceipTranslations.error.defaultValue,
                )}
              />
            )}

            {isNoData && <WidgetCommonState isNoData />}

            {!performanceItemsLoading && !performanceItemsError && !isNoData && (
              <StyledPiecesWidgetWrapper wrap="nowrap" data-testid="pieces-per-receipt-wrapper" height="100%">
                <Typography type="button" margin={[2, 0, 2, 0]} padding={[0, 4]}>
                  {t(
                    piecesPerReceipTranslations.headerText.key,
                    piecesPerReceipTranslations.headerText.defaultValue,
                  )}
                </Typography>
                <StyledSection>
                  <StyledContainer padding={[2, 0]}>
                    <Typography type="button" margin={[0, 0, 3, 0]}>
                      {today.toFixed(2)}
                    </Typography>
                    <Icon margin={[0, 0, 2, 0]} icon={Icons.STORE} />
                    <Typography>
                      {interval === StorePerformanceInterval.WEEKLY
                        ? `${t(datePicker.weekLabel.key, datePicker.weekLabel.defaultValue)} ${getWeek(
                            currentDate,
                          )}`
                        : isToday
                        ? t(common.today.key, common.today.defaultValue)
                        : intlFormat(currentDate, {}, { locale: i18n.language })}
                    </Typography>
                  </StyledContainer>

                  <StyledContainer padding={[2, 0]}>
                    <StyledTrendIconComponent
                      originalAmount={today}
                      previousAmount={lastWeek}
                      padding={[1, 0, 2]}
                      textOptions={{ margin: [0, 0, 3, 0] }}
                      valueFormatter={(value) => value.toFixed(2)}
                    />
                    <Typography>{t(common.lastWeek.key, common.lastWeek.defaultValue)}</Typography>
                  </StyledContainer>

                  <StyledContainer padding={[2, 0]}>
                    <StyledTrendIconComponent
                      originalAmount={today}
                      previousAmount={lastYear}
                      padding={[1, 0, 2]}
                      textOptions={{ margin: [0, 0, 3, 0] }}
                      valueFormatter={(value) => value.toFixed(2)}
                    />
                    <Typography>{t(common.lastYear.key, common.lastYear.defaultValue)}</Typography>
                  </StyledContainer>
                </StyledSection>

                <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                  <WidgetChart
                    type="bar"
                    performanceItemsChartName={t(
                      piecesPerReceipTranslations.toolTip.key,
                      piecesPerReceipTranslations.toolTip.defaultValue,
                    )}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                    forecastPerformanceItems={forecastPerformanceItems}
                    itemValueResolver={itemValueResolver}
                    showXAxis={true}
                    showYAxis={false}
                    tooltipFormatter={(value) => formatAmountWithSeparator(value)}
                    dataLabel={{
                      enabled: true,
                      enabledOnSeries: [0, 2],
                      offsetY: -28,
                      style: {
                        fontSize: isMobile ? '8px' : '12px',
                        fontWeight: '100',
                        colors: [mode === 'dark' ? muiTheme.color.white : muiTheme.color.primary],
                      },
                      formatter: function (value) {
                        if (!value) return '';

                        return formatAmount(parseFloat(value.toString()));
                      },
                    }}
                  />
                </Container>
              </StyledPiecesWidgetWrapper>
            )}
          </>
        }
        toolTipText={t(piecesPerReceipTranslations.toolTip.key, piecesPerReceipTranslations.toolTip.defaultValue)}
      />
    );
  },
);
