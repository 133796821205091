import { useMediaQuery } from '@mui/material';
import { add, format, intlFormat, isEqual, startOfToday, sub } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../../global-state/types';
import { ProductIndexItem, useAsyncAction, useSoldProducts } from '../../hooks';
import i18n from '../../i18n';
import { Container, Icons, Tooltip, Typography } from '../../stories/atoms';
import {
  Animation,
  Animations,
  Button,
  DropdownMenu,
  DropdownMenuItem,
  FilterDrawer,
  FilterTag,
  FilterTagsWidget,
  FilterType,
  IconWithText,
} from '../../stories/molecules';
import { DateRangePicker } from '../../stories/molecules/date-range-picker/DateRangePicker';
import { Page } from '../../stories/organisms';
import { muiTheme } from '../../theme';
import { homeTranslations } from '../../translations';
import { productCategoryTranslations } from '../../translations/productCategory';
import { getUniqueListItems, isFailureResponse } from '../../utils';
import { GarmentGroupView } from './GarmentGroupView';
import { NestedGroupView } from './NestedGroupView';
import {
  ExpandButtonContainer,
  FilterHeaderContainer,
  NestedGroupViewContainer,
  StyledCloseIconWithText,
  TableFooterContainer,
} from './ProductCategory.styles';
import { TableFooter } from './TableFooter';
import { TableHeader } from './TableHeader';
import { TableItem } from './TableItem/TableItem';

export type GroupingKey = 'indexGroupName' | 'indexDescription' | 'subindex';

type CategoryKeys = {
  [key in GroupingKey]: string;
};

type GroupingKeyValueChainExpandedMap = {
  [key in string]?: boolean;
};

export type ProductItem = CategoryKeys & ProductIndexItem;

type GarmentGroupContentDetails = {
  groupingKey: GroupingKey | null;
  groupingKeyValue: string | null;
};

const getChildKey = (key: GroupingKey): GroupingKey | null => {
  if (key == 'indexGroupName') return 'indexDescription';
  if (key == 'indexDescription') return 'subindex';

  return null;
};

const getGroupingKeyValueChainValue = (groupingKeyChain: string[]) => {
  return groupingKeyChain.join('');
};

export const getTotalSalesNet = (productItems: ProductItem[]) => {
  return productItems.reduce((acc, { salesNet }) => acc + salesNet, 0);
};

export const getTotalLastWeek = (productItems: ProductItem[]) => {
  return productItems.reduce((acc, { salesNetLastWeek }) => acc + salesNetLastWeek, 0);
};

export const getTotalLastYear = (productItems: ProductItem[]) => {
  return productItems.reduce((acc, { salesNetLastYear }) => acc + salesNetLastYear, 0);
};

export const getSob = (salesNet: number, totalSalesNet: number) => {
  return (salesNet / totalSalesNet) * 100;
};

export const ProductCategoryPage: FC<unknown> = observer(() => {
  const { t } = useTranslation();
  const { common: commonTranslations } = homeTranslations;
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const { fetchProductIndex } = useSoldProducts();

  const {
    result: productItems,
    isLoading: productItemsLoading,
    isError: productItemsError,
    triggerAction: triggerGetProductItems,
  } = useAsyncAction<ProductItem[]>();

  const [currentDates, setCurrentDates] = useState<[Date, Date]>([startOfToday(), startOfToday()]);
  const [minDate, setMinDate] = useState<Date>(sub(new Date(), { months: 18 }));
  const [maxDate, setMaxDate] = useState<Date>();
  const getIsDateRangeFilterActive = (fromDate: Date, toDate: Date) => {
    return !(isEqual(fromDate, startOfToday()) && isEqual(toDate, startOfToday()));
  };

  // Date Filter Tag Text
  const currentDatesFilterText = `${intlFormat(currentDates[0], {}, { locale: i18n.language })}`;

  const currentDatesDefaultText = `${intlFormat(currentDates[0], {}, { locale: i18n.language })}`;

  const [sortValue, setSortValue] = useState<SortDirection | null>(null);
  const [filteredIndexDescriptions, setFilteredIndexDescriptions] = useState<string[]>([]);
  const [filteredSubIndexDescriptions, setFilteredSubIndexDescriptions] = useState<string[]>([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [garmentGroupViewDetails, setGarmentGroupViewDetails] = useState<GarmentGroupContentDetails | null>(null);

  const showGarmentGroupView = (groupingKey: GroupingKey | null, groupingKeyValue: string | null) => {
    if (!productItems || productItems.length === 0) return;

    setGarmentGroupViewDetails({ groupingKey, groupingKeyValue });
  };

  const hideGarmentGroupView = () => {
    setGarmentGroupViewDetails(null);
  };

  const getProductItems = async () => {
    const response = await fetchProductIndex(
      format(currentDates[0], 'yyyy-MM-dd'),
      format(currentDates[1], 'yyyy-MM-dd'),
    );

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data as ProductItem[];
  };

  // Handle expand/collapse
  const [groupingKeyValueChainExpandedMap, setGroupingKeyValueChainExpandedMap] =
    useState<GroupingKeyValueChainExpandedMap>({});

  const isAnyExpanded = Object.keys(groupingKeyValueChainExpandedMap).length !== 0;

  const handleToggleExpandGroup = (groupingKeyChain: string[]) => {
    const groupingKeyChainValue = getGroupingKeyValueChainValue(groupingKeyChain);

    const updatedGroupingKeyValueChainExpandedMap = { ...groupingKeyValueChainExpandedMap };

    if (updatedGroupingKeyValueChainExpandedMap[groupingKeyChainValue]) {
      const keys = Object.keys(updatedGroupingKeyValueChainExpandedMap);

      keys.forEach((key) => {
        if (key.indexOf(groupingKeyChainValue) === -1) return;
        delete updatedGroupingKeyValueChainExpandedMap[key];
      });

      setGroupingKeyValueChainExpandedMap(updatedGroupingKeyValueChainExpandedMap);
      return;
    }

    updatedGroupingKeyValueChainExpandedMap[groupingKeyChainValue] = true;
    setGroupingKeyValueChainExpandedMap(updatedGroupingKeyValueChainExpandedMap);
  };

  const getIsGroupExpanded = (groupingKeyChain: string[]) => {
    const groupingKeyValueChainValue = getGroupingKeyValueChainValue(groupingKeyChain);
    return groupingKeyValueChainExpandedMap[groupingKeyValueChainValue] || false;
  };

  const expandAll = () => {
    if (!productItems) return;

    const newGroupingKeyValueChainExpandedMap: GroupingKeyValueChainExpandedMap = {};

    productItems.forEach((item) => {
      let groupingKeyValueChain = '';
      let currentGroupingKey: GroupingKey = 'indexGroupName';

      while (true) {
        const groupingKeyValue = item[currentGroupingKey];

        if (!groupingKeyValue) break;

        groupingKeyValueChain += groupingKeyValue;

        newGroupingKeyValueChainExpandedMap[groupingKeyValueChain] = true;

        const nextGroupingKey = getChildKey(currentGroupingKey);

        if (!nextGroupingKey) break;

        currentGroupingKey = nextGroupingKey;
      }
    });

    setGroupingKeyValueChainExpandedMap(newGroupingKeyValueChainExpandedMap);
  };

  const collapseAll = () => {
    setGroupingKeyValueChainExpandedMap({});
  };

  // Handle Sorting
  const sortButtonHandler = () => {
    setSortValue((currentSortValue) => {
      if (currentSortValue === null) return SortDirection.Descending;
      if (currentSortValue === SortDirection.Descending) return SortDirection.Ascending;
      return SortDirection.Descending;
    });
  };

  const groupSortFunction = (groupA: ProductItem[], groupB: ProductItem[]) => {
    if (!sortValue) return 0;

    const totalNetSalesA = groupA.reduce((acc, { salesNet }) => acc + salesNet, 0);
    const totalNetSalesB = groupB.reduce((acc, { salesNet }) => acc + salesNet, 0);

    if (sortValue === SortDirection.Descending) {
      if (totalNetSalesA < totalNetSalesB) return 1;
      if (totalNetSalesA > totalNetSalesB) return -1;
    }

    if (totalNetSalesA > totalNetSalesB) return 1;
    if (totalNetSalesA < totalNetSalesB) return -1;

    return 0;
  };

  const itemSortFunction = (a: ProductItem, b: ProductItem) => {
    if (!sortValue) return 0;

    if (sortValue === SortDirection.Descending) {
      if (a.salesNet < b.salesNet) return 1;
      if (a.salesNet > b.salesNet) return -1;
    }

    if (a.salesNet > b.salesNet) return 1;
    if (a.salesNet < b.salesNet) return -1;

    return 0;
  };

  // Date picker handlers
  const datePickerOnAccept = (dates: [Date, Date]) => {
    setCurrentDates(dates);
  };

  const datePickerOnChange = (dates: [Date, Date]) => {
    if (!dates[0]) return;
    // Allowing to select one date due longer response time for now
    setMinDate(sub(dates[0], { days: 0 }));
    setMaxDate(add(dates[0], { days: 0 }));
  };

  const datePickerOnClose = () => {
    setMinDate(sub(new Date(), { months: 18 }));
    setMaxDate(undefined);
  };

  // Filter widget handlers
  const handleDateFilterTagClick = () => {
    setCurrentDates([startOfToday(), startOfToday()]);
  };

  const handleIndexFilterTagClick = (key: string) => {
    const keyIndex = filteredIndexDescriptions.indexOf(key);

    const updatedFilteredIndexDescriptions = [...filteredIndexDescriptions];
    updatedFilteredIndexDescriptions.splice(keyIndex, 1);
    setFilteredIndexDescriptions(updatedFilteredIndexDescriptions);
  };

  const handleSubIndexFilterTagClick = (key: string) => {
    const keyIndex = filteredSubIndexDescriptions.indexOf(key);

    const updatedFilteredSubIndexDescriptions = [...filteredSubIndexDescriptions];
    updatedFilteredSubIndexDescriptions.splice(keyIndex, 1);
    setFilteredSubIndexDescriptions(updatedFilteredSubIndexDescriptions);
  };

  const clearAllFilters = (resetDateFilter: boolean = true) => {
    const isDateRangeFilterActive = getIsDateRangeFilterActive(currentDates[0], currentDates[1]);

    // Don't reset the currentDates values if the default is being used
    if (isDateRangeFilterActive && resetDateFilter) {
      setCurrentDates([startOfToday(), startOfToday()]);
    }

    setFilteredIndexDescriptions([]);
    setFilteredSubIndexDescriptions([]);
  };

  const updateSubIndexOnIndexFilters = (
    currentFilteredIndexDescriptions: string[],
    currentFilteredSubIndexDescriptions: string[],
    currentProductItems: ProductItem[] | null,
  ) => {
    if (!currentProductItems) return;
    if (currentFilteredIndexDescriptions.length === 0) return;
    if (currentFilteredSubIndexDescriptions.length === 0) return;

    // we need to filter out sub index filters that don't exist on current selected index filters

    const indexProductItems = currentProductItems.filter(
      (item) => currentFilteredIndexDescriptions.indexOf(item.indexDescription) !== -1,
    );

    const updatedFilteredSubIndexDescriptions = currentFilteredSubIndexDescriptions.filter(
      (subIndexDescription) => {
        return indexProductItems.some((item) => item.subindex === subIndexDescription);
      },
    );

    setFilteredSubIndexDescriptions(updatedFilteredSubIndexDescriptions);
  };

  useEffect(() => {
    clearAllFilters(false);
    triggerGetProductItems(getProductItems);
  }, [currentDates]);

  useEffect(() => {
    updateSubIndexOnIndexFilters(filteredIndexDescriptions, filteredSubIndexDescriptions, productItems);
  }, [filteredIndexDescriptions, productItems]);

  const filteredProductItems: ProductItem[] = !productItems
    ? []
    : productItems.filter((item) => {
        const { indexDescription, subindex } = item;

        const isIndexFilterEnabled = filteredIndexDescriptions.length !== 0;
        const isSubIndexFilterEnabled = filteredSubIndexDescriptions.length !== 0;

        // if no filter is enabled return all items
        if (!isIndexFilterEnabled && !isSubIndexFilterEnabled) return true;

        const isIndexMatch = filteredIndexDescriptions.includes(indexDescription);
        const isSubIndexMatch = filteredSubIndexDescriptions.includes(subindex);

        if (isIndexFilterEnabled && !isSubIndexFilterEnabled) {
          return isIndexMatch;
        }

        if (isSubIndexFilterEnabled && !isIndexFilterEnabled) {
          return isSubIndexMatch;
        }

        return isIndexMatch && isSubIndexMatch;
      });

  const indexDropdownMenuItems: DropdownMenuItem[] = !productItems
    ? []
    : getUniqueListItems(productItems, (item) => item.indexDescription)
        .sort((a, b) => {
          if (a.indexDescription > b.indexDescription) return 1;
          if (a.indexDescription < b.indexDescription) return -1;
          return 0;
        })
        .map((item) => {
          return {
            key: item.indexDescription,
            item: <Typography margin={[0, 2, 0]}>{item.indexDescription}</Typography>,
          };
        });

  const subIndexDropdownMenuItems: DropdownMenuItem[] = !productItems
    ? []
    : getUniqueListItems(productItems, (item) => item.subindex)
        .sort((a, b) => {
          if (a.subindex > b.subindex) return 1;
          if (a.subindex < b.subindex) return -1;
          return 0;
        })
        .map((item) => {
          let isDisabled = true;

          if (filteredIndexDescriptions.length === 0) isDisabled = false;

          if (filteredIndexDescriptions.length !== 0) {
            isDisabled = !filteredIndexDescriptions.some((description) => description === item.indexDescription);
          }

          return {
            key: item.subindex,
            item: <Typography margin={[0, 2, 0]}>{item.subindex}</Typography>,
            disabled: isDisabled,
          };
        });

  const totalSalesNet = !productItems ? 0 : getTotalSalesNet(productItems);
  const totalSalesNetFiltered = getTotalSalesNet(filteredProductItems);
  const totalSalesNetLastWeek = !productItems ? 0 : getTotalLastWeek(productItems);
  const totalSalesNetLastYear = !productItems ? 0 : getTotalLastYear(productItems);

  const isDateRangeFilterActive = getIsDateRangeFilterActive(currentDates[0], currentDates[1]);
  const isIndexFilterActive = filteredIndexDescriptions.length > 0;
  const isSubIndexFilterActive = filteredSubIndexDescriptions.length > 0;

  const isAnyFilterActive = isDateRangeFilterActive || isIndexFilterActive || isSubIndexFilterActive;

  return (
    <Page
      header={
        garmentGroupViewDetails ? undefined : (
          <FilterHeaderContainer>
            {!isMobile && (
              <>
                <DateRangePicker
                  inputSelectedDates={currentDates}
                  onAccept={datePickerOnAccept}
                  onChange={datePickerOnChange}
                  onClose={datePickerOnClose}
                  minDate={minDate}
                  maxDate={maxDate}
                  disableFutureDates
                />

                <DropdownMenu
                  dropdownLabel={t(
                    productCategoryTranslations.filter.index.key,
                    productCategoryTranslations.filter.index.defaultValue,
                  )}
                  menuPosition="left"
                  maxHeight={'50vh'}
                  menuItems={indexDropdownMenuItems}
                  checkbox={true}
                  multiSelect
                  initialSelected={filteredIndexDescriptions}
                  buttonContentProps={{ isDisabled: indexDropdownMenuItems.length === 0 }}
                  onSelect={(selectedItemKeys) => setFilteredIndexDescriptions(selectedItemKeys)}
                />

                <DropdownMenu
                  dropdownLabel={t(
                    productCategoryTranslations.filter.subIndex.key,
                    productCategoryTranslations.filter.subIndex.defaultValue,
                  )}
                  menuPosition="left"
                  maxHeight={'50vh'}
                  menuItems={subIndexDropdownMenuItems}
                  checkbox={true}
                  multiSelect
                  initialSelected={filteredSubIndexDescriptions}
                  buttonContentProps={{ isDisabled: subIndexDropdownMenuItems.length === 0 }}
                  onSelect={(selectedItemKeys) => setFilteredSubIndexDescriptions(selectedItemKeys)}
                />
              </>
            )}

            {isMobile && (
              <>
                <FilterDrawer
                  isOpen={isFilterDrawerOpen}
                  onDrawerClose={() => setIsFilterDrawerOpen(false)}
                  onClearAllFilters={clearAllFilters}
                  totalResultEntries={filteredProductItems.length}
                  totalResultEntriesText={t(
                    productCategoryTranslations.filter.entriesText.key,
                    productCategoryTranslations.filter.entriesText.defaultValue,
                  )}
                  filtersSpecs={[
                    {
                      key: 'date',
                      type: FilterType.DateRange,
                      title: t(
                        productCategoryTranslations.filter.drawerDate.key,
                        productCategoryTranslations.filter.drawerDate.defaultValue,
                      ),
                      currentDates: currentDates,
                      onAccept: datePickerOnAccept,
                      onChange: datePickerOnChange,
                      onClose: datePickerOnClose,
                    },
                    {
                      key: 'sortBy',
                      type: FilterType.RadiolList,
                      title: t(
                        productCategoryTranslations.filter.drawerSort.key,
                        productCategoryTranslations.filter.drawerSort.defaultValue,
                      ),
                      options: [
                        {
                          key: SortDirection.Ascending.toString(),
                          title: t(
                            productCategoryTranslations.filter.sortAscending.key,
                            productCategoryTranslations.filter.sortAscending.defaultValue,
                          ),
                        },
                        {
                          key: SortDirection.Descending.toString(),
                          title: t(
                            productCategoryTranslations.filter.sortDescending.key,
                            productCategoryTranslations.filter.sortDescending.defaultValue,
                          ),
                        },
                      ],
                      selectedOptionKey: sortValue ? sortValue.toString() : null,
                      onSelect: (key) => setSortValue(key as unknown as SortDirection),
                    },
                    {
                      key: 'index',
                      type: FilterType.MultiSelectList,
                      title: t(
                        productCategoryTranslations.filter.index.key,
                        productCategoryTranslations.filter.index.defaultValue,
                      ),
                      options: indexDropdownMenuItems.map((item) => ({
                        key: item.key,
                        title: item.key,
                        disabled: item.disabled,
                      })),
                      selectedOptionsKeys: filteredIndexDescriptions,
                      onSelect: (selectedItemKeys) => setFilteredIndexDescriptions(selectedItemKeys),
                    },
                    {
                      key: 'subIndex',
                      type: FilterType.MultiSelectList,
                      title: t(
                        productCategoryTranslations.filter.subIndex.key,
                        productCategoryTranslations.filter.subIndex.defaultValue,
                      ),
                      options: subIndexDropdownMenuItems.map((item) => ({
                        key: item.key,
                        title: item.key,
                        disabled: item.disabled,
                      })),
                      selectedOptionsKeys: filteredSubIndexDescriptions,
                      onSelect: (selectedItemKeys) => setFilteredSubIndexDescriptions(selectedItemKeys),
                    },
                  ]}
                />

                <Button
                  label={t(
                    productCategoryTranslations.filter.allFilters.key,
                    productCategoryTranslations.filter.allFilters.defaultValue,
                  )}
                  buttonContentProps={{
                    labelPosition: 'left',
                    iconOptions: {
                      icon: Icons.FILTER,
                      size: 'small',
                    },
                    textOptions: {
                      whiteSpace: 'nowrap',
                    },
                  }}
                  aria-haspopup="true"
                  aria-expanded={isFilterDrawerOpen ? 'true' : undefined}
                  onClick={() => setIsFilterDrawerOpen(true)}
                  variant="text"
                />
              </>
            )}

            <ExpandButtonContainer>
              <Tooltip
                text={
                  <Typography color="white">
                    {t(
                      productCategoryTranslations.garmentGroup.viewAllbutton.key,
                      productCategoryTranslations.garmentGroup.viewAllbutton.defaultValue,
                    )}
                  </Typography>
                }
                position="bottom"
                isVisible={isMobile}
              >
                <div>
                  <IconWithText
                    label={
                      isMobile
                        ? undefined
                        : t(
                            productCategoryTranslations.garmentGroup.viewAllbutton.key,
                            productCategoryTranslations.garmentGroup.viewAllbutton.defaultValue,
                          )
                    }
                    iconOptions={{
                      icon: Icons.ALLWIDGETS,
                      size: 'small',
                      rotate: 'up',
                    }}
                    textOptions={{
                      type: 'button',
                    }}
                    onClick={() => showGarmentGroupView(null, null)}
                  />
                </div>
              </Tooltip>

              <Tooltip
                text={
                  <Typography color="white">
                    {isAnyExpanded
                      ? t(commonTranslations.collapseAll.key, commonTranslations.collapseAll.defaultValue)
                      : t(commonTranslations.expandAll.key, commonTranslations.expandAll.defaultValue)}
                  </Typography>
                }
                position="bottom"
                isVisible={isMobile}
              >
                <div>
                  <StyledCloseIconWithText
                    label={
                      isAnyExpanded
                        ? t(commonTranslations.collapseAll.key, commonTranslations.collapseAll.defaultValue)
                        : t(commonTranslations.expandAll.key, commonTranslations.expandAll.defaultValue)
                    }
                    shouldRotate={isAnyExpanded ? false : true}
                    iconOptions={{
                      icon: isAnyExpanded ? Icons.CHECKBOX_INDETERMINATE : Icons.CLOSE,
                      size: 'small',
                      rotate: 'up',
                    }}
                    textOptions={{
                      type: 'button',
                    }}
                    onClick={isAnyExpanded ? collapseAll : expandAll}
                    padding={[0, 0, 0, 8]}
                  />
                </div>
              </Tooltip>
            </ExpandButtonContainer>
          </FilterHeaderContainer>
        )
      }
      body={
        <div>
          {garmentGroupViewDetails && (
            <Container
              wrap="nowrap"
              padding={isMobile ? [4, 5] : [4, 8]}
              height="100%"
              style={{ position: 'relative' }}
            >
              {productItems && garmentGroupViewDetails && (
                <GarmentGroupView
                  productItems={productItems}
                  groupingKey={garmentGroupViewDetails.groupingKey}
                  groupingKeyValue={garmentGroupViewDetails.groupingKeyValue}
                  backButtonHandler={hideGarmentGroupView}
                />
              )}
            </Container>
          )}

          {!garmentGroupViewDetails && (
            <Container
              wrap="nowrap"
              padding={isMobile ? [4, 5] : [4, 8]}
              height="100%"
              style={{ position: 'relative' }}
            >
              {productItemsLoading && (
                <Animation
                  title={t(
                    productCategoryTranslations.loading.title.key,
                    productCategoryTranslations.loading.title.defaultValue,
                  )}
                  animation={Animations.LOADING}
                />
              )}

              {!productItemsLoading && productItemsError && (
                <Animation
                  title={t(
                    productCategoryTranslations.error.title.key,
                    productCategoryTranslations.error.title.defaultValue,
                  )}
                  subtitle={t(
                    productCategoryTranslations.error.subTitle.key,
                    productCategoryTranslations.error.subTitle.defaultValue,
                  )}
                  animation={Animations.SAD}
                />
              )}

              {!productItemsLoading && !productItemsError && productItems && productItems.length === 0 && (
                <>
                  <Typography padding={[2, 0, 2, 0]} type="body3">
                    {t(
                      productCategoryTranslations.datePrefix.key,
                      productCategoryTranslations.datePrefix.defaultValue,
                    )}{' '}
                    {!isDateRangeFilterActive ? currentDatesDefaultText : currentDatesFilterText}
                  </Typography>

                  <Animation
                    title={t(
                      productCategoryTranslations.noData.title.key,
                      productCategoryTranslations.noData.title.defaultValue,
                    )}
                    subtitle={t(
                      productCategoryTranslations.noData.subtitle.key,
                      productCategoryTranslations.noData.subtitle.defaultValue,
                    )}
                    animation={Animations.CONFUSED}
                  />
                </>
              )}

              {!productItemsLoading && !productItemsError && productItems && productItems.length > 0 && (
                <>
                  {!isMobile && (
                    <FilterTagsWidget
                      isClearButtonVisible={isAnyFilterActive}
                      clearButtonText={t(
                        productCategoryTranslations.filter.clearAllFilters.key,
                        productCategoryTranslations.filter.clearAllFilters.defaultValue,
                      )}
                      clearButtonHandler={clearAllFilters}
                      filterTags={[
                        isDateRangeFilterActive ? null : (
                          <Typography padding={[2, 0, 2, 0]} type="body3">
                            {t(
                              productCategoryTranslations.datePrefix.key,
                              productCategoryTranslations.datePrefix.defaultValue,
                            )}{' '}
                            {currentDatesDefaultText}
                          </Typography>
                        ),
                        !isDateRangeFilterActive ? null : (
                          <FilterTag
                            key={currentDatesFilterText}
                            title={currentDatesFilterText}
                            onClick={handleDateFilterTagClick}
                          />
                        ),
                        ...filteredIndexDescriptions.map((description) => (
                          <FilterTag
                            key={description}
                            title={description}
                            onClick={() => handleIndexFilterTagClick(description)}
                          />
                        )),
                        ...filteredSubIndexDescriptions.map((description) => (
                          <FilterTag
                            key={description}
                            title={description}
                            onClick={() => handleSubIndexFilterTagClick(description)}
                          />
                        )),
                      ]}
                    />
                  )}

                  {!isMobile && <TableHeader sortType={sortValue} sortButtonHandler={sortButtonHandler} />}

                  <NestedGroupViewContainer data-testid="nested-group-view-container" isMobileView={isMobile}>
                    <NestedGroupView
                      groupingKey="indexGroupName"
                      depth={0}
                      items={filteredProductItems}
                      getNextChildKey={getChildKey}
                      groupItemRenderer={(groupingKey, items, depth, groupingKeyValueChain) => {
                        const item = items[0];
                        const groupingKeyValue = item[groupingKey];

                        const salesNet = items.reduce((acc, x) => acc + x.salesNet, 0);
                        const lastWeek = items.reduce((acc, x) => acc + x.salesNetLastWeek, 0);
                        const lastYear = items.reduce((acc, x) => acc + x.salesNetLastYear, 0);

                        return (
                          <TableItem
                            depth={depth}
                            isParent={true}
                            isExpanded={getIsGroupExpanded(groupingKeyValueChain)}
                            title={item[groupingKey]}
                            salesNet={salesNet}
                            lastWeek={lastWeek}
                            lastYear={lastYear}
                            sob={getSob(salesNet, totalSalesNet)}
                            expandButtonHandler={() => handleToggleExpandGroup(groupingKeyValueChain)}
                            garmentGroupButtonHandler={() => showGarmentGroupView(groupingKey, groupingKeyValue)}
                          />
                        );
                      }}
                      itemRenderer={(item, depth) => {
                        const { id, departmentId, departmentName, salesNet, salesNetLastWeek, salesNetLastYear } =
                          item;

                        const title = `${departmentId} - ${departmentName}`;

                        return (
                          <TableItem
                            key={id}
                            depth={depth}
                            isParent={false}
                            isExpanded={false}
                            title={title}
                            salesNet={salesNet}
                            lastWeek={salesNetLastWeek}
                            lastYear={salesNetLastYear}
                            sob={getSob(salesNet, totalSalesNet)}
                          />
                        );
                      }}
                      getChildrenContainerStyle={(groupingKeyValueChain) => {
                        const isExpanded = getIsGroupExpanded(groupingKeyValueChain);

                        return {
                          height: isExpanded ? 'auto' : '0',
                          overflow: 'hidden',
                        };
                      }}
                      groupSortFunction={groupSortFunction}
                      itemSortFunction={itemSortFunction}
                    />
                  </NestedGroupViewContainer>

                  <TableFooterContainer>
                    <TableFooter
                      totalSalesNet={totalSalesNet}
                      totalSalesNetFiltered={totalSalesNetFiltered}
                      salesNetLastWeek={totalSalesNetLastWeek}
                      salesNetLastYear={totalSalesNetLastYear}
                    />
                  </TableFooterContainer>
                </>
              )}
            </Container>
          )}
        </div>
      }
    />
  );
});
