import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncAction, useUser } from '../../hooks';
import {
  GetBlacklistPermissionsResponse,
  PermissionItemsMap,
  UserPermissionType,
  useUserPermissions,
} from '../../hooks/useUserPermissions';
import { Container, Icons, Typography } from '../../stories/atoms';
import { Animation, Animations, Banner, BannerItemsType, Button } from '../../stories/molecules';
import { Switch } from '../../stories/molecules/switch/Switch';
import { Page } from '../../stories/organisms';
import { muiTheme } from '../../theme';
import { commonTranslations, homeTranslations, widgetAccessControlTranslations } from '../../translations';
import {
  UserPermissionsMap,
  compareObjectKeys,
  getPermissionsListFromMap,
  getPermissionsMapFromList,
  getUniqueId,
  getUserPermissions,
  isFailureResponse,
} from '../../utils';
import { WidgetType, WidgetsCategory } from '../home/types';
import { widgetsCategoryToWidgetTypesMap } from '../home/util';
import {
  ButtonsContainer,
  OptionContainer,
  OptionNameContainer,
  OptionSwitchContainer,
  OptionsContainer,
  StyledIcon,
  WidgetsSectionContainer,
  WidgetsSectionTitleContainer,
} from './WidgetAccessControlPage.styles';

type WidgetSectionDetails = {
  category: WidgetsCategory;
  widgetTypes: WidgetType[];
};

const widgetSectionsDetails: WidgetSectionDetails[] = [
  {
    category: WidgetsCategory.SALESEFFICIENCY,
    widgetTypes: widgetsCategoryToWidgetTypesMap.salesEfficiency,
  },
  {
    category: WidgetsCategory.CUSTOMERBASEVALUE,
    widgetTypes: widgetsCategoryToWidgetTypesMap.customerBaseValue,
  },
  {
    category: WidgetsCategory.CUSTOMEREXPERIENCE,
    widgetTypes: widgetsCategoryToWidgetTypesMap.customerExperience,
  },
  {
    category: WidgetsCategory.TRANSACTIONS,
    widgetTypes: widgetsCategoryToWidgetTypesMap.transactions,
  },
];

const getWidgetTranslationData = (widgetType: WidgetType): { key: string; defaultValue: string } | null => {
  switch (widgetType) {
    //  Sales and Efficiency
    case WidgetType.ConversionRate:
      return homeTranslations.conversionRate.title;
    case WidgetType.HourlyTurnover:
      return homeTranslations.hourlyTurnover.title;
    case WidgetType.ReceiptSales:
      return homeTranslations.receiptSales.title;
    case WidgetType.PiecesPerReceipt:
      return homeTranslations.piecesPerReceipt.title;
    case WidgetType.AveragePurchaseWidget:
      return homeTranslations.averagePurchase.title;
    case WidgetType.EffectiveHoursWidget:
      return homeTranslations.effectiveHours.title;

    //  Customer Base Value
    case WidgetType.NewMembers:
      return homeTranslations.newMembers.title;
    case WidgetType.TopSellingGarment:
      return homeTranslations.topSelling.title;
    case WidgetType.ShareOfReceipts:
      return homeTranslations.shareOfReceipts.title;

    //  Customer Experience
    case WidgetType.TopReturningGarment:
      return homeTranslations.topReturns.title;
    case WidgetType.QueueLengthWidget:
      return homeTranslations.queueLength.title;
    case WidgetType.CustomerFeedBackWidget:
      return homeTranslations.customerFeedback.title;

    // Transactions
    case WidgetType.TenderOverview:
      return homeTranslations.tenderOverview.title;
    case WidgetType.RegisteredReceipts:
      return homeTranslations.registeredReceipts.title;
    case WidgetType.AssetManagement:
      return homeTranslations.assetManagement.title;

    default:
      return null;
  }
};

const getPermissionTypeFromWidgetType = (widgetType: WidgetType): UserPermissionType | null => {
  switch (widgetType) {
    //  Sales and Efficiency
    case WidgetType.ConversionRate:
      return 'conversionRateWidget';
    case WidgetType.HourlyTurnover:
      return 'salesPerformanceWidget';
    case WidgetType.ReceiptSales:
      return 'takt1Widget';
    case WidgetType.PiecesPerReceipt:
      return 'avgPiecesPerReceiptWidget';
    case WidgetType.AveragePurchaseWidget:
      return 'avgPurchasePerReceiptWidget';
    case WidgetType.EffectiveHoursWidget:
      return 'effectiveHoursWidget';

    //  Customer Base Value
    case WidgetType.NewMembers:
      return 'newMembersWidget';
    case WidgetType.TopSellingGarment:
      return 'topSellingGarmentsWidget';
    case WidgetType.ShareOfReceipts:
      return 'shareOfReceiptWidget';

    //  Customer Experience
    case WidgetType.TopReturningGarment:
      return 'topSellingGarmentsWidget';
    case WidgetType.QueueLengthWidget:
      return 'queueLengthWidget';
    case WidgetType.CustomerFeedBackWidget:
      return 'customerFeedBackWidget';

    // Transactions
    case WidgetType.TenderOverview:
      return 'tenderOverviewWidget';
    case WidgetType.RegisteredReceipts:
      return 'registeredReceiptsWidget';
    case WidgetType.AssetManagement:
      return 'assetManagementWidget';

    default:
      return null;
  }
};

const getWidgetCategoryIcon = (widgetsCategory: WidgetsCategory): Icons | null => {
  switch (widgetsCategory) {
    case WidgetsCategory.HIGHLIGHTED:
      return Icons.STAR;
    case WidgetsCategory.CUSTOMEREXPERIENCE:
      return Icons.PROFILE;
    case WidgetsCategory.CUSTOMERBASEVALUE:
      return Icons.CUSTOMERVALUE;
    case WidgetsCategory.SALESEFFICIENCY:
      return Icons.SALESEFFICIENCY;
    case WidgetsCategory.TRANSACTIONS:
      return Icons.TRANSACTIONS;
    case WidgetsCategory.ALL:
      return Icons.ALLWIDGETS;
    default:
      return null;
  }
};

export const WidgetAccessControlPage: FC<unknown> = observer(() => {
  const isMobileTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('mobile'));
  const { t } = useTranslation();
  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const { widgetCategory: widgetCategoryTranslations } = homeTranslations;

  const {
    set,
    get: { currentStoreId, profile },
  } = useUser();

  const { getBlacklistPermissions, postBlacklistPermissions, fetchUserPermissions } = useUserPermissions();

  const [currentBlacklistPermissionsMap, setCurrentBlacklistPermissionsMap] = useState<UserPermissionsMap>({});
  const [formBlacklistPermissionsMap, setFormBlacklistPermissionsMap] = useState<UserPermissionsMap>({});

  const getIsPermissionBlacklisted = (permissionsMap: UserPermissionsMap, widgetType: WidgetType) => {
    const permissionType = getPermissionTypeFromWidgetType(widgetType);

    if (!permissionType) return true;

    return !!permissionsMap[permissionType];
  };

  const onSwitchToggle = (widgetType: WidgetType) => {
    const permissionType = getPermissionTypeFromWidgetType(widgetType);

    if (!permissionType) return true;

    if (formBlacklistPermissionsMap[permissionType]) {
      const updatedPermissionsMap = { ...formBlacklistPermissionsMap };
      delete updatedPermissionsMap[permissionType];

      setFormBlacklistPermissionsMap(updatedPermissionsMap);
      return;
    }
    setFormBlacklistPermissionsMap({
      ...formBlacklistPermissionsMap,
      [permissionType]: true,
    });
  };

  const {
    result: blacklistPermissions,
    isLoading: blacklistPermissionsLoading,
    isError: blacklistPermissionsError,
    triggerAction: triggerFetchBlacklistPermissions,
  } = useAsyncAction<GetBlacklistPermissionsResponse>();

  const {
    result: updatedBlacklistPermissions,
    isLoading: updateBlacklistPermissionsLoading,
    isError: updateBlacklistPermissionsError,
    triggerAction: triggerUpdateBlacklistPermissions,
  } = useAsyncAction<GetBlacklistPermissionsResponse>();

  const {
    result: userPermissions,
    isLoading: userPermissionsLoading,
    isError: userPermissionsError,
    triggerAction: triggerLoadUserPermissions,
  } = useAsyncAction<PermissionItemsMap>(true);

  const fetchBlacklistPermissions = async () => {
    const response = await getBlacklistPermissions();

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const updateBlacklistPermissions = async () => {
    const permissions = getPermissionsListFromMap(formBlacklistPermissionsMap);

    const response = await postBlacklistPermissions({
      permissions,
    });

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const loadUserPermissions = async () => {
    const response = await fetchUserPermissions(profile.upn, currentStoreId);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const restoreButtonHandler = () => {
    setFormBlacklistPermissionsMap(currentBlacklistPermissionsMap);
  };

  useEffect(() => {
    triggerFetchBlacklistPermissions(fetchBlacklistPermissions);
  }, []);

  useEffect(() => {
    if (!blacklistPermissions) return;

    setCurrentBlacklistPermissionsMap(getPermissionsMapFromList(blacklistPermissions.permissions));
    setFormBlacklistPermissionsMap(getPermissionsMapFromList(blacklistPermissions.permissions));
  }, [blacklistPermissions]);

  useEffect(() => {
    if (!updatedBlacklistPermissions) return;

    setBannerItems([
      {
        id: getUniqueId(),
        label: t(
          widgetAccessControlTranslations.updateSuccessMessage.key,
          widgetAccessControlTranslations.updateSuccessMessage.defaultValue,
        ),
        type: 'success',
      },
    ]);

    setCurrentBlacklistPermissionsMap(getPermissionsMapFromList(updatedBlacklistPermissions.permissions));
    setFormBlacklistPermissionsMap(getPermissionsMapFromList(updatedBlacklistPermissions.permissions));

    triggerLoadUserPermissions(loadUserPermissions);
  }, [updatedBlacklistPermissions]);

  useEffect(() => {
    if (!updateBlacklistPermissionsLoading) return;

    setBannerItems([]);
  }, [updateBlacklistPermissionsLoading]);

  useEffect(() => {
    if (!updateBlacklistPermissionsError) return;

    setBannerItems([
      {
        id: getUniqueId(),
        label: t(
          widgetAccessControlTranslations.updateErrorMessage.key,
          widgetAccessControlTranslations.updateErrorMessage.defaultValue,
        ),
        type: 'error',
      },
    ]);
  }, [updateBlacklistPermissionsError]);

  useEffect(() => {
    if (!userPermissions) return;

    const apiUserPermissions = getUserPermissions(userPermissions);

    set({ userPermissions: apiUserPermissions });
  }, [userPermissions]);

  const isFormValid = !compareObjectKeys(currentBlacklistPermissionsMap, formBlacklistPermissionsMap);

  return (
    <Page
      body={
        <div>
          <Container
            wrap="nowrap"
            padding={isMobileTablet ? [2, 4, 20, 4] : [4, 8, 20, 8]}
            style={{ position: 'relative', flex: 1 }}
          >
            <Banner isSticky items={bannerItems} onChange={(items) => setBannerItems(items)} />

            {blacklistPermissionsLoading && (
              <Animation
                title={t(
                  widgetAccessControlTranslations.loading.title.key,
                  widgetAccessControlTranslations.loading.title.defaultValue,
                )}
                animation={Animations.LOADING}
              />
            )}

            {!blacklistPermissionsLoading && blacklistPermissionsError && (
              <Animation
                title={t(
                  widgetAccessControlTranslations.error.title.key,
                  widgetAccessControlTranslations.error.title.defaultValue,
                )}
                subtitle={t(
                  widgetAccessControlTranslations.error.subTitle.key,
                  widgetAccessControlTranslations.error.subTitle.defaultValue,
                )}
                animation={Animations.SAD}
              />
            )}

            {userPermissionsError && (
              <Animation
                title={t(
                  widgetAccessControlTranslations.permissionLoadError.title.key,
                  widgetAccessControlTranslations.permissionLoadError.title.defaultValue,
                )}
                subtitle={t(
                  widgetAccessControlTranslations.permissionLoadError.subTitle.key,
                  widgetAccessControlTranslations.permissionLoadError.subTitle.defaultValue,
                )}
                button={{
                  isLoading: userPermissionsLoading,
                  label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
                  onClick: () => triggerLoadUserPermissions(loadUserPermissions),
                  buttonContentProps: {
                    iconOptions: {
                      icon: Icons.REFRESH,
                      color: 'white',
                    },
                  },
                }}
                animation={Animations.SAD}
              />
            )}

            {!blacklistPermissionsLoading &&
              !blacklistPermissionsError &&
              blacklistPermissions &&
              !userPermissionsError && (
                <>
                  <Typography type="h3" overflow={'visible'}>
                    {t(
                      widgetAccessControlTranslations.title.key,
                      widgetAccessControlTranslations.title.defaultValue,
                    )}
                  </Typography>

                  <Typography overflow={'visible'} gutterBottom>
                    {t(
                      widgetAccessControlTranslations.subTitle.key,
                      widgetAccessControlTranslations.subTitle.defaultValue,
                    )}{' '}
                    {blacklistPermissions ? blacklistPermissions.areaCode : ''}
                  </Typography>

                  {widgetSectionsDetails.map((widgetSectionDetails) => {
                    const { category, widgetTypes } = widgetSectionDetails;
                    const icon = getWidgetCategoryIcon(category);

                    return (
                      <WidgetsSectionContainer key={category} isMobileView={isMobileTablet}>
                        <WidgetsSectionTitleContainer>
                          {icon && <StyledIcon icon={icon} size="small" />}

                          <Typography type="h4" overflow={'visible'}>
                            {t(
                              widgetCategoryTranslations[category].key,
                              widgetCategoryTranslations[category].defaultValue,
                            )}
                          </Typography>
                        </WidgetsSectionTitleContainer>

                        <OptionsContainer isMobileView={isMobile}>
                          {widgetTypes.map((widgetType) => {
                            const translationData = getWidgetTranslationData(widgetType);
                            const isActive = !getIsPermissionBlacklisted(formBlacklistPermissionsMap, widgetType);

                            if (!translationData) return null;

                            return (
                              <OptionContainer key={widgetType}>
                                <OptionNameContainer>
                                  <Typography overflow={'visible'}>
                                    {t(translationData.key, translationData.defaultValue)}
                                  </Typography>
                                </OptionNameContainer>

                                <OptionSwitchContainer data-testid={`${widgetType}-switch-container`}>
                                  <Switch
                                    isActive={isActive}
                                    label={
                                      isActive
                                        ? t(
                                            widgetAccessControlTranslations.switchEnabled.key,
                                            widgetAccessControlTranslations.switchEnabled.defaultValue,
                                          )
                                        : t(
                                            widgetAccessControlTranslations.switchDisabled.key,
                                            widgetAccessControlTranslations.switchDisabled.defaultValue,
                                          )
                                    }
                                    containerWidth={130}
                                    disabled={false}
                                    onClick={() => onSwitchToggle(widgetType)}
                                  />
                                </OptionSwitchContainer>
                              </OptionContainer>
                            );
                          })}
                        </OptionsContainer>
                      </WidgetsSectionContainer>
                    );
                  })}

                  <ButtonsContainer>
                    <Button
                      label={t(
                        widgetAccessControlTranslations.restoreButtonText.key,
                        widgetAccessControlTranslations.restoreButtonText.defaultValue,
                      )}
                      fullWidth
                      disabled={!isFormValid}
                      variant="secondary"
                      onClick={restoreButtonHandler}
                    />
                    <Button
                      label={t(
                        widgetAccessControlTranslations.saveButtonText.key,
                        widgetAccessControlTranslations.saveButtonText.defaultValue,
                      )}
                      fullWidth
                      isLoading={updateBlacklistPermissionsLoading || userPermissionsLoading}
                      disabled={!isFormValid}
                      onClick={() => triggerUpdateBlacklistPermissions(updateBlacklistPermissions)}
                    />
                  </ButtonsContainer>
                </>
              )}
          </Container>
        </div>
      }
    />
  );
});
