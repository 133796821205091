import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { RoutePaths } from './constants';
import { LocalStorageKey } from './constants/localStorageKey';
import { Store } from './global-state/types';
import { useLocalStorage, useStores, useUser } from './hooks';
import { PermissionItemsMap } from './hooks/useUserPermissions';
import { Home, NotFound } from './pages';
import { Icons } from './stories/atoms';
import { Animation, Animations, Banner } from './stories/molecules';
import { ActionButton, BannerItemsType } from './stories/molecules/banner';
import { ErrorFallback, Header } from './stories/organisms';
import { commonTranslations, errors, homeTranslations } from './translations';
import { sideMenuTranslations } from './translations/navigation';
import { Translation, getUniqueId, getUserPermissions, isFailureResponse } from './utils';

const nonAuthorizedPermissions: PermissionItemsMap = {
  transactionSearchPage: '',
  employeesAndRolesPage: '',
  avatarManagementPage: '',
  kpiTargetsPage: '',
  chatBoxPage: '',
  newMembersWidget: '',
  shareOfReceiptWidget: 'R',
  topSellingGarmentsWidget: 'R',
  salesPerformanceWidget: 'R',
  conversionRateWidget: 'R',
  registeredReceiptsWidget: '',
  tenderOverviewWidget: '',
  assetManagementWidget: '',
  avgPiecesPerReceiptWidget: '',
  avgPurchasePerReceiptWidget: '',
  areaComparisonWidget: '',
  takt1Widget: '',
  sptWidget: '',
  effectiveHoursWidget: '',
  personalSettings: '',
  kpiTargets: '',
  areaCaps: '',
  lockTargets: '',
  highlightWidget: '',
  financialPlanned: '',
  financialBudget: '',
  employeePermissions: '',
  appCustomers: '',
  soldProductsPage: '',
  productIndexSubindex: '',
  customerFeedBackWidget: '',
  blacklistPermissionsForArea: '',
  queueLengthWidget: '',
};

export type RouteList = {
  section: Translation;
  links: {
    path: RoutePaths;
    element: ReactNode;
    translation: Translation;
    icon: Icons;
    badgeIcon?: JSX.Element;
  }[];
};

const { home, dashboards } = sideMenuTranslations;

export const AppNonSignin: FC<PropsWithChildren<unknown>> = observer(() => {
  const [isError, setIsError] = useState(false);
  const [isFetchingStore, setIsFetchingStore] = useState<boolean>(true);
  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [systemBanners, setSystemBanners] = useState<BannerItemsType[]>([]);

  const {
    set,
    get: { keyToken },
  } = useUser();
  const { fetchStoresData } = useStores();
  const { getItem, setItem } = useLocalStorage();
  const { t, i18n } = useTranslation();

  const storeIdParam = new URLSearchParams(window.location.search).get('storeId') as string;
  const mobileDeviceKey = new URLSearchParams(window.location.search).get('key') as string;

  useEffect(() => {
    if (!i18n.resolvedLanguage) {
      setSystemBanners(() => [
        {
          id: getUniqueId(),
          label: t(
            homeTranslations.banners.translationError.key,
            homeTranslations.banners.translationError.defaultValue,
          ),
          type: 'warning',
          actionButton: ActionButton.Close,
          hasNoMargin: true,
        },
      ]);
      return;
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    const getToken = async () => {
      set({ keyToken: mobileDeviceKey });
    };
    getToken();
  }, [mobileDeviceKey]);

  useEffect(() => {
    if (!keyToken) return;
    const getStoreData = async () => {
      const storesResponse = await fetchStoresData(storeIdParam);
      if (isFailureResponse(storesResponse)) {
        setIsError(true); // This might not be a core error
        return;
      }

      const { data } = storesResponse;

      // Setting localStorage using url storeId param
      setItem(LocalStorageKey.SelectedStore, storeIdParam);

      set({
        userPermissions: getUserPermissions(nonAuthorizedPermissions),
        stores: [data] as Store[],
        currentStoreId: storeIdParam,
      });
      setIsFetchingStore(false);
    };

    getStoreData();
  }, [keyToken]);

  const featureMapping = new Map<string, boolean>();

  const filteredMenuList = () => {
    const routes: RouteList[] = [
      {
        section: dashboards,
        links: [
          {
            path: RoutePaths.HOME,
            translation: home,
            element: <Home />,
            icon: Icons.HOME,
          },
        ],
      },
    ];
    const filteredList = routes.map((section) => {
      const formatLinks = section.links.filter((link) => {
        if (featureMapping.has(link.path)) {
          const isEnabled = featureMapping.get(link.path);

          if (!isEnabled) return false;
        }
        return link;
      });

      return {
        ...section,
        links: formatLinks,
      };
    });
    return filteredList;
  };

  const ErrorAnimation = () => {
    return (
      <AnimationWrapper>
        <ErrorFallback />
      </AnimationWrapper>
    );
  };

  const UnauthorizedError = () => {
    return (
      <AnimationWrapper>
        <Animation
          title={t(errors.pageNotFound.title.key, errors.pageNotFound.title.defaultValue)}
          subtitle={t(errors.pageNotFound.subtitle.key, errors.pageNotFound.subtitle.defaultValue)}
          animation={Animations.SAD}
        />
      </AnimationWrapper>
    );
  };
  if (isError) return <UnauthorizedError />;

  if (isFetchingStore) {
    return (
      <AnimationWrapper>
        <Animation
          title={t(commonTranslations.loading.key, commonTranslations.loading.defaultValue)}
          animation={Animations.LOADING}
        />
      </AnimationWrapper>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorAnimation}>
      <Banner
        isSticky
        items={systemBanners}
        onChange={(items) => {
          setSystemBanners(items);
        }}
      />
      <Container>
        <Banner
          items={bannerItems}
          onChange={(items) => {
            setBannerItems(items);
          }}
        />
        {<Header isNonSignPage={true} currentPathNameTranslation={home} />}

        <Routes>
          {filteredMenuList().map(({ links }) =>
            links.map((link) => <Route key={link.path} path={link.path} element={link.element} />),
          )}
          <Route index element={<Navigate to="/home" />} />
          <Route path={`/home/${getItem(LocalStorageKey.SelectedStore)}`} element={<Navigate to="/home" />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </ErrorBoundary>
  );
});

const Container = styled.div<{ isFullScreen?: boolean }>`
  display: grid;
  grid-template-areas:
    'header header'
    'page page';
  grid-template-rows: ${({ theme: { sizing } }) => `${sizing.header} ${sizing.page}`};
  grid-template-columns: '100%';
`;

const AnimationWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.backgroundDark};
`;
