import { StorePerformanceInterval } from '../../constants';
import { Store, StoreDetails } from '../../global-state/types';

import {
  AreaComparisonItem,
  AssetManagementResponse,
  SalesEfficiencyMetrics,
  Status,
  StorePerformanceItem,
  Tills,
} from '../../hooks';

import { getTodayPerformanceItems } from '../../utils/storePerformanceChart';
import { getRandomNumber } from './profit-and-loss.mock';
import {
  getConversionRateData,
  getHourlyTournoverData,
  getMockShareOfReceiptsData,
  getNewMembersData,
  getQueueLengthForecastData,
} from './transaction-search.mock';

const timing1 = {
  open: {
    opens: '10:00',
    closes: '19:00',
  },
  exception: {
    opens: '',
    closes: '',
  },
};

const timing2 = {
  open: {
    opens: '10:00',
    closes: '17:00',
  },
  exception: {
    opens: '',
    closes: '',
  },
};

const openingHours = {
  mon: timing1,
  tue: timing2,
  wed: timing1,
  thu: timing1,
  fri: timing2,
  sat: timing2,
  sun: timing1,
};

const openingHourExceptions = {
  item: [
    { date: '2022-11-22', openingHours: { opens: '10:00', closes: '21:00' }, isClosedAllDay: false },
    { date: '2022-11-24', openingHours: { opens: '10:00', closes: '22:00' }, isClosedAllDay: false },
    { date: '2022-11-26', openingHours: { opens: '10:00', closes: '18:00' }, isClosedAllDay: true },
  ],
};

export const mockStoresDetails: StoreDetails[] = [
  {
    storeId: 'SE0038',
    name: 'H&M - Drottninggatan 56 - Stockholm',
    area: 'EW Area SE',
    longitude: '11.96734995286636',
    latitude: '57.70456331254925',
    address: 'Stockholm',
    timezone: 'Europe/Stockholm',
    status: 'OPEN',
    corporateBrandId: 0,
  },
  {
    storeId: 'SE0144',
    name: 'H&M - Mall Of Scandinavia - Solna',
    area: 'Box Area Demo',
    longitude: '18.003338',
    latitude: '59.370186',
    address: 'Evenemangsgatan 14C, Solna, 16979',
    timezone: 'Europe/Stockholm',
    status: 'CLOSED',
    corporateBrandId: 1,
  },
];

export const mockStores: Store[] = [
  {
    id: '1ec4df22-9fa4-4b69-b1f8-d51c781e922c',
    storeId: 'SE0038',
    area: 'SE Mälardalen',
    popularName: 'H&M - Drottninggatan 56 - Stockholm',
    countryCode: 'SE',
    status: 'OPEN',
    currency: 'SEK',
    openingHours: { ...openingHours },
    openingHourExceptions: { ...openingHourExceptions },
    kpis: [
      { name: 'dailyConversionRate', target: 20, locked: true },
      { name: 'dailyTurnOver', target: 10000 },
      { name: 'dailyNewMembers', target: 5, locked: true },
      { name: 'dailyShareOfReceipts', target: 10 },
    ],
    corporateBrandId: 0,
  },
  {
    id: '24f9315e-cd43-4bfc-af4c-4f9e82d5b16c',
    storeId: 'SE0663',
    area: 'SE Mälardalen',
    popularName: 'Weekday - Kungsgatan 51',
    countryCode: 'SE',
    currency: 'SEK',
    status: 'OPEN',
    openingHours: { ...openingHours },
    openingHourExceptions: { ...openingHourExceptions },
    kpis: [
      { name: 'dailyConversionRate', target: 25 },
      { name: 'dailyTurnOver', target: 10000 },
      { name: 'dailyNewMembers', target: 8 },
      { name: 'dailyShareOfReceipts', target: 10 },
    ],
    corporateBrandId: 0,
  },
];

const mockReceiptType = ['OnlineReturn', 'Sales', 'Return'];
const mockOperatorIds = ['A110001', 'A110003', 'A110004'];
const mockNames = ['POS#0001', 'POS#0002', 'POS#0003', 'POS#0004', 'POS#0005', 'POS#0006'];
const statuses = ['OK', 'WARNING', 'UNKNOWN'];

const getMockReceiptType = () => {
  return mockReceiptType[Math.floor(Math.random() * mockReceiptType.length)];
};

const getMockOperatorId = () => {
  return mockOperatorIds[Math.floor(Math.random() * mockOperatorIds.length)];
};

const getMockName = () => {
  return mockNames[Math.floor(Math.random() * mockNames.length)];
};
const getStatus = () => {
  return statuses[Math.floor(Math.random() * statuses.length)];
};

const mockTotalAmount = [189, 200, 201, 450, 234, 543];

export const getMockAssetManagementData = (noOfTills = 6): AssetManagementResponse => {
  const tills: Tills[] = [];

  for (let i = 0; i < noOfTills; i++) {
    const status = getStatus() as Status;

    const till: Tills = {
      name: getMockName(),
      amounts: [
        { amount: 169, currency: 'SEK' },
        { amount: 20, currency: 'USD' },
      ],
      totalAmount: mockTotalAmount[Math.floor(Math.random() * mockTotalAmount.length)],
      userLog: [
        {
          lastSeen: new Date().toISOString(),
          operatorId: getMockOperatorId(),
        },
        {
          lastSeen: new Date().toISOString(),
          operatorId: getMockOperatorId(),
        },
      ],
      status,
    };

    if (status !== 'UNKNOWN') {
      till.latestTransaction = {
        timestamp: new Date().toISOString(),
        type: getMockReceiptType(),
      };
    }

    tills.push(till);
  }
  return {
    tills,
    lastUpdated: new Date(),
  };
};

export const getMockHighlightedWidgets = () => {
  return ['dailyShareOfReceipts', 'dailyTurnOver', 'dailyNewMembers'];
};

export const getMockTills = () => {
  return getMockAssetManagementData().tills;
};

export const getMockWeatherData = () => {
  const data = {
    name: 'Eskilstuna',
    situation: 'overcast clouds',
    icon: 'cloudy',
    units: 'Celsius',
    temperature: 2,
    temperatureHigh: 2,
    temperatureLow: 3,
  };
  return data;
};

export const forecastData = (kpi: string): StorePerformanceItem[] => {
  let storePerformanceItems: StorePerformanceItem[] = [];
  let storePerformanceCompleteItems: StorePerformanceItem[] = [];

  if (kpi === 'newMembers') {
    storePerformanceItems = getNewMembersData().newMembers;
    storePerformanceCompleteItems = getNewMembersData(false).newMembers;
  }

  if (kpi === 'shareOfReceipt') {
    storePerformanceItems = getMockShareOfReceiptsData().shareOfReceipts;
    storePerformanceCompleteItems = getMockShareOfReceiptsData(false).shareOfReceipts;
  }

  if (kpi === 'netSale') {
    storePerformanceItems = getHourlyTournoverData().netSales;
    storePerformanceCompleteItems = getHourlyTournoverData(false).netSales;
  }

  if (kpi === 'conversionRate') {
    storePerformanceItems = getConversionRateData().conversionRate;
    storePerformanceCompleteItems = getConversionRateData(false).conversionRate;
  }

  if (kpi === 'queueLength') {
    return getQueueLengthForecastData();
  }

  const filteredPerformanceData = getTodayPerformanceItems({
    interval: StorePerformanceInterval.DAILY,
    currentUTCTime: new Date(),
    storeTimezone: mockStoresDetails[0].timezone,
    storePerformanceItems: storePerformanceItems,
    valueResolver: () => 0,
  });

  const forecastItems: StorePerformanceItem[] = [];

  storePerformanceCompleteItems.forEach((item, index) => {
    if (filteredPerformanceData[index]) return;

    forecastItems.push(item);
  });

  return forecastItems;
};

export const getMockAreaComparisonData = (): AreaComparisonItem[] => {
  const metrics = [
    {
      name: 'averageNetSale',
      min: 50,
      max: 100,
      factor: 1000,
    },
    {
      name: 'averageVisitors',
      min: 1000,
      max: 2000,
      decimals: 0,
    },
    {
      name: 'averageConversionRate',
      min: 15,
      max: 30,
    },
    {
      name: 'averageTakt1',
      min: 800,
      max: 1200,
    },
    {
      name: 'averagePiecesPerReceipt',
      min: 1,
      max: 3,
    },
    {
      name: 'averageSPT',
      min: 5,
      max: 15,
    },
    {
      name: 'averagePurchasePerReceipt',
      min: 200,
      max: 400,
    },
    {
      name: 'averageEffectiveHours',
      min: 40,
      max: 60,
    },
  ];

  const data = metrics.map((metric) => ({
    name: metric.name as SalesEfficiencyMetrics,
    today: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastWeek: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastYear: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastWeekArea: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastYearArea: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
  }));

  return data;
};
