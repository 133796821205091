import { CircularProgress, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetPlanned, TrendEnum } from '../../../../constants/budgetPlanned';
import { Container, Icons, Typography } from '../../../../stories/atoms';
import { Animation, Animations } from '../../../../stories/molecules';
import { muiTheme } from '../../../../theme';
import { homeTranslations } from '../../../../translations';
import { UserPermissions } from '../../../../utils';
import {
  StyledBudgetPlannedIcon,
  StyledContainer,
  StyledDivider,
  StyledIconContainer,
  StyledTrendIcon,
  StyledTypography,
} from './BudgetPlannedContent.styles';

export const getBudgetAndPlannedPermissions = (userPermissions: UserPermissions) => {
  const canViewBudget = userPermissions.financialBudget.canRead;
  const canViewPlanned = userPermissions.financialPlanned.canRead;

  return {
    canViewBudget,
    canViewPlanned,
    showBudgetAndPlanned: canViewBudget || canViewPlanned,
  };
};
export type BudgetPlannedContentProps = {
  canViewBudget: boolean;
  canViewPlanned: boolean;
  budget: BudgetPlanned;
  planned: BudgetPlanned;
  budgetNoData?: boolean;
  plannedNoData?: boolean;
  isLoading?: boolean;
  isError?: boolean;
};

export const BudgetPlannedContent: FC<React.PropsWithChildren<BudgetPlannedContentProps>> = ({
  canViewBudget,
  canViewPlanned,
  budget,
  planned,
  budgetNoData,
  plannedNoData,
  isLoading = false,
  isError = false,
}) => {
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const { t } = useTranslation();
  const { budgetPlanned, common } = homeTranslations;

  return (
    <>
      <Typography type={isMobileView ? 'h2' : 'h3'}>
        {t(budgetPlanned.title.key, budgetPlanned.title.defaultValue)}
      </Typography>
      <StyledDivider data-testid="divider" />

      {isLoading && (
        <Container data-testid="loading-view" position="center" height="80%">
          <CircularProgress size={24} thickness={3} />
        </Container>
      )}

      {!isLoading && isError && (
        <Container data-testid="loading-view" position="center" height="80%">
          <Animation
            title=""
            animation={Animations.SAD}
            size="small"
            errorText={t(budgetPlanned.error.key, budgetPlanned.error.defaultValue)}
          />
        </Container>
      )}

      {!isLoading && !isError && canViewBudget && (
        <StyledContainer
          data-testid="budget-wrapper"
          wrap="nowrap"
          direction="horizontal"
          padding={[3, 2]}
          margin={[3, 0]}
          space="between"
          position="center"
        >
          <Container wrap="nowrap">
            <Typography>{t(budgetPlanned.budget.key, budgetPlanned.budget.defaultValue)}</Typography>
            <StyledTypography trend={budgetNoData ? null : budget.trend} type="h4">
              {budget.data}
            </StyledTypography>
            {!budgetNoData && (
              <StyledTypography trend={budget.trend}>{`${
                budget.trend === TrendEnum.UP ? '+' : '-'
              } ${budget.percentage.toFixed(2)}% ${t(
                common.thanActual.key,
                common.thanActual.defaultValue,
              )}`}</StyledTypography>
            )}
          </Container>
          <StyledIconContainer>
            {!budgetNoData && <StyledTrendIcon trend={budget.trend} data-testid="trend-icon" icon={Icons.ARROW} />}
            <StyledBudgetPlannedIcon
              color="textGray"
              margin={[3, 0, 0]}
              data-testid="budget-icon"
              icon={Icons.SALESEFFICIENCY}
            />
          </StyledIconContainer>
        </StyledContainer>
      )}

      {!isLoading && !isError && canViewPlanned && (
        <StyledContainer
          data-testid="planned-wrapper"
          wrap="nowrap"
          direction="horizontal"
          padding={[3, 2]}
          space="between"
          position="center"
        >
          <Container wrap="nowrap">
            <Typography>{t(budgetPlanned.planned.key, budgetPlanned.planned.defaultValue)}</Typography>
            <StyledTypography trend={plannedNoData ? null : planned.trend} type="h4">
              {planned.data}
            </StyledTypography>
            {!plannedNoData && (
              <StyledTypography trend={planned.trend}>{`${
                planned.trend === TrendEnum.UP ? '+' : '-'
              }${planned.percentage.toFixed(2)} % ${t(
                common.thanActual.key,
                common.thanActual.defaultValue,
              )}`}</StyledTypography>
            )}
          </Container>
          <StyledIconContainer>
            {!plannedNoData && (
              <StyledTrendIcon trend={planned.trend} data-testid="trend-icon" icon={Icons.ARROW} />
            )}
            <StyledBudgetPlannedIcon
              color="textGray"
              margin={[3, 0, 0]}
              data-testid="planned-icon"
              icon={Icons.LIST}
            />
          </StyledIconContainer>
        </StyledContainer>
      )}
    </>
  );
};
