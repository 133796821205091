import styled from 'styled-components';
import { Container, Typography } from '../../../../../stories/atoms';

export const StyledView = styled(Container)<{ hasBorder: boolean }>`
  border-top: 1px solid ${({ theme: { color }, hasBorder }) => (hasBorder ? color.borderGray : 'none')};
  height: 100%;
`;

export const StyledArticles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledItem = styled.img`
  max-height: min(6vh, 6vw);
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
  max-width: fit-content;
`;

// TODO: Uncomment while integrating ORIS data

export const StyledDivider = styled.div`
  width: ${({ theme: { spacing } }) => spacing(0.3)};
  height: 60%;
  margin: 0 ${({ theme: { spacing } }) => spacing(0, 0, 3)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.textGray : color.borderGray};
`;

export const StyledSection = styled(Container)`
  border-radius: ${({ theme: { spacing } }) => spacing(4)};
  border: 1px solid ${({ theme: { color } }) => color.borderGray};
`;

export const StyledTypography = styled(Typography)`
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color } }) => color.backgroundSelectedHover};
`;
