import { ProfitLossResponse } from '../../hooks';

export const getRandomNumber = (min: number, max: number, decimals: number = 0): number => {
  const factor = Math.pow(10, decimals);
  return Math.round((Math.random() * (max - min) + min) * factor) / factor;
};

const costDetailsOthers = [
  { costName: 'Electricity', departmentID: '502' },
  { costName: 'Shopfitting and Bulbs', departmentID: '505' },
  { costName: 'Cleaning, Pest and Waste', departmentID: '506' },
  { costName: 'Hangers', departmentID: '547' },
  { costName: 'Travel Expense', departmentID: '580' },
];

const costDetailsReductions = [
  { costName: 'Reduction Textile', departmentID: '470' },
  { costName: 'Reduction Cosmetics', departmentID: '471' },
  { costName: 'Statistical Cost of Reduction', departmentID: '472' },
];

const costDetailsSalaries = [
  { costName: 'Temporary Staff Agency', departmentID: '713' },
  { costName: 'Other personnel costs/Employee', departmentID: '760' },
];

const summaryDataCostSections = ['Sales Excl VAT', 'Sales Incl VAT'];

const detailsDataCostSections = [
  {
    costName: 'Others',
    departmentID: '',
    reports: costDetailsOthers,
  },
  {
    costName: 'Reductions',
    departmentID: '',
    reports: costDetailsReductions,
  },
  {
    costName: 'Salaries',
    departmentID: '',
    reports: costDetailsSalaries,
  },
];

export const MockPLReportData: ProfitLossResponse = {
  summaryData: summaryDataCostSections.map((costName) => ({
    costName,
    departmentID: '',
    actuals: getRandomNumber(100000, 10000000),
    indexVsLY: getRandomNumber(50, 200),
    indexVsBudget: getRandomNumber(50, 200),
    sob: parseFloat((Math.random() * 2).toFixed(2)),
    sobLY: parseFloat((Math.random() * 2).toFixed(2)),
    sobBudget: parseFloat((Math.random() * 2).toFixed(2)),
  })),
  detailData: detailsDataCostSections.map((detail) => ({
    ...detail,
    actuals: getRandomNumber(100000, 10000000),
    indexVsLY: getRandomNumber(50, 200),
    indexVsBudget: getRandomNumber(50, 200),
    sob: parseFloat((Math.random() * 2).toFixed(2)),
    sobLY: parseFloat((Math.random() * 2).toFixed(2)),
    sobBudget: parseFloat((Math.random() * 2).toFixed(2)),
    reports: detail.reports.map((report) => ({
      ...report,
      actuals: getRandomNumber(100000, 10000000),
      indexVsLY: getRandomNumber(50, 200),
      indexVsBudget: getRandomNumber(50, 200),
      sob: parseFloat((Math.random() * 2).toFixed(2)),
      sobLY: parseFloat((Math.random() * 2).toFixed(2)),
      sobBudget: parseFloat((Math.random() * 2).toFixed(2)),
    })),
  })),
};
